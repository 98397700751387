//import firebase from '../../config/firebaseTest'
import firebase from '../../config/firebaseIO'
import { useHistory } from "react-router-dom";

const db = firebase.firestore()

export const loginUser = (userUID, historyRoute) => (dispatch) => {

  // const history = useHistory();
  db.collection("users").doc(userUID)
    .get()
    .then((user) => {
      dispatch({
        type: "SET_LOGIN",
        user: {
          userUID: userUID,
          firstName: user.data().firstName,
          lastName: user.data().lastName,
          rol: user.data().rol,
          firstLogin: user.data().firstLogin,
          email: user.data().email && user.data().email == true ? user.data().email : false,
        },
      });
      return user
    })
    .then((user) => {

      console.log(user.data())

      historyRoute.push("/today")
    });
};

export const logoutUser = () => (dispatch) =>
  dispatch({
    type: "SET_LOGOUT",
    user: {},
  });