import React, { useState, useEffect, useRef } from "react";
import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import axios from "axios";
import DemoBBVADialogFlow from "../components/DemoBBVADialogFlow";

const ChatClient = () => {
    // let responsesPoc = [
    //     { message: `Ya estamos trabajando para responder todas tus consultas.`, owner: 'admin' },
    //     { message: `Gracias por ser paciente.`, owner: 'admin' }
    // ]
    const messageEl = useRef(null);
    const loginUser = useSelector(state => state.user.loginUser)
    const { idBot } = useParams();
    const [darkmode, setDarkmode] = useState(false);
    const [screenSize, setScreenSize] = useState(window.innerWidth);
    const [lang, setLang] = useState('spanish');
    const [introMode, setIntroMode] = useState(true);
    const [message, setMessage] = useState('');
    const [messages, setMessages] = useState([]);
    const [active, setActive] = useState(true);
    const [idx, setIdx] = useState(0);
    const [sessionPath, setSessionPath] = useState('');

    useEffect(() => {
        if (introMode === false) {
            if (messageEl) {
                messageEl.current.addEventListener('DOMNodeInserted', event => {
                    const { currentTarget: target } = event;
                    target.scroll({ top: target.scrollHeight, behavior: 'smooth' });
                });
            }
        }
    }, [introMode])

    const handleIntroMode = () => {
        setIntroMode(!introMode);
    };

    const handleSend = async (e) => {
        e.preventDefault();
        setActive(false);
        let msgs = messages;
        msgs = [...msgs, { message: message, owner: 'user' }, { message: 'escribiendo...', owner: 'admin' }];
        setMessages(msgs);
        const tmp = message;
        setMessage('');
        //const url = `https://us-central1-test-bf2a6.cloudfunctions.net`;
        //    const url = `http://localhost:5000/insideone/us-central1`;
        const response = await axios({
            //url: `http://localhost:5000/insideone/us-central1/SEND_TO_DIALOGFLOW`,
            url: `https://us-central1-insideone-app.cloudfunctions.net/SEND_TO_DIALOGFLOW`,
            method: 'POST',
            params: {
                message: tmp,
                sessionCurrent: sessionPath,
            }
        })
        let responseGoogle = response.data.message;
        if (!sessionPath) setSessionPath(response.data.sessionRes);
        msgs.pop()
        msgs = [...msgs, { message: responseGoogle, owner: 'admin' }];
        setActive(true);
        setMessages(msgs);
    }

    return (
        <DemoBBVADialogFlow
            introMode={introMode}
            handleIntroMode={handleIntroMode}
            darkmodeState={darkmode}
            screenSize={screenSize}
            messages={messages}
            message={message}
            setMessage={setMessage}
            typeBot={idBot}
            loginUser={loginUser}
            handleSend={handleSend}
            active={active}
            messageEl={messageEl}
        />
    );
}

export default ChatClient;