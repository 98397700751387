import React, { useState, useRef } from "react";
import Login from "../components/Login";
import { useHistory } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { loginUser } from "../store/actions/loginAction";
import AuthService from "../services/auth.service";
//import { useTranslation } from 'react-i18next';

const LoginClient = () => {

    // const { t } = useTranslation();
    // const form = useRef();
    const history = useHistory();
    const userLogin = useSelector(state => state.user.userLogin)
    const isAuth = useSelector(state => state.user.isAuth)
    const dispatch = useDispatch()

    const [username, setUsername] = useState("");
    const [password, setPassword] = useState("");
    const [loading, setLoading] = useState(false);
    const [message, setMessage] = useState("");
    const [screenSize, setScreenSize] = useState(window.innerWidth);

    const onChangeUsername = (e) => {
        const username = e.target.value;
        setUsername(username);
    };

    const onChangePassword = (e) => {
        const password = e.target.value;
        setPassword(password);
    };

    const handleLogin = (e) => {
        e.preventDefault();
        setMessage("");
        setLoading(true);

        AuthService.login(username, password).then(
            (res) => {
                if (res) {
                    setMessage("");
                    dispatch(loginUser(res.user.uid, history))
                }
            },
            (error) => {
                setLoading(false);
                setMessage('The email or password is incorrect.');
            }
        )
    };

    return (
        <Login
            screenSize={screenSize}
            username={username}
            password={password}
            message={message}
            loading={loading}
            onChangeUsername={onChangeUsername}
            onChangePassword={onChangePassword}
            handleLogin={handleLogin}
        />
    );
}

export default LoginClient;