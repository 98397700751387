import React, { useEffect } from "react";
import { Route, Switch } from "react-router-dom";
import { useSelector } from "react-redux";


import AuthService from "./services/auth.service";

import Today from "./containers/Today";
import People from "./containers/People";
import NewPerson from "./containers/create/People";
import NewEmail from "./containers/create/Email";
import DemoBBVADialogFlow from "./containers/DemoBBVADialogFlow";
import Web from "./containers/Web";
import Login from "./containers/Login";
import Chat from "./containers/Chat";
import Meet from "./containers/Contact";
import Solutions from "./containers/Solutions";
import SolutionsIA from "./containers/SolutionsIA";
import Resources from "./containers/Resources";
import SolutionsAvaya from "./containers/SolutionsAvaya";

import OneView from "./containers/OneView";

const Main = () => {
  const isAuth = useSelector(state => state.user.isAuth)

  useEffect(() => {
    const user = AuthService.getCurrentUser();

    if (user) {
      //   setCurrentUser(user);
    }
  }, []);

  const connStatus = () => {
    AuthService.connStatus();
  }

  // OJO que sin los [] es bucle infinito
  useEffect(() => { connStatus(); }, []);



  return (
    <>
      {isAuth && isAuth === true ? (
        <Switch>

          {/* <Route exact path="/tasks" component={Tasks} />
          <Route path="/bot/:idBot" component={Chat} /> */}

          {/* Generales */}
          <Route path="/solutions/artificial-intelligence" component={SolutionsIA} />
          <Route path="/solutions/:typeSolution" component={SolutionsIA} />

          {/* //Esto sucede cuando desde el Bot se lo envía a la web, entonces lo saluda */}
          <Route path="/welcome/:nameClient" component={Web} />
          {/* //Esto sucede cuando desde un formulario de registro se lo envía a la web, entonces lo saluda */}
          <Route path="/registration-done/:nameClient" component={Web} />
          <Route exact path="/login" component={Login} />
          <Route exact path="/today" component={Today} />
          <Route exact path="/people" component={People} />
          <Route exact path="/new-person" component={NewPerson} />
          <Route exact path="/send-email" component={NewEmail} />
          <Route exact path="/demos" component={Chat} />
          <Route exact path="/bbva-seguros-whatsapp" component={DemoBBVADialogFlow} />
          <Route exact path="/home" component={Today} />
          <Route path="/" component={Web} />
        </Switch>
      ) : (
        <Switch>
          <Route exact path="/oneview/:idInteraction" component={OneView} />
          <Route exact path="/bbva-seguros-whatsapp" component={DemoBBVADialogFlow} />
          <Route exact path="/login" component={Login} />
          {/* Generales */}
          <Route path="/solutions/artificial-intelligence" component={SolutionsIA} />
          <Route path="/solutions/avaya" component={SolutionsAvaya} />
          <Route path="/solutions/:typeSolution" component={Solutions} />
          {/* //Esto sucede cuando desde el Bot se lo envía a la web, entonces lo saluda */}
          <Route path="/welcome/:nameClient" component={Web} />
          {/* //Esto sucede cuando desde un formulario de registro se lo envía a la web, entonces lo saluda */}
          <Route path="/registration-done/:nameClient" component={Web} />
          <Route path="/meet" component={Meet} />
          <Route path="/news" component={Resources} />
          <Route path="/" component={Web} />
        </Switch>
      )}
    </>
  )
}

export default Main;