import React from "react";
import { Link } from "react-router-dom";
import { InlineWidget } from "react-calendly";
import Navbar from "./Navbar";

let year = new Date().getFullYear()

export default ({ screenSize, handleClick, handleDarkMode, darkModeState, handleLanguage, handleChange, handleSubmit, language, page, firstName, lastName, numberPhone, mail, message, messageSent, setSolutionBanner, solutionBanner, solutions }) => {
    const styles = {
        container: {
            //    position: "fixed",
            //   bottom: 0,
            //  top: 0,
            // left: 0,
            //  right: 0,
            display: "flex",
            flexDirection: "column",
            backgroundColor: `${darkModeState ? ("hsl(230, 17%, 14%)") : ("hsl(0, 0%, 100%)")}`,
            //   padding: `${screenSize > 800 ? ("3% 2% 3% 20%") : ("30px")}`,
            //   padding: `${screenSize > 800 ? ("3% 2% 3% 280px") : ("30px")}`,
            //    height: "100%",
            overflow: `auto`,
        },
        navbar: {
            display: "flex",
            height: "60px",
            alignItems: 'center',
            justifyContent: 'space-between',
            margin: `${screenSize > 800 ? ("0px 9vw") : ("0px")}`,
            padding: '10px 0px',
            // width: '100%'
            //    flex: 1.5,
            //   marginBottom: `${screenSize > 800 ? ("0px") : ("30px")}`,
        },
        navbarWhite: {
            // display: "flex",
            // alignItems: 'center',
            // justifyContent: 'space-between',
            // height: "110px",
            // width: '100%',
            // padding: `${screenSize > 800 ? ("20px 60px") : ("20px")}`,
            // margin: "0px",

            //    flex: 1.5,
            //   marginBottom: `${screenSize > 800 ? ("0px") : ("30px")}`,

            position: "fixed",
            top: 0,
            right: 0,
            bottom: `${screenSize > 800 ? (<></>) : ("0")} `,
            left: 0,
            display: "flex",
            flexDirection: 'row',
            height: "100px",
            alignItems: 'center',
            justifyContent: 'space-between',
            padding: `${screenSize > 800 ? ("0px 60px") : ("20px")}`,
            margin: "0px",
            zIndex: 200,
            backgroundColor: 'white',
            //     borderBottom: '1px solid rgba(0, 0, 0, .2)',
        },
        logoContainer: {
            display: "flex",
            flex: '1',
            alignItems: "center",
            justifyContent: 'center',
            border: 'none',
            padding: 0,
            outline: 0,
            backgroundColor: 'transparent',
            //    backgroundColor: 'red'
            //   padding: "20px 0px"
        },
        titleNavbar: {
            //  marginBottom: `${screenSize > 800 ? ('5px') : ("10px")}`,
            margin: 0,
            fontSize: "28px",
            color: `${darkModeState ? ("hsl(0, 0%, 100%)") : ("hsl(230, 17%, 14%)")}`,
            fontFamily: "InterBold",
            lineHeight: '1.4'
        },
        buttonsNavbarLeft: {
            display: 'flex',
            flex: 2,
            flexDirection: 'row',
            alignItems: 'flex-end',
            justifyContent: 'flex-start'
        },
        buttonsNavbarRight: {
            display: 'flex',
            flex: 2,
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'flex-end'
        },
        buttonNavbarPlain: {
            backgroundColor: 'transparent',
            color: '#4dc2f1',
            borderRadius: 25,
            border: 'none',
            padding: 0,
            outline: 0,
            margin: "0px 15px 0px 0px",
            display: 'flex',
            flexDirection: 'row',
            // alignItem: 'center',
            // justifyContent: 'center'
        },
        textButtonPlain: {
            margin: 0,
            fontSize: 16,
            color: '#4dc2f1',
            margin: "0px 15px 0px 0px",
            fontFamily: "UbuntuRegular",
            //  textAlign: 'start'
        },
        line: {
            display: 'flex',
            width: "30px",
            height: "2px",
            alignSelf: 'flex-end',
            marginBottom: "3px",
            backgroundColor: '#4dc2f1'
        },
        buttonNavbarWhite: {
            backgroundColor: 'transparent',
            color: '#4dc2f1',
            borderRadius: 25,
            border: '2px solid #4dc2f1',
            padding: '3px 18px',
            height: '45px',
            width: '130px',
            outline: 0,
            margin: "0px 5px"
        },
        buttonNavbarBlue: {
            backgroundColor: '#4DC2F1',
            borderRadius: 25,
            border: 'none',
            padding: '3px 18px',
            height: '45px',
            width: '130px',
            outline: 0,
            margin: "0px 10px"
        },
        buttonLanguage: {
            backgroundColor: 'transparent',
            border: 'none',
            padding: '3px 18px',
            height: '35px',
            width: '70px',
            outline: 0
        },
        textButtonBlue: {
            margin: 0,
            fontSize: "14px",
            color: '#4dc2f1',
            fontFamily: "UbuntuRegular",
        },
        textButtonWhite: {
            margin: 0,
            fontSize: "14px",
            color: '#fff',
            fontFamily: "UbuntuRegular",
        },
        textThanks: {
            margin: 0,
            fontSize: "16px",
            color: '#fff',
            alignSelf: 'centerr'
        },
        textButtonLanguage: {
            margin: 0,
            fontSize: "14px",
            color: '#333c4e',
        },
        textButtonLanguageHeader: {
            margin: 0,
            fontSize: "14px",
            color: '#4dc2f1',
            fontFamily: "UbuntuRegular",
            //  color: '#000',
        },
        bannerHeader: {
            display: 'flex',
            flexDirection: "column",
            margin: `${screenSize > 800 ? ('8vh 0px 0px') : ('3vh 0px 0px')}`,
        },
        textsHeader: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            flexDirection: 'column',
            margin: `${screenSize > 800 ? ('0px 25vw') : ('0px')}`,
        },
        subtitleHeader: {
            color: '#4dc2f1',
            fontSize: `${screenSize > 800 ? ('20px') : ('16px')}`,
            fontFamily: 'UbuntuMedium',
            fontWeight: '600',
            textAlign: 'center',
            margin: `${screenSize > 800 ? ('0px') : ('0px')}`,
        },
        titleHeader: {
            color: '#000',
            fontSize: `${screenSize > 800 ? ('45px') : ('25px')}`,
            fontFamily: "UbuntuMedium",
            textAlign: 'center',
            margin: `${screenSize > 800 ? ('10px 0px 0px') : ('10px 10vw 50px')}`,
        },
        divButtonHeader: {
            display: 'flex',
            alignItem: 'center',
            justifyContent: 'center',
            height: '10vh'
        },
        buttonHeader: {
            display: 'flex',
            alignItem: 'center',
            justifyContent: 'center',
            backgroundColor: '#4DC2F1',
            borderRadius: 25,
            border: 'none',
            // padding: '3px 18px',
            height: '40px',
            width: '40px',
            color: '#fff',
            outline: 0,
        },

        resourcesBanner: {
            display: "flex",
            flexDirection: `${screenSize > 800 ? ('row') : ('column')}`,
            margin: `${screenSize > 800 ? ('50px 10vw 0px') : ('0px 30px')}`,
            // padding: `${screenSize > 800 ? ('100px 0px') : ('50px 0px')}`,
            // alignItems: "center",
            justifyContent: 'space-between',
            // height: "600px",
            //   backgroundColor: "blue",
            // textAlign: 'center',
            flexWrap: `${screenSize > 800 ? ('wrap') : ('wrap')}`,
        },
        singleResource: {
            display: "flex",
            flexDirection: "column",
            margin: `${screenSize > 800 ? ('60px 0px 0px') : ('20px 0px')}`,
            // padding: `${screenSize > 800 ? ('100px 0px') : ('50px 0px')}`,
            alignItems: `${screenSize > 800 ? (<></>) : ('center')}`,
            justifyContent: `${screenSize > 800 ? (<></>) : ('center')}`,
            //   height: "450px",
            width: `${screenSize > 800 ? ('30%') : ('100%')}`,
            //    backgroundColor: "red",
            borderRadius: '25px'
            // textAlign: 'center',
            // flexWrap: `${screenSize > 800 ? ('nowrap') : ('wrap')}`,
        },
        singleResourceTexts: {
            display: "flex",
            flexDirection: "column",
            margin: 0,
            padding: `${screenSize > 800 ? ('25px') : ('25px 15px')}`,
        },
        typeResource: {
            fontSize: `${screenSize > 800 ? ('14px') : ('14px')}`,
            //   margin: `${screenSize > 800 ? ('0px 8vw 40px') : ('20px 15vw 20px')}`,
            margin: `${screenSize > 800 ? ('0px 0px 10px') : ('0px 0px 20px')}`,
            fontFamily: "UbuntuMedium",
            // color: '#fff',
            color: '#4dc2f1',
            // backgroundColor: "#2D2A32",
            // borderRadius: '25px',
            // padding: '5px 18px',
            textAlign: `${screenSize > 800 ? (<></>) : ('center')}`,
        },
        titleResource: {
            fontSize: `${screenSize > 800 ? ('20px') : ('18px')}`,
            //   margin: `${screenSize > 800 ? ('0px 8vw 40px') : ('20px 15vw 20px')}`,
            margin: `${screenSize > 800 ? ('0px 0px 10px') : ('0px 0px 10px')}`,
            fontFamily: "UbuntuBold",
            color: '#000',
            textAlign: `${screenSize > 800 ? (<></>) : ('start')}`,
        },
        subtitleResource: {
            color: '#000',
            fontSize: `${screenSize > 800 ? ('16px') : ('14px')}`,
            fontFamily: 'UbuntuRegular',
            margin: `${screenSize > 800 ? ('0px 0px 20px') : ('0px 0px 20px')}`,
            // lineHeight: '30px'
            textAlign: `${screenSize > 800 ? (<></>) : ('start')}`,
        },
        buttonResource: {
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'flex-start',
            justifyContent: 'flex-start',
            //   margin: `${screenSize > 800 ? ('0px 8vw 40px') : ('20px 15vw 20px')}`,
            margin: `${screenSize > 800 ? ('0px 1vw 0px 0px') : ('0px 0px 0px')}`,
            padding: 0,
            backgroundColor: 'transparent',
            border: 'none',
            outline: 0,
        },
        buttonTextResource: {
            color: '#D0B5F0',
            fontSize: '18px',
            fontFamily: 'UbuntuMedium',
            fontWeight: '600',
            margin: '0px 15px 0px 0px',
            textAlign: 'start',
        },
        lineResource: {
            display: 'flex',
            width: "100px",
            height: "2px",
            alignSelf: 'flex-end',
            marginBottom: "3px",
            backgroundColor: '#D0B5F0'
        },







        bannerSolutions: {
            display: "flex",
            flexDirection: 'column',
            margin: `${screenSize > 800 ? ('0px') : ('50px 0px 0px')}`,
            padding: `${screenSize > 800 ? ('100px 0px') : ('50px 0px')}`,
            alignItems: "center",
            justifyContent: 'center',
            //  height: "70vh",
            textAlign: 'center',
            // flexWrap: `${screenSize > 800 ? ('nowrap') : ('wrap')}`,
        },
        titleBannerSolutions: {
            fontSize: `${screenSize > 800 ? ('40px') : ('25px')}`,
            //   margin: `${screenSize > 800 ? ('0px 8vw 40px') : ('20px 15vw 20px')}`,
            margin: `${screenSize > 800 ? ('-10px 0px 0px') : ('0px 0px 0px')}`,
            fontFamily: "UbuntuRegular",
            color: '#4dc2f1',
            textAlign: `${screenSize > 800 ? (<></>) : ('center')}`,
        },
        subtitleBannerSolutions: {
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'center',
            //   margin: `${screenSize > 800 ? ('0px 8vw 40px') : ('20px 15vw 20px')}`,
            margin: '50px 0px 40px',
            backgroundColor: 'transparent',
            border: 'none',
            outline: 0,
        },
        subtitleBannerSolutionsText: {
            color: '#000',
            fontSize: '18px',
            fontFamily: 'UbuntuRegular',
            fontWeight: '600',
            margin: '0px'
            //  padding: '0px 5vw'
            // textAlign: 'center'
        },
        buttonBannerSolutions: {
            display: 'flex',
            //     flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'center',
            //   margin: `${screenSize > 800 ? ('0px 8vw 40px') : ('20px 15vw 20px')}`,
            margin: '0px 0px 20px',
            backgroundColor: 'transparent',
            border: '1px solid #000',
            borderRadius: '50px',
            outline: 0,
            height: '45px',
            width: '180px',
        },
        buttonBannerSolutionsText: {
            // display: 'flex',
            // alignSelf: 'center',
            color: '#000',
            fontSize: '14px',
            fontFamily: 'UbuntuRegular',
            fontWeight: '600',
            margin: '0px',
            //    padding: '13px 20px',
            //    padding: '3px 18px',

            // textAlign: 'center'
        },




        columnContainer: {
            display: "flex",
            margin: '50px 0px',
            alignItems: "center",
            justifyContent: 'center',
            flexWrap: `${screenSize > 800 ? ('nowrap') : ('wrap')}`,
        },
        columnContainerReverse: {
            display: "flex",
            margin: '50px 0px',
            alignItems: "center",
            justifyContent: 'center',
            flexWrap: `${screenSize > 800 ? ('nowrap') : ('wrap-reverse')}`,
        },
        columnContainerNoBottom: {
            display: "flex",
            margin: '50px 0px 0px',
            alignItems: "center",
            justifyContent: 'center',
            flexWrap: `${screenSize > 800 ? ('nowrap') : ('wrap')}`,
        },
        columnContainerNoTop: {
            display: "flex",
            margin: '0px 0px 50px',
            alignItems: "center",
            justifyContent: 'center',
            flexWrap: `${screenSize > 800 ? ('nowrap') : ('wrap')}`,
        },
        columnContainerNoTopNoBottom: {
            display: "flex",
            margin: `${screenSize > 800 ? ('0px') : ('30px 0px 0px')}`,
            alignItems: "center",
            justifyContent: 'center',
            flexWrap: `${screenSize > 800 ? ('nowrap') : ('wrap')}`,
        },
        columnText: {
            display: "flex",
            flexDirection: 'column',
            alignItems: "flex-start",
            justifyContent: 'center',
            margin: 0,
            //   width: `${screenSize > 800 ? ("50vw") : ("80vw")}`,
            width: `${screenSize > 800 ? ("50vw") : ("100%")}`,
            padding: `${screenSize > 800 ? (0) : ("10px")}`,
        },
        columnTextGrey: {
            display: "flex",
            flexDirection: 'column',
            alignItems: "flex-start",
            justifyContent: 'center',
            margin: 0,
            width: `${screenSize > 800 ? ("50vw") : ("100%")}`,
            padding: `${screenSize > 800 ? (0) : ("10px")}`,
            backgroundColor: "#F5F7FA",
            height: `${screenSize > 800 ? ("480px") : (<></>)} `,
        },
        columnTextPadding: {
            padding: `${screenSize > 800 ? ("0px 80px") : ("20px")}`,
        },
        columnTextContext: {
            fontFamily: 'sans-serif',
            fontWeight: '600',
            fontSize: '16px',
            color: '#a4abae',
            lineHeight: '1.45',
            margin: "0px 0px 15px 0px",

        },
        columnTextTitle: {
            fontFamily: 'sans-serif',
            fontWeight: '600',
            fontSize: '27px',
            color: '#333c4e',
            lineHeight: '1.3',
            margin: "0px 0px 30px 0px",
        },
        columnTextBody: {
            fontFamily: 'sans-serif',
            fontWeight: '400',
            fontSize: '18px',
            color: '#4f5766',
            lineHeight: '1.7',
            margin: "20px 0px 0px",
        },
        titleCaseContainer: {
            display: "flex",
            margin: '50px 50px 0px',
            alignItems: "center",
            justifyContent: 'center',
        },
        titleCase: {
            fontFamily: 'sans-serif',
            fontWeight: '600',
            fontSize: '18px',
            textAlign: 'center',
            color: '#333c4e',
            lineHeight: '1.7',
            margin: 0,
        },
        columnCaseContainer: {
            display: "flex",
            margin: '50px 0px',
            alignItems: "center",
            justifyContent: 'center',
            flexWrap: `${screenSize > 800 ? ('wrap') : ('wrap')}`,
        },
        columnCase: {
            display: "flex",
            alignItems: "center",
            justifyContent: 'center',
            width: `${screenSize > 800 ? ("30vw") : ("80vw")}`,
            height: `${screenSize > 800 ? ("345px") : ("230px")}`,
            margin: 0,
            padding: `${screenSize > 800 ? (0) : ("20px")}`,
            backgroundColor: "#005093"
        },
        imageCase: {
            height: `${screenSize > 800 ? ("45px") : ("35px")}`,
        },
        imageCase2: {
            height: `${screenSize > 800 ? ("75px") : ("55px")}`,
        },
        imageCase3: {
            height: `${screenSize > 800 ? ("45px") : ("30px")}`,
        },
        columnCaseText: {
            display: "flex",
            alignItems: "center",
            justifyContent: 'center',
            width: `${screenSize > 800 ? ("30vw") : ("80vw")}`,
            height: `${screenSize > 800 ? ("345px") : (<></>)}`,
            margin: 0,
            backgroundColor: '#F5F7FA'
        },
        columnCaseTextRecordati: {
            display: "flex",
            alignItems: "center",
            justifyContent: 'center',
            width: `${screenSize > 800 ? ("30vw") : ("80vw")}`,
            height: `${screenSize > 800 ? ("445px") : (<></>)}`,
            margin: 0,
            backgroundColor: '#F5F7FA'
        },
        columnCasePadding: {
            padding: `${screenSize > 800 ? ("0px 80px") : ("20px")}`,
            display: "flex",
            flexDirection: 'column',
            alignItems: "center",
            justifyContent: 'center',
        },
        columnCasePaddingRecordati: {
            padding: `${screenSize > 800 ? ("0px 60px") : ("20px")}`,
            display: "flex",
            flexDirection: 'column',
            alignItems: "center",
            justifyContent: 'center',
        },
        columnTextCase: {
            fontFamily: 'sans-serif',
            fontWeight: '400',
            fontSize: '18px',
            textAlign: 'center',
            color: '#4f5766',
            lineHeight: '1.7',
            margin: "30px 0px",
        },
        columnTextCaseNoTop: {
            fontFamily: 'sans-serif',
            fontWeight: '400',
            fontSize: '18px',
            textAlign: 'center',
            color: '#4f5766',
            lineHeight: '1.7',
            margin: `${screenSize > 800 ? ("0px 0px 30px") : ("20px 10px 20px")}`,
        },
        columnTextCaseRecordati: {
            fontFamily: 'sans-serif',
            fontWeight: '400',
            fontSize: '17px',
            textAlign: 'start',
            color: '#4f5766',
            lineHeight: '1.7',
            margin: `${screenSize > 800 ? ("10px 0px") : ("20px 10px 20px")}`,
        },
        columnBoldCase: {
            fontFamily: 'sans-serif',
            fontWeight: '600',
            fontSize: '16px',
            textAlign: 'center',
            color: '#333c4e',
            lineHeight: '1.7',
            margin: 0,
            // padding: 0,
            padding: `${screenSize > 800 ? (0) : ("0px 0px 10px")}`,
        },
        titleClientContainer: {
            display: "flex",
            margin: '50px 50px 0px',
            alignItems: "center",
            justifyContent: 'center',
        },
        titleClient: {
            fontFamily: 'sans-serif',
            fontWeight: '400',
            fontSize: '26px',
            textAlign: 'center',
            color: '#333c4e',
            margin: 0,
            lineHeight: '1.7',
        },
        columnClientContainer: {
            display: "flex",
            margin: `${screenSize > 800 ? ('50px') : ("30px 30px 50px")}`,
            alignItems: "center",
            justifyContent: 'center',
            flexWrap: 'wrap'
        },
        imageClient: {
            height: 70,
            margin: 25
        },
        formContainer: {
            backgroundColor: "#333c4e",
            alignItems: "center",
            justifyContent: 'center',
            padding: `${screenSize > 800 ? ("100px") : ("100px 30px")}`,
            margin: '50px 0px'
        },
        titleForm: {
            fontFamily: 'sans-serif',
            fontWeight: '400',
            fontSize: '28px',
            textAlign: 'center',
            color: '#fff',
            margin: "0px 50px 70px",
            lineHeight: '1.7',
        },
        formCenter: {
            display: 'flex',
            alignItems: "center",
            justifyContent: 'center',
        },
        formInputsContainer: {
            display: "flex",
            // flex: 12,
            width: '440px',
            flexDirection: 'column',
            alignItems: "flex-start",
            justifyContent: 'center',
        },
        labelForm: {
            margin: 0,
            color: '#fff'
        },
        inputForm: {
            borderRadius: '25px',
            height: '40px',
            margin: "8px 0px 16px",
            fontFamily: 'sans-serif',
            fontWeight: '400',
            padding: '0px 16px'
        },
        inputFormMessage: {
            display: "flex",
            alignItems: "flex-start",
            justifyContent: 'flex-start',
            borderRadius: '15px',
            height: '160px',
            margin: "8px 0px 16px",
            fontFamily: 'sans-serif',
            fontWeight: '400',
            padding: '10px 16px',
        },
        buttonForm: {
            display: "flex",
            alignItems: "center",
            justifyContent: 'center',
            backgroundColor: '#4DC2F1',
            borderRadius: 25,
            border: 'none',
            padding: '13px 23px',
            margin: "22px 0px",
            height: '50px',
            outline: 0
        },
        columnAddressContainer: {
            display: "flex",
            // margin: '50px 120px 100px',
            margin: `${screenSize > 800 ? ('100px 120px 100px') : ("50px 30px")}`,
            alignItems: "flex-start",
            justifyContent: 'space-between',
            flexWrap: 'wrap'
        },
        singleAddress: {
            display: "flex",
            flexDirection: 'column',
            alignItems: "flex-start",
            justifyContent: 'flex-start',
            width: "250px",
            //height: 70,
            margin: "0px 0px 25px"
        },
        titleAddress: {
            fontFamily: 'sans-serif',
            fontWeight: '600',
            fontSize: '18px',
            textAlign: 'center',
            color: '#333c4e',
            lineHeight: '1.7',
            textAlign: 'left',
            margin: "0px 0px 15px",
        },
        addressText: {
            fontFamily: 'sans-serif',
            fontWeight: '400',
            fontSize: '18px',
            textAlign: 'center',
            color: '#4f5766',
            lineHeight: '1.7',
            textAlign: 'left',
            margin: "0px 0px 3px",
        },
        phoneText: {
            fontFamily: 'sans-serif',
            fontWeight: '400',
            fontSize: '18px',
            textAlign: 'center',
            color: '#4dc2f1',
            lineHeight: '1.7',
            margin: 0,
            textAlign: 'left',
        },
        footerContainer: {
            display: "flex",
            margin: "10px 0px 30px",
            alignItems: "flex-start",
            justifyContent: 'flex-start',
        },
        textFooter: {
            fontFamily: 'sans-serif',
            fontWeight: '400',
            fontSize: '14px',
            textAlign: `${screenSize > 800 ? ("center") : ("left")} `,
            color: '#4f5766',
            margin: `${screenSize > 800 ? ("0px 120px") : ("0px 30px")} `,
            lineHeight: '1.7',
        },
        svgStyle: {
            height: 16,
            width: 16,
            display: 'flex',
            alignSelf: 'center'
        },
        // toogleNavbar: {
        //     display: "flex",
        //     flex: 4,
        //     alignItems: "center",
        //     justifyContent: `${ screenSize > 800 ? ("flex-end") : ("center") } `,
        //     flexDirection: `${ screenSize > 800 ? ("row") : ("column") } `,
        // },
        // textToogle: {
        //     fontSize: "14px",
        //     fontFamily: "InterBold",
        //     alignSelf: "center",
        //     textDecoration: "none",
        //     color: 'inherit',
        //     border: 'none',
        //     outline: 'none',
        //     padding: '12px',
        //     backgroundColor: `transparent`
        // }
        bannerAvaya: {
            display: "flex",
            flexDirection: `${screenSize > 800 ? ("row") : ("column")}`,
            margin: `${screenSize > 800 ? ("0px") : ("50px 0px 0px")}`,
            alignItems: "center",
            justifyContent: 'center',
            backgroundColor: '#DA291C'
        },
        columnTextAvaya: {
            display: "flex",
            justifyContent: 'center',
            margin: `${screenSize > 800 ? ("0px 40px") : ("60px 20px 0px")}`,
            padding: `${screenSize > 800 ? ("70px 0px") : ("0px")}`,
        },
        textAvaya: {
            fontFamily: 'sans-serif',
            fontWeight: 400,
            fontSize: '22px',
            color: '#fff',
            lineHeight: '1.5',
            textAlign: 'center',
            width: '100%',
            margin: 0
        }
    };
    return (
        <>
            <div style={styles.container} id="scroller">

                <Navbar style={{ zIndex: 999 }}
                    screenSize={screenSize}
                    language={language}
                    handleClick={handleClick}
                    handleLanguage={handleLanguage}
                    origin="contact"
                />



                <div style={{
                    display: "flex",
                    flexDirection: 'column',
                    alignItems: 'center',
                    justifyContent: 'center',
                    width: '100vw',
                    //   height: "100vh",
                    margin: 0,
                    padding: 0,
                    backgroundColor: "#fff",
                }}
                    id="home"
                >
                    <div style={{ backgroundColor: 'transparent', height: "100px", width: '100vw' }}></div>
                    <div style={styles.bannerHeader}>
                        <div style={styles.textsHeader}>
                            <h3 style={styles.subtitleHeader}>{language === true ? "Novedades" : "News"}</h3>
                            <h3 style={styles.titleHeader}>{language === true ? "Explora nuestra colección de recursos para ayudarte a construir tu negocio" : "Explore our collection of resources to help you build your business"}</h3>
                        </div>
                    </div>
                </div>



                <div style={styles.resourcesBanner}>
                    <div style={styles.singleResource}>
                        <img
                            src="https://i.postimg.cc/cCjwpnbG/banner-news-01.png"
                            //   height={screenSize > 800 ? ('621px') : (<></>)}
                            height={screenSize > 800 ? ('350px') : (<></>)}
                            width={screenSize > 800 ? (<></>) : ("100%")}
                            style={{ objectFit: "cover", margin: '0px 0px 0px', borderRadius: '25px' }}
                            alt="Hero of InsideOne"
                            loading="lazy"
                        />
                        <div style={styles.singleResourceTexts}>
                            <h3 style={styles.typeResource}>{language === true ? "Nota | Prensario" : "New | by Prensario"}</h3>
                            <h3 style={styles.titleResource}>InsideOne presenta su modelo flexible y adaptable a las necesidades de cada empresa.</h3>
                            <h3 style={styles.subtitleResource}>“Estamos muy contentos de estar aquí en Chile replicando el negocio de Argentina, que es pasar del modelo Capex al Opex."</h3>
                            <button style={styles.buttonResource} onClick={() => window.open("https://www.prensariohub.com/insideone/", "_blank")}>
                                <h3 style={styles.buttonTextResource}>{language === true ? "LEER" : "READ"}</h3>
                                <div style={styles.lineResource} />
                            </button>
                        </div>
                    </div>
                    <div style={styles.singleResource}>
                        <img
                            src="https://i.postimg.cc/hG6JPB0V/banner-news-04.png"
                            //   height={screenSize > 800 ? ('621px') : (<></>)}
                            height={screenSize > 800 ? ('350px') : (<></>)}
                            width={screenSize > 800 ? (<></>) : ("100%")}
                            style={{ objectFit: "cover", margin: '0px 0px 0px', borderRadius: '25px' }}
                            alt="Hero of InsideOne"
                            loading="lazy"
                        />
                        <div style={styles.singleResourceTexts}>
                            <h3 style={styles.typeResource}>{language === true ? "Nota | Dr.Cex" : "New | by Dr.Cex"}</h3>
                            <h3 style={styles.titleResource}>InsideOne presentó su modelo Opex Flexible al competitivo mercado de Chile</h3>
                            <h3 style={styles.subtitleResource}>InsideOne, como parte de su estrategia de expansión regional organizó un desayuno tecnológico, ante un gran número de funcionarios de empresas de los sectores Retail, Banca, Seguros y BPO´s.</h3>
                            <button style={styles.buttonResource} onClick={() => window.open("https://contactcentersonline.com/insideone-presento-su-modelo-opex-flexible-al-competitivo-mercado-de-chile/", "_blank")}>
                                <h3 style={styles.buttonTextResource}>{language === true ? "LEER" : "READ"}</h3>
                                <div style={styles.lineResource} />
                            </button>
                        </div>
                    </div>
                    <div style={styles.singleResource}>
                        <img
                            src="https://i.postimg.cc/RZnhs8mF/banner-news-05.png"
                            //   height={screenSize > 800 ? ('621px') : (<></>)}
                            height={screenSize > 800 ? ('350px') : (<></>)}
                            width={screenSize > 800 ? (<></>) : ("100%")}
                            style={{ objectFit: "cover", margin: '0px 0px 0px', borderRadius: '25px' }}
                            alt="Hero of InsideOne"
                            loading="lazy"
                        />
                        <div style={styles.singleResourceTexts}>
                            <h3 style={styles.typeResource}>{language === true ? "Nota | Avaya" : "New | by Avaya"}</h3>
                            <h3 style={styles.titleResource}>Avaya anuncia la expansión de su alianza estratégica con InsideOne en el Cono Sur</h3>
                            <h3 style={styles.subtitleResource}>InsideOne anunció su llegada a Chile de la mano de Avaya con el objetivo de apoyar a más empresas en su digitalización y ofrecer las mejores experiencias a sus clientes.</h3>
                            <button style={styles.buttonResource} onClick={() => window.open("https://www.avaya.com/es/about-avaya/newsroom/2023/esp_np_mx_230323/", "_blank")}>
                                <h3 style={styles.buttonTextResource}>{language === true ? "LEER" : "READ"}</h3>
                                <div style={styles.lineResource} />
                            </button>
                        </div>
                    </div>

                    <div style={styles.singleResource}>
                        <img
                            src="https://i.postimg.cc/hG6JPB0V/banner-news-04.png"
                            //   height={screenSize > 800 ? ('621px') : (<></>)}
                            height={screenSize > 800 ? ('350px') : (<></>)}
                            width={screenSize > 800 ? (<></>) : ("100%")}
                            style={{ objectFit: "cover", margin: '0px 0px 0px', borderRadius: '25px' }}
                            alt="Hero of InsideOne"
                            loading="lazy"
                        />
                        <div style={styles.singleResourceTexts}>
                            <h3 style={styles.typeResource}>{language === true ? "Video" : "Video"}</h3>
                            <h3 style={styles.titleResource}>Beneficios del Opex Flexible</h3>
                            <h3 style={styles.subtitleResource}>Conoce en este video algunos de los muchos beneficios que ofrece el modelo Opex Flexible de InsideOne y cómo podemos ayudarte.</h3>
                            <button style={styles.buttonResource} onClick={() => window.open("https://youtu.be/okfnF2_cHxI", "_blank")}>
                                <h3 style={styles.buttonTextResource}>{language === true ? "VER" : "WATCH"}</h3>
                                <div style={styles.lineResource} />
                            </button>
                        </div>
                    </div>
                    <div style={styles.singleResource}>
                        <img
                            src="https://i.postimg.cc/zvS3jRYk/banner-news-03.png"
                            //   height={screenSize > 800 ? ('621px') : (<></>)}
                            height={screenSize > 800 ? ('350px') : (<></>)}
                            width={screenSize > 800 ? (<></>) : ("100%")}
                            style={{ objectFit: "cover", margin: '0px 0px 0px', borderRadius: '25px' }}
                            alt="Hero of InsideOne"
                            loading="lazy"
                        />
                        <div style={styles.singleResourceTexts}>
                            <h3 style={styles.typeResource}>{language === true ? "Entrevista | Dr.Cex" : "Interview | by Dr.Cex"}</h3>
                            <h3 style={styles.titleResource}>InsideOne: Todo sobre el modelo de servicios Opex</h3>
                            <h3 style={styles.subtitleResource}>Plantear que las empresas pasen de tener una solución Stand Alone a confiar en una nube era un concepto muy disruptivo, salvo para las empresas que veían más allá y hacia donde iba la tecnología confiaron en InsideOne.</h3>
                            <button style={styles.buttonResource} onClick={() => window.open("https://contactcentersonline.com/inside-one-todo-sobre-el-modelo-de-servicios-opex/", "_blank")}>
                                <h3 style={styles.buttonTextResource}>{language === true ? "LEER" : "READ"}</h3>
                                <div style={styles.lineResource} />
                            </button>
                        </div>
                    </div>
                    <div style={styles.singleResource}>
                        <img
                            src="https://i.postimg.cc/q7dXB2Mk/nota1-2x.png"
                            //   height={screenSize > 800 ? ('621px') : (<></>)}
                            height={screenSize > 800 ? ('350px') : (<></>)}
                            width={screenSize > 800 ? (<></>) : ("100%")}
                            style={{ objectFit: "cover", margin: '0px 0px 0px', borderRadius: '25px' }}
                            alt="Hero of InsideOne"
                            loading="lazy"
                        />
                        <div style={styles.singleResourceTexts}>
                            <h3 style={styles.typeResource}>{language === true ? "Entrevista | Prensario" : "Interview | by Prensario"}</h3>
                            <h3 style={styles.titleResource}>InsideOne: la vanguardia madura en CCaaS y UCaaS</h3>
                            <h3 style={styles.subtitleResource}>El mercado de Contact Center (CC) y Comunicaciones Unificadas (UC) en el Cono Sur tiene un Player ABC1, para su deseada evolución a CCaaS y UCaaS: es el servicio OneCloud de InsideOne.</h3>
                            <button style={styles.buttonResource} onClick={() => window.open("https://prensariotila.com/insideone-la-vanguardia-madura-en-ccaas-y-ucaas/", "_blank")}>
                                <h3 style={styles.buttonTextResource}>{language === true ? "LEER" : "READ"}</h3>
                                <div style={styles.lineResource} />
                            </button>
                        </div>
                    </div>
                </div>


















                {/* 
                        <InlineWidget url="https://calendly.com/insideone/30min?hide_landing_page_details=1&primary_color=4dc2f1"
                            styles={{
                                height: `${screenSize > 800 ? ('80vh') : ('110vh')}`,
                                width: '100vw'
                            }}
                            pageSettings={{
                                backgroundColor: 'fff',
                                hideEventTypeDetails: false,
                                hideLandingPageDetails: false,
                                primaryColor: '4dc2f1',
                                //      textColor: '4d5055'
                            }}
                        // prefill={{
                        //     date: new Date(Date.now() + 86400000)
                        // }}
                        />
 */}











                <div style={styles.columnAddressContainer}>
                    <div style={styles.singleAddress}>
                        <h1 style={styles.titleAddress}>ARGENTINA</h1>
                        <h1 style={styles.addressText}>Diogenes Taborda 127</h1>
                        <h1 style={styles.addressText}>Distrito Tecnológico, Buenos Aires</h1>
                        {/* <a href="tel:0800 345 4466" style={styles.phoneText}> 0800 345 4466</a> */}
                    </div>
                    <div style={styles.singleAddress}>
                        <h1 style={styles.titleAddress}>CHILE</h1>
                        <h1 style={styles.addressText}>Avenida Ricardo Lyon 222</h1>
                        <h1 style={styles.addressText}>Santiago de Chile</h1>
                        {/* <a href="tel:+34915774549" style={styles.phoneText}>+34 91 577 4549</a> */}
                    </div>
                    <div style={styles.singleAddress}>
                        {language === true ?
                            <h1 style={styles.titleAddress}>REINO UNIDO</h1> :
                            <h1 style={styles.titleAddress}>UNITED KINGDOM</h1>}
                        <h1 style={styles.addressText}>44 Kirkgate</h1>
                        <h1 style={styles.addressText}>Ripon, North Yorkshire</h1>
                    </div>
                    <div style={styles.singleAddress}>
                        {language === true ?
                            <h1 style={styles.titleAddress}>ESTADOS UNIDOS</h1> :
                            <h1 style={styles.titleAddress}>UNITED STATES</h1>}
                        <h1 style={styles.addressText}>3401 SW 160th Ave</h1>
                        <h1 style={styles.addressText}>Miami</h1>
                    </div>
                </div>
                <div style={styles.footerContainer}>
                    <h1 style={styles.textFooter}>{`Copyright © ${year} Inside One SL. All Rights Reserved.`}</h1>
                </div>
            </div>
        </>
    );
};
