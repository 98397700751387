const initialState = {
  loginUser: {},
  isAuth: false,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case "SET_LOGIN":
      return Object.assign({}, state, {
        loginUser: action.user,
        isAuth: true,
      });
    case "SET_LOGOUT":
      return Object.assign({}, state, {
        loginUser: action.user,
        isAuth: false,
      });
    default:
      return state;
  }
};
