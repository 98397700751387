import React, { useState, useEffect } from "react";
import Today from "../components/Today";
import Sidebar from "./Sidebar";
import firebase from '../config/firebaseIO'
import { useSelector } from "react-redux";

const db = firebase.firestore()

const TodayClient = () => {

    const [chartState, setChartState] = useState('');
    const [darkMode, setDarkMode] = useState(false);
    const [screenSize, setScreenSize] = useState(window.innerWidth);
    const [news, setNews] = useState([{ id: '0000', title: 'Estas al día 🎉' }]);

    const loginUser = useSelector(state => state.user.loginUser)

    const handleDarkMode = () => { setDarkMode(!darkMode) };

    const handleResize = (e) => { setScreenSize(window.innerWidth) };

    useEffect(() => {
        window.scrollTo(0, 0);
        window.addEventListener("resize", handleResize);
        let newsToState = []
        db.collection("news").orderBy("date")
            .get()
            .then((news) => {
                news.forEach((singleNew) => {
                    newsToState.push({
                        id: singleNew.id,
                        title: singleNew.data().title,
                        description: singleNew.data().description,
                        date: singleNew.data().date,
                        editor: singleNew.data().editor,
                    })
                });
            }).then(() => { setNews(newsToState.concat(news)) })
    }, []);

    return (
        <>
            <Sidebar />
            <Today
                handleDarkMode={handleDarkMode}
                chartState={chartState}
                darkModeState={darkMode}
                screenSize={screenSize}
                news={news}
                loginUser={loginUser}
            />
        </>
    );
}

export default TodayClient;