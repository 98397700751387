export default function authHeader() {
  const user = JSON.parse(localStorage.getItem('user'));
  // TODO si el usuario tiene caducado el token actualizar el objeto usuario en localStorage
  if (user && (user.accessToken || (user.stsTokenManager && user.stsTokenManager.accessToken))) {
    const tk = user.accessToken || user.stsTokenManager.accessToken;
    const ret = { Authorization: 'Bearer ' + tk} 
      return ret;
    } else {
      return {};
    }
  }