import React from "react";
import ChartSocial from "./ChartSocial"
import { Link } from "react-router-dom";

export default ({ screenSize, chartState, handleDarkMode, darkModeState, news, loginUser }) => {
    const styles = {
        backColor: {
            position: "fixed",
            left: 0,
            top: 0,
            right: 0,
            bottom: 0,
            height: "100%",
            backgroundColor: `${darkModeState ? ("hsl(230, 17%, 14%)") : ("hsl(0, 0%, 100%)")}`
        },
        topBackColor: {
            position: "fixed",
            left: 0,
            top: 0,
            right: 0,
            bottom: 0,
            height: "29%",
            backgroundColor: `${darkModeState ? ("hsl(232, 19%, 15%)") : ("hsl(225, 100%, 98%)")}`,
            borderRadius: "0px 0px 25px 25px"
        },
        container: {
            position: "fixed",
            bottom: 0,
            top: 0,
            left: 0,
            right: 0,
            display: "flex",
            flexDirection: "column",
            //   padding: `${screenSize > 800 ? ("3% 2% 3% 20%") : ("30px")}`,
            padding: `${screenSize > 800 ? ("3% 2% 3% 280px") : ("30px")}`,
            height: "100%",
            overflow: `auto`,
        },
        navbar: {
            display: "flex",
            flex: 1.5,
            margin: `${screenSize > 800 ? ("0px 20px") : ("0px")}`,
            marginBottom: `${screenSize > 800 ? ("0px") : ("30px")}`,
        },
        textsNavbar: {
            display: "flex",
            flex: 8,
            flexDirection: "column",
        },
        titleNavbar: {
            marginBottom: `${screenSize > 800 ? ('5px') : ("10px")}`,
            fontSize: "28px",
            color: `${darkModeState ? ("hsl(0, 0%, 100%)") : ("hsl(230, 17%, 14%)")}`,
            fontFamily: "InterBold",
            lineHeight: '1.4'
        },
        subtitleNavbar: {
            margin: 0,
            fontSize: "14px",
            color: `${darkModeState ? ("hsl(228, 34%, 66%)") : ("hsl(228, 12%, 44%)")}`,
            fontFamily: "InterBold",
        },
        toogleNavbar: {
            display: "flex",
            flex: 4,
            alignItems: "center",
            justifyContent: `${screenSize > 800 ? ("flex-end") : ("center")}`,
            flexDirection: `${screenSize > 800 ? ("row") : ("column")}`,
        },
        textToogle: {
            fontSize: "14px",
            fontFamily: "InterBold",
            alignSelf: "center",
            textDecoration: "none",
            color: 'inherit',
            border: 'none',
            outline: 'none',
            padding: '12px',
            backgroundColor: `transparent`
        },
        indicators: {
            display: "flex",
            flex: 11,
            flexDirection: "column",
        },
        generalIndicators: {
            display: "flex",
            flex: 4,
            alignItems: 'center',
            justifyContent: 'center',
            flexWrap: `${screenSize > 800 ? ('no-wrap') : ("wrap")}`,
            margin: `${screenSize > 800 ? ('0px') : ("0px 0px 30px")}`,
        },
        singleGenIndicator: {
            display: "flex",
            width: `${screenSize > 800 ? ("22%") : ("80vw")}`,
            flexDirection: "column",
            alignItems: 'stretch',
            borderRadius: '5px',
            padding: '0px',
            textDecoration: "none",
            border: 'none',
            outline: 'none',
            margin: `${screenSize > 800 ? ('1.4%') : ("20px 10px")}`,
            height: '205px',
        },
        singleGenIndicatorDoble: {
            display: "flex",
            width: `${screenSize > 800 ? ("47%") : ("80vw")}`,
            flexDirection: "column",
            alignItems: 'stretch',
            borderRadius: '5px',
            padding: '0px',
            textDecoration: "none",
            border: 'none',
            outline: 'none',
            margin: `${screenSize > 800 ? ('1.4%') : ("20px 10px")}`,
            height: '205px',
        },
        singleGenIndicatorDobleList: {
            display: "flex",
            width: `100%`,
            flexDirection: "column",
            alignItems: 'stretch',
            borderRadius: '5px',
            padding: '0px',
            textDecoration: "none",
            border: 'none',
            outline: 'none',
            margin: 0,
            height: '100%',
            overflow: `auto`,
        },
        borderCardF: {
            display: "flex",
            height: '4px',
            width: '100%',
            flexDirection: "column",
            backgroundColor: "hsl(203, 89%, 53%)",
            borderRadius: '5px 5px 0px 0px',
        },
        borderCardT: {
            display: "flex",
            height: '4px',
            width: '100%',
            flexDirection: "column",
            backgroundColor: "hsl(195, 100%, 50%)",
            borderRadius: '5px 5px 0px 0px',
        },
        borderCardI: {
            display: "flex",
            height: '4px',
            width: '100%',
            flexDirection: "column",
            backgroundImage: "linear-gradient(to right, hsl(37, 97%, 70%), hsl(329, 70%, 58%))",
            borderRadius: '5px 5px 0px 0px',
        },
        borderCardY: {
            display: "flex",
            height: '4px',
            width: '100%',
            flexDirection: "column",
            backgroundColor: "hsl(348, 97%, 39%)",
            borderRadius: '5px 5px 0px 0px',
        },
        contentSingleIndicator: {
            display: "flex",
            height: '100%',
            flexDirection: "column",
            alignItems: 'center',
            justifyContent: 'center',
            padding: '30px 30px 40px',
        },
        contentSingleNew: {
            display: "flex",
            flexDirection: "row",
            borderBottom: 'thin solid #A8AFD3'
        },
        contentSingleNewFinal: {
            display: "flex",
            alignSelf: 'center',
        },
        userCard: {
            display: "flex",
            color: `${darkModeState ? ("hsl(228, 34%, 66%)") : ("hsl(228, 12%, 44%)")}`,
            margin: 0,
            alignItems: 'center',
            justifyContent: 'center',
        },
        textUser: {
            display: "flex",
            color: `${darkModeState ? ("hsl(228, 34%, 66%)") : ("hsl(228, 12%, 44%)")}`,
            margin: 0,
            fontSize: "14px",
            fontFamily: "InterBold",
            marginLeft: '10px'
        },
        newTitle: {
            display: "flex",
            flex: 2,
            alignItems: 'center',
            color: `${darkModeState ? ("hsl(0, 0%, 100%)") : ("hsl(230, 17%, 14%)")}`,
            margin: 0,
            fontSize: "16px",
            fontFamily: "InterBold",
            padding: '10px 10px 10px 20px'
        },
        newDescription: {
            display: "flex",
            flex: 4,
            alignItems: 'center',
            color: `${darkModeState ? ("hsl(0, 0%, 100%)") : ("hsl(230, 17%, 14%)")}`,
            margin: 0,
            fontSize: "16px",
            fontFamily: "InterRegular",
            padding: '10px'
        },
        newDate: {
            display: "flex",
            flex: 2,
            alignItems: 'center',
            justifyContent: 'center',
            color: `${darkModeState ? ("hsl(0, 0%, 100%)") : ("hsl(230, 17%, 14%)")}`,
            margin: 0,
            fontSize: "16px",
            fontFamily: "InterRegular",
            padding: '10px'
        },
        newTitleFinal: {
            display: "flex",
            alignItems: 'center',
            color: `${darkModeState ? ("hsl(0, 0%, 100%)") : ("hsl(230, 17%, 14%)")}`,
            margin: 0,
            fontSize: "16px",
            fontFamily: "InterBold",
            height: '68px'
        },
        numberCard: {
            display: "flex",
            color: `${darkModeState ? ("hsl(0, 0%, 100%)") : ("hsl(230, 17%, 14%)")}`,
            margin: 0,
            fontSize: "55px",
            fontFamily: "InterBold",
            marginTop: "-8px",
        },
        detailCard: {
            display: "flex",
            color: `${darkModeState ? ("hsl(228, 34%, 66%)") : ("hsl(228, 12%, 44%)")}`,
            margin: 0,
            fontSize: "12px",
            fontFamily: "InterRegular",
            letterSpacing: '5px',
            marginBottom: '20px'
        },
        compareCardUp: {
            display: "flex",
            color: "hsl(163, 72%, 41%)",
            margin: 0,
            fontSize: "12px",
            fontFamily: "InterBold",
            alignItems: 'flex-end',
        },
        compareCardDown: {
            display: "flex",
            color: "hsl(356, 69%, 56%)",
            margin: 0,
            fontSize: "12px",
            fontFamily: "InterBold",
            alignItems: 'flex-end',
        },
        compareCardCenter: {
            display: "flex",
            alignItems: 'center',
            marginBottom: '6px'
        },
        actionText: {
            display: "flex",
            color: `${darkModeState ? ("hsl(0, 0%, 100%)") : ("hsl(230, 17%, 14%)")}`,
            margin: 0,
            fontSize: "18px",
            fontFamily: "InterBold",
        },
        newsIndicators: {
            display: "flex",
            flex: 6,
            flexDirection: "column",
        },
        appsIndicators: {
            display: "flex",
            flex: 6,
            flexDirection: "column",
        },
        titleDetailIndicators: {
            display: "flex",
            flex: 2,
            alignItems: 'center',
            fontSize: "24px",
            color: `${darkModeState ? ("hsl(0, 0%, 100%)") : ("hsl(228, 12%, 44%)")}`,
            fontFamily: "InterBold",
            margin: `${screenSize > 800 ? ("20px 20px 0px") : ("0px")}`,
        },
        rowDetailIndicators: {
            display: "flex",
            flex: 10,
            alignItems: 'flex-start',
            justifyContent: 'center',
            flexWrap: `${screenSize > 800 ? ('wrap') : ("wrap")}`,
            margin: `${screenSize > 800 ? ('0px') : ("20px 0px")}`,
        },
        singleDetIndicator: {
            display: "flex",
            width: `${screenSize > 800 ? ("22%") : ("80vw")}`,
            flexDirection: "column",
            alignItems: 'space-between',
            justifyContent: 'space-between',
            borderRadius: '5px',
            padding: "25px",
            textDecoration: "none",
            border: 'none',
            outline: 'none',
            margin: `${screenSize > 800 ? ('1.4%') : ("10px")}`,
        },
        singleDetIndicatorTrello: {
            display: "flex",
            width: `${screenSize > 800 ? ("22%") : ("80vw")}`,
            flexDirection: "column",
            alignItems: 'space-between',
            justifyContent: 'space-between',
            borderRadius: '5px',
            padding: "25px",
            textDecoration: "none",
            border: 'none',
            outline: 'none',
            margin: `${screenSize > 800 ? ('1.4%') : ("10px")}`,
            backgroundColor: '#1D74B9'
        },
        singleDetIndicatorSlack: {
            display: "flex",
            width: `${screenSize > 800 ? ("22%") : ("80vw")}`,
            flexDirection: "column",
            alignItems: 'space-between',
            justifyContent: 'space-between',
            borderRadius: '5px',
            padding: "25px",
            textDecoration: "none",
            border: 'none',
            outline: 'none',
            margin: `${screenSize > 800 ? ('1.4%') : ("10px")}`,
            backgroundColor: '#461447'
        },
        singleDetIndicatorHubspot: {
            display: "flex",
            width: `${screenSize > 800 ? ("22%") : ("80vw")}`,
            flexDirection: "column",
            alignItems: 'space-between',
            justifyContent: 'space-between',
            borderRadius: '5px',
            padding: "25px",
            textDecoration: "none",
            border: 'none',
            outline: 'none',
            margin: `${screenSize > 800 ? ('1.4%') : ("10px")}`,
            backgroundColor: '#F27454'
        },
        singleDetIndicatorGuide: {
            display: "flex",
            width: `${screenSize > 800 ? ("22%") : ("80vw")}`,
            flexDirection: "column",
            alignItems: 'space-between',
            justifyContent: 'space-between',
            borderRadius: '5px',
            padding: "25px",
            textDecoration: "none",
            border: 'none',
            outline: 'none',
            margin: `${screenSize > 800 ? ('1.4%') : ("10px")}`,
            backgroundColor: '#EB4962'
        },
        singleDetIndicatorZendesk: {
            display: "flex",
            width: `${screenSize > 800 ? ("22%") : ("80vw")}`,
            flexDirection: "column",
            alignItems: 'space-between',
            justifyContent: 'space-between',
            borderRadius: '5px',
            padding: "25px",
            textDecoration: "none",
            border: 'none',
            outline: 'none',
            margin: `${screenSize > 800 ? ('1.4%') : ("10px")}`,
            backgroundColor: '#0A333A'
        },
        textDetailCard: {
            display: "flex",
            color: `${darkModeState ? ("hsl(228, 34%, 66%)") : ("hsl(228, 12%, 44%)")}`,
            margin: 0,
            fontSize: "14px",
            fontFamily: "InterBold",
        },
        contentSingleIndicatorLineTop: {
            display: "flex",
            alignItems: 'space-between',
            justifyContent: 'space-between',
            marginBottom: `12px`,
        },
        contentSingleIndicatorLineDown: {
            display: "flex",
            alignItems: 'space-between',
            justifyContent: 'space-between',
        },
        svgStyle: {
            width: "8px",
            height: '4px',
            alignSelf: 'center',
            marginRight: '5px'
        },
        svgStyleDown: {
            display: "flex",
            width: "8px",
            height: '4px',
            marginRight: '5px'
        },
        svgSocialStyle: {
            width: "20px",
            height: '20px',
            alignSelf: 'center',
        },
        logos: {
            height: '40px',
            margin: '10px 15px',
        },
        icons: {
            margin: "0px 15px 5px 0px",
            width: '20px',
            height: '20px',
            color: '#4dc2f1'
        },
    };

    let year = new Date().getFullYear()
    let month = new Date().getMonth() + 1
    if (`${month}`.length === 1) { month = "0" + month }
    let date = new Date().getDate()
    if (`${date}`.length === 1) { date = "0" + date }
    let dateTotal = (date + " / " + month + " / " + year)

    const svgUp = <svg xmlns="http://www.w3.org/2000/svg" style={styles.svgStyle} ><path fill="#1EB589" fillRule="evenodd" d="M0 4l4-4 4 4z" /></svg>
    const svgDown = <svg xmlns="http://www.w3.org/2000/svg" style={styles.svgStyle}><path fill="#DC414C" f="evenodd" d="M0 0l4 4 4-4z" /></svg>

    return (
        <>
            <div style={styles.backColor} />
            {/* {screenSize > 800 ? (<div style={styles.topBackColor} />) : (<></>)} */}
            <div style={styles.container}>
                <div style={styles.navbar}>
                    <div style={styles.textsNavbar}>
                        <h3 style={styles.titleNavbar}>Today is a new  day! 🎉</h3>
                        <h3 style={styles.subtitleNavbar}>{dateTotal}</h3>
                    </div>
                    <div className={darkModeState ? ("textDarkToogle") : ("textLightToogle")} style={styles.toogleNavbar}>
                        {darkModeState === true ? (<button style={styles.textToogle} onClick={handleDarkMode}>Light Mode</button>) : (<button style={styles.textToogle} onClick={handleDarkMode}>Dark Mode</button>)}
                        {/* {darkModeState === true ? (<>  <input type="checkbox" id="switch" onChange={handleDarkMode} /><label for="switch" >Toggle</label></>) : (<>  <input type="checkbox" id="switch" onChange={handleDarkMode} checked /><label for="switch" >Toggle</label></>)} */}
                    </div>
                </div>
                <div style={styles.indicators}>
                    {/* {chartState === true ? (
                        <ChartSocial
                            handleClick={handleClick}
                            darkModeState={darkModeState}
                            screenSize={screenSize}
                            socialMedia={socialMedia}
                            user={user}
                            totalFollowers={totalFollowers}
                        />
                    ) : (<></>)} */}
                    <div style={styles.generalIndicators}>
                        <Link className={darkModeState ? ("buttonDark") : ("buttonLight")} style={styles.singleGenIndicator}
                        // to="/clients"
                        >
                            <div style={styles.borderCardT} />
                            <div style={styles.contentSingleIndicator}>
                                <div style={styles.numberCard}>25</div>
                                <div style={styles.detailCard}>CLIENTES</div>
                                <div style={styles.compareCardUp}>{svgUp}2 This Month</div>
                            </div>
                        </Link>
                        <Link className={darkModeState ? ("buttonDark") : ("buttonLight")} style={styles.singleGenIndicator}
                        //    to="/projects"
                        >
                            <div style={styles.borderCardT} />
                            <div style={styles.contentSingleIndicator}>
                                <div style={styles.numberCard}>10</div>
                                <div style={styles.detailCard}>PROYECTOS</div>
                                <div style={styles.compareCardDown}>{svgDown}3 This Month</div>

                            </div>
                        </Link>

                        <div className={darkModeState ? ("buttonDark") : ("buttonLight")} style={styles.singleGenIndicatorDoble}>
                            <div style={styles.borderCardT} />
                            <div style={styles.singleGenIndicatorDobleList}>
                                <>
                                    {news ? (
                                        news.map((singleNew) => {
                                            return (
                                                <>
                                                    {singleNew.id === '0000' ? (
                                                        <div style={styles.contentSingleNewFinal}>
                                                            <div style={styles.newTitleFinal}>{singleNew.title}</div>
                                                        </div>
                                                    ) : (
                                                        <div style={styles.contentSingleNew}>
                                                            <div style={styles.newTitle}>{singleNew.title}</div>
                                                            <div style={styles.newDescription}>{singleNew.description}</div>
                                                            {/* <div style={styles.newDate}>{singleNew.date}</div> */}
                                                        </div>
                                                    )}
                                                </>
                                            )
                                        })) : (null)}
                                </>
                            </div>
                        </div>
                    </div>
                    <div style={styles.newsIndicators}>
                        <h3 style={styles.titleDetailIndicators}>Actions</h3>
                        <div style={styles.rowDetailIndicators}>
                            <Link className={darkModeState ? ("buttonDark") : ("buttonLight")} style={styles.singleDetIndicator}
                            //  to="/people" 
                            >
                                <div style={styles.contentSingleIndicatorLineTop}>
                                    <svg style={styles.icons} fill="currentColor" class="bi bi-award" viewBox="0 0 16 16">
                                        <path d="M9.669.864 8 0 6.331.864l-1.858.282-.842 1.68-1.337 1.32L2.6 6l-.306 1.854 1.337 1.32.842 1.68 1.858.282L8 12l1.669-.864 1.858-.282.842-1.68 1.337-1.32L13.4 6l.306-1.854-1.337-1.32-.842-1.68L9.669.864zm1.196 1.193.684 1.365 1.086 1.072L12.387 6l.248 1.506-1.086 1.072-.684 1.365-1.51.229L8 10.874l-1.355-.702-1.51-.229-.684-1.365-1.086-1.072L3.614 6l-.25-1.506 1.087-1.072.684-1.365 1.51-.229L8 1.126l1.356.702 1.509.229z" />
                                        <path d="M4 11.794V16l4-1 4 1v-4.206l-2.018.306L8 13.126 6.018 12.1 4 11.794z" />
                                    </svg>
                                </div>
                                <div style={styles.contentSingleIndicatorLineDown}>
                                    <div style={styles.actionText}>Give valoration</div>
                                </div>
                            </Link>
                            <Link className={darkModeState ? ("buttonDark") : ("buttonLight")} style={styles.singleDetIndicator}
                            //  to="/people"
                            >
                                <div style={styles.contentSingleIndicatorLineTop}>
                                    <svg style={styles.icons} fill="currentColor" className="bi bi-clipboard-check" viewBox="0 0 16 16">
                                        <path fillRule="evenodd" d="M10.854 7.146a.5.5 0 0 1 0 .708l-3 3a.5.5 0 0 1-.708 0l-1.5-1.5a.5.5 0 1 1 .708-.708L7.5 9.793l2.646-2.647a.5.5 0 0 1 .708 0z" />
                                        <path d="M4 1.5H3a2 2 0 0 0-2 2V14a2 2 0 0 0 2 2h10a2 2 0 0 0 2-2V3.5a2 2 0 0 0-2-2h-1v1h1a1 1 0 0 1 1 1V14a1 1 0 0 1-1 1H3a1 1 0 0 1-1-1V3.5a1 1 0 0 1 1-1h1v-1z" />
                                        <path d="M9.5 1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-3a.5.5 0 0 1-.5-.5v-1a.5.5 0 0 1 .5-.5h3zm-3-1A1.5 1.5 0 0 0 5 1.5v1A1.5 1.5 0 0 0 6.5 4h3A1.5 1.5 0 0 0 11 2.5v-1A1.5 1.5 0 0 0 9.5 0h-3z" />
                                    </svg>
                                </div>
                                <div style={styles.contentSingleIndicatorLineDown}>
                                    <div style={styles.actionText}>Do survey</div>
                                </div>
                            </Link>
                            <Link className={darkModeState ? ("buttonDark") : ("buttonLight")} style={styles.singleDetIndicator}
                            //    to="/people"
                            >
                                <div style={styles.contentSingleIndicatorLineTop}>
                                    <svg style={styles.icons} fill="currentColor" class="bi bi-calendar-check" viewBox="0 0 16 16">
                                        <path d="M10.854 7.146a.5.5 0 0 1 0 .708l-3 3a.5.5 0 0 1-.708 0l-1.5-1.5a.5.5 0 1 1 .708-.708L7.5 9.793l2.646-2.647a.5.5 0 0 1 .708 0z" />
                                        <path d="M3.5 0a.5.5 0 0 1 .5.5V1h8V.5a.5.5 0 0 1 1 0V1h1a2 2 0 0 1 2 2v11a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V3a2 2 0 0 1 2-2h1V.5a.5.5 0 0 1 .5-.5zM1 4v10a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1V4H1z" />
                                    </svg>
                                </div>
                                <div style={styles.contentSingleIndicatorLineDown}>
                                    <div style={styles.actionText}>Ask vacations</div>
                                </div>
                            </Link>
                            <Link className={darkModeState ? ("buttonDark") : ("buttonLight")} style={styles.singleDetIndicator}
                            //   to="/people"
                            >
                                <div style={styles.contentSingleIndicatorLineTop}>
                                    <svg style={styles.icons} fill="currentColor" class="bi bi-clock" viewBox="0 0 16 16">
                                        <path d="M8 3.5a.5.5 0 0 0-1 0V9a.5.5 0 0 0 .252.434l3.5 2a.5.5 0 0 0 .496-.868L8 8.71V3.5z" />
                                        <path d="M8 16A8 8 0 1 0 8 0a8 8 0 0 0 0 16zm7-8A7 7 0 1 1 1 8a7 7 0 0 1 14 0z" />
                                    </svg>
                                </div>
                                <div style={styles.contentSingleIndicatorLineDown}>
                                    <div style={styles.actionText}>Document hours</div>
                                </div>
                            </Link>
                        </div>
                    </div>
                    <div style={styles.appsIndicators}>
                        <h3 style={styles.titleDetailIndicators}>Our apps</h3>
                        <div style={styles.rowDetailIndicators}>
                            <button className={darkModeState ? ("buttonDark") : ("buttonLight")} style={styles.singleDetIndicatorTrello}
                                onClick={() => (window.open("https://trello.com/"))}
                            >
                                <div style={styles.contentSingleIndicatorLineDown}>
                                    <img src="https://i.postimg.cc/SxskCWmp/trello.png" style={styles.logos} alt="Logo of Trello" />
                                </div>
                            </button>
                            <button className={darkModeState ? ("buttonDark") : ("buttonLight")} style={styles.singleDetIndicatorSlack}
                                onClick={() => (window.open("https://insideonecloud.slack.com/"))}
                            >
                                <div style={styles.contentSingleIndicatorLineDown}>
                                    <img src="https://i.postimg.cc/sxwbgNQG/slack.png" style={styles.logos} alt="Logo of Slack" />
                                </div>
                            </button>
                            {loginUser.rol === 'Commercial' || loginUser.rol === 'Admin' ? (
                                <button className={darkModeState ? ("buttonDark") : ("buttonLight")} style={styles.singleDetIndicatorHubspot}
                                    onClick={() => (window.open("https://app.hubspot.com/login"))}
                                >
                                    <div style={styles.contentSingleIndicatorLineDown}>
                                        <img src="https://i.postimg.cc/kG4ffXGD/hubspot.png" style={styles.logos} alt="Logo of Hubspot" />
                                    </div>
                                </button>
                            ) : (
                                <button className={darkModeState ? ("buttonDark") : ("buttonLight")} style={styles.singleDetIndicatorGuide}
                                    onClick={() => (window.open("https://insideone.zendesk.com/hc/es"))}
                                >
                                    <div style={styles.contentSingleIndicatorLineDown}>
                                        <img src="https://i.postimg.cc/Qt9WmYwH/guide.png" style={styles.logos} alt="Logo of Zendesk Guide" />
                                    </div>
                                </button>
                            )}
                            <button className={darkModeState ? ("buttonDark") : ("buttonLight")} style={styles.singleDetIndicatorZendesk}
                                onClick={() => (window.open("https://insideone.zendesk.com/access/unauthenticated"))}
                            >
                                <div style={styles.contentSingleIndicatorLineDown}>
                                    <img src="https://i.postimg.cc/2810W6gK/zendesk.png" style={styles.logos} alt="Logo of Zendesk" />
                                </div>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};
