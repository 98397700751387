import React from "react";

export default ({ screenSize, handleLogin, username, password, message, loading, onChangeUsername, onChangePassword }) => {
    const styles = {
        container: {
            position: "fixed",
            bottom: 0,
            top: 0,
            left: 0,
            right: 0,
            display: "flex",
            margin: 0,
            padding: 0,
            backgroundColor: '#fff'
        },
        columnImage: {
            display: "flex",
            height: "100vh",
            width: "50vw",
            margin: 0,
            padding: 0,
            backgroundImage: `url(${"https://i.postimg.cc/pr1b0Vf9/login-image.jpg"})`,
            backgroundRepeat: "no-repeat",
            backgroundSize: "cover",
        },
        imageLogo: {
            height: "10%",
            width: "auto",
            position: "relative",
            left: "10%",
            top: "80%",
        },
        columnInputs: {
            display: "flex",
            justifyContent: 'center',
            alignItems: 'center',
            width: `${screenSize > 800 ? ("50vw") : ("100vw")}`,
            margin: 0,
            padding: 0,
        },
        form: {
            width: "100%",
            padding: 40
        },
        formTitle: {
            fontSize: `${screenSize > 800 ? ("40px") : ("30px")}`,
            marginBottom: 30,
        },
        formLabel: {
            marginBottom: 10,
            fontFamily: "InterRegular",
        },
        formInput: {
            marginBottom: 20,
            fontFamily: "InterRegular",
        },
        formButton: {
            display: "flex",
            alignItems: "center",
            justifyContent: 'center',
            backgroundColor: '#4DC2F1',
            borderRadius: 25,
            border: 'none',
            padding: '8px 32px',
            margin: "30px 0px 0px",
            color: '#fff',
            fontWeight: 500,
            outline: 0,
            width: 130,
            height: 40
        },
        formLoading: {
            width: 20,
            height: 20
        },
        textError: {
            margin: 0,
            fontSize: "16px",
            color: 'hsl(356, 69%, 56%)',
            alignSelf: 'center'
        },
        buttonFloat: {
            position: "absolute",
            bottom: 15,
            right: -100,
            display: "flex",
            justifyContent: "flex-end",
            height: '280px'
        },
    }

    return (
        <div style={styles.container}>
            {screenSize > 800 ? (
                <div style={styles.columnImage} >
                    <img
                        src="https://i.postimg.cc/ydB9XXzt/insideone-white.png"
                        alt="Logo of Inside One"
                        style={styles.imageLogo}
                    />
                </div>
            ) : (<></>)}
            <div style={styles.columnInputs} >
                <form onSubmit={handleLogin} style={styles.form}
                // ref={form}
                >
                    <div className="form-group">
                        <h1 style={styles.formTitle}>Sign in to InsideOne</h1>
                        <label style={styles.formLabel} htmlFor="username">Email</label>
                        <input
                            name="username"
                            type="email"
                            className="form-control"
                            id="username"
                            value={username}
                            onChange={onChangeUsername}
                            style={styles.formInput}
                        //  validations={[required]}
                        />
                        {/* <small id="emailHelp" className="form-text text-muted"> No compartiremos su información. </small> */}
                        <label style={styles.formLabel} htmlFor="inputPassword">Password</label>
                        <input
                            name="password"
                            type="password"
                            className="form-control"
                            id="inputPassword"
                            value={password}
                            onChange={onChangePassword}
                            style={styles.formInput}
                        //   validations={[required]}
                        />
                        {/* <Link style={{ marginLeft: "20px", }} to="/recover" >  Recuperar contraseña  </Link> */}
                        {message ? <h3 style={styles.textError}>{message}</h3> : <></>}
                        {loading ? (
                            <button type="submit" style={styles.formButton} disabled>
                                <div className="spinner-border text-light spinner-border-sm" role="status" />
                            </button>
                        ) : (
                            <button type="submit" style={styles.formButton}>Sign in</button>
                        )}
                    </div>
                </form>
            </div>
            {screenSize > 800 ? (
                <img
                    style={styles.buttonFloat}
                    src="https://i.postimg.cc/SRjc48Cv/Eric-Apuntando-Alpha-baja.gif"
                    alt="Bot of Inside One"
                />
            ) : (<></>)}
        </div>
    )
}
