//import firebase from '../config/firebaseTest'
import firebase from '../config/firebaseIO'

// const register = (username, email, password) => {
//   /* return axios.post(API_URL + "signup", {
//     username,
//     email,
//     password,
//   }); */
// };

const login = (username, password) => {
  return firebase.auth().signInWithEmailAndPassword(username, password)
    .then(user => {
      return user;
    }).catch(e => {
      throw e;
    })
};

const logout = () => {
  firebase.auth().signOut().then(() => {
    localStorage.removeItem("user");
  }).catch((error) => {
    throw error;
  });
};

const getCurrentUser = () => {
  const user = JSON.parse(localStorage.getItem("user"));
  return user
};

const connStatus = () => {
  firebase.auth().onAuthStateChanged(user => {
    if (!user) {
      localStorage.removeItem("user");
    } else {
      if (user) {
        localStorage.setItem("user", JSON.stringify(user));
        //Recuperar usuario de la bd para configuración personal, roles, permisos, etc.
      }
    }
  })
}

const auth_service = {
  //  register,
  login,
  logout,
  getCurrentUser,
  connStatus
};

export default auth_service;