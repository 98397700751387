import React, { useState, useEffect } from 'react'
import { Link } from "react-router-dom";

export default ({ screenSize, handleClick, handleDarkMode, darkModeState, handleLanguage, handleChange, handleSubmit, language, origin }) => {

    const [closeNavbar, setCloseNavbar, setSolutionBanner] = useState(false);

    const handleButton = (type) => {
        if (type == "bot") {
            setCloseNavbar(false)
            handleClick("solutions-bot")
        } else if (type == "omnichannel") {
            setCloseNavbar(false)
            handleClick("solutions-omnichannel")
        } else if (type == "contact-center") {
            setCloseNavbar(false)
            handleClick("solutions-contactcenter")
        } else if (type == "integrations") {
            setCloseNavbar(false)
            handleClick("solutions-integrations")
        } else {
            setCloseNavbar(false)
            handleClick("contact")
        }
    }

    const styles = {
        container: {
            //    position: "fixed",
            //   bottom: 0,
            //  top: 0,
            // left: 0,
            //  right: 0,
            display: "flex",
            flexDirection: "column",
            backgroundColor: `${darkModeState ? ("hsl(230, 17%, 14%)") : ("hsl(0, 0%, 100%)")}`,
            //   padding: `${screenSize > 800 ? ("3% 2% 3% 20%") : ("30px")}`,
            //   padding: `${screenSize > 800 ? ("3% 2% 3% 280px") : ("30px")}`,
            //    height: "100%",
            overflow: `auto`,
        },
        navbar: {
            display: "flex",
            height: "60px",
            alignItems: 'center',
            justifyContent: 'space-between',
            margin: `${screenSize > 800 ? ("0px 9vw") : ("0px")}`,
            padding: '10px 0px',
            // width: '100%'
            //    flex: 1.5,
            //   marginBottom: `${screenSize > 800 ? ("0px") : ("30px")}`,
        },
        navbarOpen: {
            // display: "flex",
            // alignItems: 'center',
            // justifyContent: 'space-between',
            // height: "110px",
            // width: '100%',
            // padding: `${screenSize > 800 ? ("20px 60px") : ("20px")}`,
            // margin: "0px",

            //    flex: 1.5,
            //   marginBottom: `${screenSize > 800 ? ("0px") : ("30px")}`,

            position: "fixed",
            top: 0,
            right: 0,
            bottom: `${screenSize > 800 ? (<></>) : ("0")} `,
            left: 0,
            display: "flex",
            flexDirection: 'row',
            height: "100px",
            width: "100%",
            alignItems: 'center',
            justifyContent: 'space-between',
            padding: `${screenSize > 800 ? ("0px 60px") : ("20px")}`,
            margin: "0px",
            zIndex: 200,
            backgroundColor: '#fff',
            //     borderBottom: '1px solid rgba(0, 0, 0, .2)',
        },
        navbarMenu: {
            position: "fixed",
            top: 0,
            bottom: `${screenSize > 800 ? ("0") : ("0")} `,
            left: 0,
            display: "flex",
            flexDirection: 'column',
            height: "100vh",
            width: "33vw",
            alignItems: 'flex-start',
            justifyContent: 'center',
            padding: `${screenSize > 800 ? ("0px 60px") : ("20px")}`,
            margin: "0px",
            zIndex: 100,
            backgroundColor: '#fff',
            boxShadow: `${screenSize > 800 ? ("0 3px 16px 0 rgba(150,150,150,0.2)") : (<></>)}`,
            //     borderBottom: '1px solid rgba(0, 0, 0, .2)',
        },
        navbarMenuGroup: {
            display: 'flex',
            flexDirection: 'column',
            width: "100%",
            //      flex: '1'
        },
        textMenuSubtitle: {
            fontSize: 16,
            color: 'grey',
            margin: "18px 0px",
            fontFamily: "UbuntuRegular",
        },
        textMenu: {
            backgroundColor: 'transparent',
            color: '#000',
            border: 'none',
            outline: 0,
            padding: 0,
            fontSize: 18,
            margin: "18px 0px",
            fontFamily: "UbuntuBold",
            display: 'flex',
            alignItems: 'flex-start',
            justifyContent: 'flex-start',
            textAlign: 'left'
        },
        lineMenu: {
            display: 'flex',
            width: "100%",
            height: "1px",
            alignSelf: 'flex-end',
            backgroundColor: '#000',
            margin: "30px 0px",
        },
        navbarClose: {
            position: "fixed",
            top: 0,
            right: 0,
            bottom: `${screenSize > 800 ? ("0") : ("0")} `,
            left: 0,
            display: "flex",
            flexDirection: 'row',
            height: "100px",
            width: "100%",
            alignItems: 'center',
            justifyContent: 'space-between',
            padding: `${screenSize > 800 ? ("0px 60px") : ("20px")}`,
            margin: "0px",
            zIndex: 200,
            backgroundColor: '#fff',
            //     borderBottom: '1px solid rgba(0, 0, 0, .2)',
        },
        logoContainer: {
            display: "flex",
            flex: '1',
            alignItems: "center",
            justifyContent: 'center',
            border: 'none',
            padding: 0,
            outline: 0,
            backgroundColor: 'transparent',
            //    backgroundColor: 'red'
            //   padding: "20px 0px"
        },
        titleNavbar: {
            //  marginBottom: `${screenSize > 800 ? ('5px') : ("10px")}`,
            margin: 0,
            fontSize: "28px",
            color: `${darkModeState ? ("hsl(0, 0%, 100%)") : ("hsl(230, 17%, 14%)")}`,
            fontFamily: "InterBold",
            lineHeight: '1.4'
        },
        buttonsNavbarLeft: {
            display: 'flex',
            flex: 2,
            flexDirection: 'row',
            alignItems: 'flex-end',
            justifyContent: 'flex-start'
        },
        buttonsNavbarRight: {
            display: 'flex',
            flex: 2,
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'flex-end'
        },
        buttonNavbarPlain: {
            backgroundColor: 'transparent',
            color: '#4dc2f1',
            borderRadius: 25,
            border: 'none',
            padding: 0,
            outline: 0,
            margin: "0px 15px 0px 0px",
            display: 'flex',
            flexDirection: 'row',
            // alignItem: 'center',
            // justifyContent: 'center'
        },
        textButtonPlain: {
            margin: 0,
            fontSize: 16,
            color: '#4dc2f1',
            margin: "0px 15px 0px 0px",
            fontFamily: "UbuntuRegular",
            //  textAlign: 'start'
        },
        line: {
            display: 'flex',
            width: "30px",
            height: "2px",
            alignSelf: 'flex-end',
            marginBottom: "3px",
            backgroundColor: '#4dc2f1'
        },
        buttonNavbarWhite: {
            backgroundColor: 'transparent',
            color: '#4dc2f1',
            borderRadius: 25,
            border: '2px solid #4dc2f1',
            padding: '3px 18px',
            height: '45px',
            width: '130px',
            outline: 0,
            margin: "0px 5px"
        },
        buttonNavbarBlue: {
            backgroundColor: '#4DC2F1',
            borderRadius: 25,
            border: 'none',
            padding: '3px 18px',
            height: '45px',
            width: '130px',
            outline: 0,
            margin: "0px 10px"
        },
        buttonLanguage: {
            backgroundColor: 'transparent',
            border: 'none',
            padding: '3px 18px',
            height: '35px',
            width: '70px',
            outline: 0
        },
        textButtonBlue: {
            margin: 0,
            fontSize: "14px",
            color: '#4dc2f1',
            fontFamily: "UbuntuRegular",
        },
        textButtonWhite: {
            margin: 0,
            fontSize: "14px",
            color: '#fff',
            fontFamily: "UbuntuRegular",
        },
        textThanks: {
            margin: 0,
            fontSize: "16px",
            color: '#fff',
            alignSelf: 'centerr'
        },
        textButtonLanguage: {
            margin: 0,
            fontSize: "14px",
            color: '#333c4e',
        },
        textButtonLanguageHeader: {
            margin: 0,
            fontSize: "14px",
            color: '#4dc2f1',
            fontFamily: "UbuntuRegular",
            //  color: '#000',
        },
        bannerHeader: {
            display: 'flex',
            flexDirection: `${screenSize > 800 ? ('row') : ('column')}`,
            width: '100vw',
            padding: `${screenSize > 800 ? ('0px 7vw 0px 9vw') : ('0px')}`,
            //      backgroundColor: 'red',
        },
        textsHeader: {
            display: 'flex',
            flex: 1,
            alignItems: 'flex-start',
            justifyContent: 'center',
            flexDirection: 'column',
            textAlign: 'start',
        },
        titleHeader: {
            fontSize: `${screenSize > 800 ? ('48px') : ('25px')}`,
            //   margin: `${screenSize > 800 ? ('0px 8vw 40px') : ('20px 15vw 20px')}`,
            margin: `${screenSize > 800 ? ('0px 1vw 40px 2vw') : ('20px 15vw 20px')}`,
            fontFamily: "UbuntuRegular",
            color: '#000',
            textAlign: `${screenSize > 800 ? (<></>) : ('center')}`,
        },
        subtitleHeader: {
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'center',
            //   margin: `${screenSize > 800 ? ('0px 8vw 40px') : ('20px 15vw 20px')}`,
            margin: `${screenSize > 800 ? ('0px 1vw 40px 2vw') : ('20px 15vw 20px')}`,
            backgroundColor: 'transparent',
            border: 'none',
            outline: 0,
        },
        subtitleHeaderText: {
            color: '#D0B5F0',
            fontSize: '18px',
            fontFamily: 'UbuntuRegular',
            fontWeight: '600',
            margin: '0px 15px 0px 0px'
            //  padding: '0px 5vw'
            // textAlign: 'center'
        },
        divButtonHeader: {
            display: 'flex',
            alignItem: 'center',
            justifyContent: 'center',
            height: '10vh'
        },
        buttonHeader: {
            display: 'flex',
            alignItem: 'center',
            justifyContent: 'center',
            backgroundColor: '#4DC2F1',
            borderRadius: 25,
            border: 'none',
            // padding: '3px 18px',
            height: '40px',
            width: '40px',
            color: '#fff',
            outline: 0,
        },



        bannerSolutions: {
            display: "flex",
            flexDirection: 'column',
            margin: `${screenSize > 800 ? ('0px') : ('50px 0px 0px')}`,
            padding: `${screenSize > 800 ? ('100px 0px') : ('50px 0px')}`,
            alignItems: "center",
            justifyContent: 'center',
            //  height: "70vh",
            textAlign: 'center',
            // flexWrap: `${screenSize > 800 ? ('nowrap') : ('wrap')}`,
        },
        titleBannerSolutions: {
            fontSize: `${screenSize > 800 ? ('40px') : ('25px')}`,
            //   margin: `${screenSize > 800 ? ('0px 8vw 40px') : ('20px 15vw 20px')}`,
            margin: `${screenSize > 800 ? ('-10px 0px 0px') : ('0px 0px 0px')}`,
            fontFamily: "UbuntuRegular",
            color: '#4dc2f1',
            textAlign: `${screenSize > 800 ? (<></>) : ('center')}`,
        },
        subtitleBannerSolutions: {
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'center',
            //   margin: `${screenSize > 800 ? ('0px 8vw 40px') : ('20px 15vw 20px')}`,
            margin: '50px 0px 40px',
            backgroundColor: 'transparent',
            border: 'none',
            outline: 0,
        },
        subtitleBannerSolutionsText: {
            color: '#000',
            fontSize: '18px',
            fontFamily: 'UbuntuRegular',
            fontWeight: '600',
            margin: '0px'
            //  padding: '0px 5vw'
            // textAlign: 'center'
        },
        buttonBannerSolutions: {
            display: 'flex',
            //     flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'center',
            //   margin: `${screenSize > 800 ? ('0px 8vw 40px') : ('20px 15vw 20px')}`,
            margin: '0px 0px 20px',
            backgroundColor: 'transparent',
            border: '1px solid #000',
            borderRadius: '50px',
            outline: 0,
            height: '45px',
            width: '180px',
        },
        buttonBannerSolutionsText: {
            // display: 'flex',
            // alignSelf: 'center',
            color: '#000',
            fontSize: '14px',
            fontFamily: 'UbuntuRegular',
            fontWeight: '600',
            margin: '0px',
            //    padding: '13px 20px',
            //    padding: '3px 18px',

            // textAlign: 'center'
        },




        columnContainer: {
            display: "flex",
            margin: '50px 0px',
            alignItems: "center",
            justifyContent: 'center',
            flexWrap: `${screenSize > 800 ? ('nowrap') : ('wrap')}`,
        },
        columnContainerReverse: {
            display: "flex",
            margin: '50px 0px',
            alignItems: "center",
            justifyContent: 'center',
            flexWrap: `${screenSize > 800 ? ('nowrap') : ('wrap-reverse')}`,
        },
        columnContainerNoBottom: {
            display: "flex",
            margin: '50px 0px 0px',
            alignItems: "center",
            justifyContent: 'center',
            flexWrap: `${screenSize > 800 ? ('nowrap') : ('wrap')}`,
        },
        columnContainerNoTop: {
            display: "flex",
            margin: '0px 0px 50px',
            alignItems: "center",
            justifyContent: 'center',
            flexWrap: `${screenSize > 800 ? ('nowrap') : ('wrap')}`,
        },
        columnContainerNoTopNoBottom: {
            display: "flex",
            margin: `${screenSize > 800 ? ('0px') : ('30px 0px 0px')}`,
            alignItems: "center",
            justifyContent: 'center',
            flexWrap: `${screenSize > 800 ? ('nowrap') : ('wrap')}`,
        },
        columnText: {
            display: "flex",
            flexDirection: 'column',
            alignItems: "flex-start",
            justifyContent: 'center',
            margin: 0,
            //   width: `${screenSize > 800 ? ("50vw") : ("80vw")}`,
            width: `${screenSize > 800 ? ("50vw") : ("100%")}`,
            padding: `${screenSize > 800 ? (0) : ("10px")}`,
        },
        columnTextGrey: {
            display: "flex",
            flexDirection: 'column',
            alignItems: "flex-start",
            justifyContent: 'center',
            margin: 0,
            width: `${screenSize > 800 ? ("50vw") : ("100%")}`,
            padding: `${screenSize > 800 ? (0) : ("10px")}`,
            backgroundColor: "#F5F7FA",
            height: `${screenSize > 800 ? ("480px") : (<></>)} `,
        },
        columnTextPadding: {
            padding: `${screenSize > 800 ? ("0px 80px") : ("20px")}`,
        },
        columnTextContext: {
            fontFamily: 'sans-serif',
            fontWeight: '600',
            fontSize: '16px',
            color: '#a4abae',
            lineHeight: '1.45',
            margin: "0px 0px 15px 0px",

        },
        columnTextTitle: {
            fontFamily: 'sans-serif',
            fontWeight: '600',
            fontSize: '27px',
            color: '#333c4e',
            lineHeight: '1.3',
            margin: "0px 0px 30px 0px",
        },
        columnTextBody: {
            fontFamily: 'sans-serif',
            fontWeight: '400',
            fontSize: '18px',
            color: '#4f5766',
            lineHeight: '1.7',
            margin: "20px 0px 0px",
        },
        titleCaseContainer: {
            display: "flex",
            margin: '50px 50px 0px',
            alignItems: "center",
            justifyContent: 'center',
        },
        titleCase: {
            fontFamily: 'sans-serif',
            fontWeight: '600',
            fontSize: '18px',
            textAlign: 'center',
            color: '#333c4e',
            lineHeight: '1.7',
            margin: 0,
        },
        columnCaseContainer: {
            display: "flex",
            margin: '50px 0px',
            alignItems: "center",
            justifyContent: 'center',
            flexWrap: `${screenSize > 800 ? ('wrap') : ('wrap')}`,
        },
        columnCase: {
            display: "flex",
            alignItems: "center",
            justifyContent: 'center',
            width: `${screenSize > 800 ? ("30vw") : ("80vw")}`,
            height: `${screenSize > 800 ? ("345px") : ("230px")}`,
            margin: 0,
            padding: `${screenSize > 800 ? (0) : ("20px")}`,
            backgroundColor: "#005093"
        },
        imageCase: {
            height: `${screenSize > 800 ? ("45px") : ("35px")}`,
        },
        imageCase2: {
            height: `${screenSize > 800 ? ("75px") : ("55px")}`,
        },
        imageCase3: {
            height: `${screenSize > 800 ? ("45px") : ("30px")}`,
        },
        columnCaseText: {
            display: "flex",
            alignItems: "center",
            justifyContent: 'center',
            width: `${screenSize > 800 ? ("30vw") : ("80vw")}`,
            height: `${screenSize > 800 ? ("345px") : (<></>)}`,
            margin: 0,
            backgroundColor: '#F5F7FA'
        },
        columnCaseTextRecordati: {
            display: "flex",
            alignItems: "center",
            justifyContent: 'center',
            width: `${screenSize > 800 ? ("30vw") : ("80vw")}`,
            height: `${screenSize > 800 ? ("445px") : (<></>)}`,
            margin: 0,
            backgroundColor: '#F5F7FA'
        },
        columnCasePadding: {
            padding: `${screenSize > 800 ? ("0px 80px") : ("20px")}`,
            display: "flex",
            flexDirection: 'column',
            alignItems: "center",
            justifyContent: 'center',
        },
        columnCasePaddingRecordati: {
            padding: `${screenSize > 800 ? ("0px 60px") : ("20px")}`,
            display: "flex",
            flexDirection: 'column',
            alignItems: "center",
            justifyContent: 'center',
        },
        columnTextCase: {
            fontFamily: 'sans-serif',
            fontWeight: '400',
            fontSize: '18px',
            textAlign: 'center',
            color: '#4f5766',
            lineHeight: '1.7',
            margin: "30px 0px",
        },
        columnTextCaseNoTop: {
            fontFamily: 'sans-serif',
            fontWeight: '400',
            fontSize: '18px',
            textAlign: 'center',
            color: '#4f5766',
            lineHeight: '1.7',
            margin: `${screenSize > 800 ? ("0px 0px 30px") : ("20px 10px 20px")}`,
        },
        columnTextCaseRecordati: {
            fontFamily: 'sans-serif',
            fontWeight: '400',
            fontSize: '17px',
            textAlign: 'start',
            color: '#4f5766',
            lineHeight: '1.7',
            margin: `${screenSize > 800 ? ("10px 0px") : ("20px 10px 20px")}`,
        },
        columnBoldCase: {
            fontFamily: 'sans-serif',
            fontWeight: '600',
            fontSize: '16px',
            textAlign: 'center',
            color: '#333c4e',
            lineHeight: '1.7',
            margin: 0,
            // padding: 0,
            padding: `${screenSize > 800 ? (0) : ("0px 0px 10px")}`,
        },
        titleClientContainer: {
            display: "flex",
            margin: '50px 50px 0px',
            alignItems: "center",
            justifyContent: 'center',
        },
        titleClient: {
            fontFamily: 'sans-serif',
            fontWeight: '400',
            fontSize: '26px',
            textAlign: 'center',
            color: '#333c4e',
            margin: 0,
            lineHeight: '1.7',
        },
        columnClientContainer: {
            display: "flex",
            margin: `${screenSize > 800 ? ('50px') : ("30px 30px 50px")}`,
            alignItems: "center",
            justifyContent: 'center',
            flexWrap: 'wrap'
        },
        imageClient: {
            height: 70,
            margin: 25
        },
        formContainer: {
            backgroundColor: "#333c4e",
            alignItems: "center",
            justifyContent: 'center',
            padding: `${screenSize > 800 ? ("100px") : ("100px 30px")}`,
            margin: '50px 0px'
        },
        titleForm: {
            fontFamily: 'sans-serif',
            fontWeight: '400',
            fontSize: '28px',
            textAlign: 'center',
            color: '#fff',
            margin: "0px 50px 70px",
            lineHeight: '1.7',
        },
        formCenter: {
            display: 'flex',
            alignItems: "center",
            justifyContent: 'center',
        },
        formInputsContainer: {
            display: "flex",
            // flex: 12,
            width: '440px',
            flexDirection: 'column',
            alignItems: "flex-start",
            justifyContent: 'center',
        },
        labelForm: {
            margin: 0,
            color: '#fff'
        },
        inputForm: {
            borderRadius: '25px',
            height: '40px',
            margin: "8px 0px 16px",
            fontFamily: 'sans-serif',
            fontWeight: '400',
            padding: '0px 16px'
        },
        inputFormMessage: {
            display: "flex",
            alignItems: "flex-start",
            justifyContent: 'flex-start',
            borderRadius: '15px',
            height: '160px',
            margin: "8px 0px 16px",
            fontFamily: 'sans-serif',
            fontWeight: '400',
            padding: '10px 16px',
        },
        buttonForm: {
            display: "flex",
            alignItems: "center",
            justifyContent: 'center',
            backgroundColor: '#4DC2F1',
            borderRadius: 25,
            border: 'none',
            padding: '13px 23px',
            margin: "22px 0px",
            height: '50px',
            outline: 0
        },
        columnAddressContainer: {
            display: "flex",
            // margin: '50px 120px 100px',
            margin: `${screenSize > 800 ? ('50px 120px 100px') : ("50px 30px")}`,
            alignItems: "flex-start",
            justifyContent: 'space-between',
            flexWrap: 'wrap'
        },
        singleAddress: {
            display: "flex",
            flexDirection: 'column',
            alignItems: "flex-start",
            justifyContent: 'flex-start',
            width: "250px",
            //height: 70,
            margin: "0px 0px 25px"
        },
        titleAddress: {
            fontFamily: 'sans-serif',
            fontWeight: '600',
            fontSize: '18px',
            textAlign: 'center',
            color: '#333c4e',
            lineHeight: '1.7',
            textAlign: 'left',
            margin: "0px 0px 15px",
        },
        addressText: {
            fontFamily: 'sans-serif',
            fontWeight: '400',
            fontSize: '18px',
            textAlign: 'center',
            color: '#4f5766',
            lineHeight: '1.7',
            textAlign: 'left',
            margin: "0px 0px 3px",
        },
        phoneText: {
            fontFamily: 'sans-serif',
            fontWeight: '400',
            fontSize: '18px',
            textAlign: 'center',
            color: '#4dc2f1',
            lineHeight: '1.7',
            margin: 0,
            textAlign: 'left',
        },
        footerContainer: {
            display: "flex",
            margin: "10px 0px 30px",
            alignItems: "flex-start",
            justifyContent: 'flex-start',
        },
        textFooter: {
            fontFamily: 'sans-serif',
            fontWeight: '400',
            fontSize: '14px',
            textAlign: `${screenSize > 800 ? ("center") : ("left")} `,
            color: '#4f5766',
            margin: `${screenSize > 800 ? ("0px 120px") : ("0px 30px")} `,
            lineHeight: '1.7',
        },
        svgStyle: {
            height: 16,
            width: 16,
            display: 'flex',
            alignSelf: 'center'
        },
        // toogleNavbar: {
        //     display: "flex",
        //     flex: 4,
        //     alignItems: "center",
        //     justifyContent: `${ screenSize > 800 ? ("flex-end") : ("center") } `,
        //     flexDirection: `${ screenSize > 800 ? ("row") : ("column") } `,
        // },
        // textToogle: {
        //     fontSize: "14px",
        //     fontFamily: "InterBold",
        //     alignSelf: "center",
        //     textDecoration: "none",
        //     color: 'inherit',
        //     border: 'none',
        //     outline: 'none',
        //     padding: '12px',
        //     backgroundColor: `transparent`
        // }
        bannerAvaya: {
            display: "flex",
            flexDirection: `${screenSize > 800 ? ("row") : ("column")}`,
            margin: `${screenSize > 800 ? ("0px") : ("50px 0px 0px")}`,
            alignItems: "center",
            justifyContent: 'center',
            backgroundColor: '#DA291C'
        },
        columnTextAvaya: {
            display: "flex",
            justifyContent: 'center',
            margin: `${screenSize > 800 ? ("0px 40px") : ("60px 20px 0px")}`,
            padding: `${screenSize > 800 ? ("70px 0px") : ("0px")}`,
        },
        textAvaya: {
            fontFamily: 'sans-serif',
            fontWeight: 400,
            fontSize: '22px',
            color: '#fff',
            lineHeight: '1.5',
            textAlign: 'center',
            width: '100%',
            margin: 0
        },
        icons: {
            // marginRight: "15px",
            width: `${screenSize > 800 ? ("25px") : ("20px")}`,
            height: `${screenSize > 800 ? ("25px") : ("20px")}`,
            color: "#4dc2f1",
        },
        link: {
            // marginRight: "15px",
            padding: `${screenSize > 800 ? (<></>) : ("12px")}`,
        },
    };
    return (
        <>
            {closeNavbar ? (<>
                <div style={styles.navbarOpen}>
                    <div style={styles.buttonsNavbarLeft}>


                        {origin == "home" ? (
                            <>
                                {screenSize > 800 ? (<button style={styles.buttonNavbarPlain} onClick={(e) => setCloseNavbar(!closeNavbar)}>
                                    {language === true ?
                                        <h3 style={styles.textButtonPlain}>Cloud de soluciones</h3> :
                                        <h3 style={styles.textButtonPlain}>Cloud solutions</h3>}
                                    <div style={styles.line} />
                                </button>) : (<></>)}
                            </>
                        ) : (
                            <>
                                {screenSize > 800 ? (<Link style={styles.buttonNavbarPlain} to="/">
                                    {language === true ?
                                        <h3 style={styles.textButtonPlain}>Cloud de soluciones</h3> :
                                        <h3 style={styles.textButtonPlain}>Cloud solutions</h3>}
                                    <div style={styles.line} />
                                </Link>) : (<></>)}
                            </>
                        )}


                        {/* {screenSize > 800 ? (<button style={styles.buttonNavbarPlain} onClick={(e) => handleClick("contact")}>
                        {language === true ?
                            <h3 style={styles.textButtonPlain}>Quiénes somos</h3> :
                            <h3 style={styles.textButtonPlain}>About us</h3>}
                        <div style={styles.line} />
                    </button>) : (<></>)} */}
                        {/* {screenSize > 800 ? (<button style={styles.buttonNavbarPlain} onClick={() => window.open("https://insideone.zendesk.com/hc/es", "_blank")}>
                            {language === true ?
                                <h3 style={styles.textButtonPlain}>Novedades</h3> :
                                <h3 style={styles.textButtonPlain}>News</h3>}
                            <div style={styles.line} />
                        </button>) : (<></>)} */}
                        {screenSize > 800 ? (<Link style={styles.buttonNavbarPlain} to="/news">
                            {language === true ? <h3 style={styles.textButtonPlain}>Novedades</h3> : <h3 style={styles.textButtonPlain}>News</h3>}
                            <div style={styles.line} />
                        </Link>) : (<></>)}


                    </div>

                    {origin == "home" ? (
                        <button style={styles.logoContainer} onClick={(e) => handleClick("home")}>
                            <img
                                src="https://i.postimg.cc/R0RvH3TJ/insideone-isologo.png"
                                height={screenSize > 800 ? ("50px") : ("40px")}
                                alt="Logo of Inside One"

                            />
                        </button>
                    ) : (
                        <Link style={styles.logoContainer} to="/">
                            <img
                                src="https://i.postimg.cc/R0RvH3TJ/insideone-isologo.png"
                                height={screenSize > 800 ? ("50px") : ("40px")}
                                alt="Logo of Inside One"

                            />
                        </Link>
                    )}
                    <div style={styles.buttonsNavbarRight}>
                        {screenSize > 800 ? (<button style={styles.buttonNavbarWhite}
                            // onClick={(e) => handleClick("contact")}
                            // to="/meet"
                            onClick={() => (window.open("https://insideone.typeform.com/to/OPap5DNM"))}
                        >
                            {language === true ?
                                <h3 style={styles.textButtonBlue}>Hablemos</h3> :
                                <h3 style={styles.textButtonBlue}>Let´s talk</h3>}
                        </button>) : (<></>)}
                        {screenSize > 800 ? (<button style={styles.buttonNavbarBlue}
                            // onClick={(e) => handleClick("contact")}
                            // to="/meet"
                            onClick={() => (window.open("https://insideone.typeform.com/to/OPap5DNM"))}
                        >
                            {language === true ?
                                <h3 style={styles.textButtonWhite}>Demo</h3> :
                                <h3 style={styles.textButtonWhite}>Demo free</h3>}
                        </button>) : (<></>)}
                        <button style={styles.buttonLanguage} onClick={(e) => handleLanguage()}>
                            {language === true ? <h3 style={styles.textButtonLanguageHeader}>ENG</h3> : <h3 style={styles.textButtonLanguageHeader}>ESP</h3>}
                        </button>
                    </div>
                </div>
                <div style={styles.navbarMenu}>
                    <div style={styles.navbarMenuGroup}>
                        <h3 style={styles.textMenuSubtitle}>{language === true ? ("Te ayudaremos") : ("We help you")}</h3>
                        <button style={styles.textMenu} onClick={() => handleButton("bot")}>{language === true ? ("Brindar respuestas en el momento") : ("Provide answers at all times")}</button>
                        <button style={styles.textMenu} onClick={() => handleButton("contact-center")}>{language === true ? ("Mejorar la atención de tus clientes") : ("Improve customer service")}</button>
                        <button style={styles.textMenu} onClick={() => handleButton("omnichannel")}>{language === true ? ("Estar presentes en todos los canales") : ("Be present on all channels")}</button>
                        <button style={styles.textMenu} onClick={() => handleButton("contact-center")}>{language === true ? ("Tomar conocimiento de tus conversiones") : ("Learn about your conversions")}</button>
                        <button style={styles.textMenu} onClick={() => handleButton("integrations")}>{language === true ? ("Integrar todas las soluciones") : ("Integrate all solutions")}</button>
                        <div style={styles.lineMenu} />
                    </div>

                    <div style={styles.navbarMenuGroup}>
                        <button style={styles.textMenu} onClick={() => handleButton("contact-center")}>{language === true ? ("Contact Center") : ("Contact Center")}</button>
                        <button style={styles.textMenu} onClick={() => handleButton("bot")}>{language === true ? ("Agent Bot") : ("Agent Bot")}</button>
                        <button style={styles.textMenu} onClick={() => handleButton("bot")}>{language === true ? ("Lenaguaje Natural AI") : ("Natural Language AI")}</button>
                        <button style={styles.textMenu} onClick={() => handleButton("omnichannel")}>{language === true ? ("Omnicanalidad") : ("Omnichannel")}</button>
                        <div style={styles.lineMenu} />
                    </div>




                </div>
            </>) : (<>
                <div style={styles.navbarClose}>
                    <div style={styles.buttonsNavbarLeft}>
                        {origin == "home" ? (
                            <>
                                {screenSize > 800 ? (<button style={styles.buttonNavbarPlain} onClick={(e) => setCloseNavbar(!closeNavbar)}>
                                    {language === true ?
                                        <h3 style={styles.textButtonPlain}>Cloud de soluciones</h3> :
                                        <h3 style={styles.textButtonPlain}>Cloud solutions</h3>}
                                    <div style={styles.line} />
                                </button>) : (<></>)}
                            </>
                        ) : (
                            <>
                                {screenSize > 800 ? (<Link style={styles.buttonNavbarPlain} to="/">
                                    {language === true ?
                                        <h3 style={styles.textButtonPlain}>Cloud de soluciones</h3> :
                                        <h3 style={styles.textButtonPlain}>Cloud solutions</h3>}
                                    <div style={styles.line} />
                                </Link>) : (<></>)}
                            </>
                        )}
                        {/* {screenSize > 800 ? (<button style={styles.buttonNavbarPlain} onClick={(e) => handleClick("contact")}>
                        {language === true ?
                            <h3 style={styles.textButtonPlain}>Quiénes somos</h3> :
                            <h3 style={styles.textButtonPlain}>About us</h3>}
                        <div style={styles.line} />
                    </button>) : (<></>)} */}
                        {/* {screenSize > 800 ? (<button style={styles.buttonNavbarPlain} onClick={() => window.open("https://insideone.zendesk.com/hc/es", "_blank")}>
                            {language === true ?
                                <h3 style={styles.textButtonPlain}>Novedades</h3> :
                                <h3 style={styles.textButtonPlain}>News</h3>}
                            <div style={styles.line} />
                        </button>) : (<></>)} */}
                        {screenSize > 800 ? (<Link style={styles.buttonNavbarPlain} to="/news">
                            {language === true ? <h3 style={styles.textButtonPlain}>Novedades</h3> : <h3 style={styles.textButtonPlain}>News</h3>}
                            <div style={styles.line} />
                        </Link>) : (<></>)}
                    </div>

                    {origin == "home" ? (
                        <button style={styles.logoContainer} onClick={(e) => handleClick("home")}>
                            <img
                                src="https://i.postimg.cc/R0RvH3TJ/insideone-isologo.png"
                                height={screenSize > 800 ? ("50px") : ("40px")}
                                alt="Logo of Inside One"

                            />
                        </button>
                    ) : (
                        <Link style={styles.logoContainer} to="/">
                            <img
                                src="https://i.postimg.cc/R0RvH3TJ/insideone-isologo.png"
                                height={screenSize > 800 ? ("50px") : ("40px")}
                                alt="Logo of Inside One"

                            />
                        </Link>
                    )}



                    <div style={styles.buttonsNavbarRight}>
                        <>
                            {origin == "home" ? (
                                <>
                                    {screenSize > 800 ? (<button style={styles.buttonNavbarWhite}
                                        // onClick={(e) => handleClick("contact")}
                                        // to="/meet"
                                        onClick={() => (window.open("https://insideone.typeform.com/to/OPap5DNM"))}
                                    >
                                        {language === true ?
                                            <h3 style={styles.textButtonBlue}>Hablemos</h3> :
                                            <h3 style={styles.textButtonBlue}>Let´s talk</h3>}
                                    </button>) : (<></>)}
                                    {screenSize > 800 ? (<button style={styles.buttonNavbarBlue}
                                        // onClick={(e) => handleClick("contact")}
                                        // to="/meet"
                                        onClick={() => (window.open("https://insideone.typeform.com/to/OPap5DNM"))}
                                    >
                                        {language === true ?
                                            <h3 style={styles.textButtonWhite}>Demo</h3> :
                                            <h3 style={styles.textButtonWhite}>Demo free</h3>}
                                    </button>) : (<></>)}
                                </>
                            ) : (
                                <></>
                            )}
                        </>
                        <>
                            {origin != "contact" ? (
                                <button style={styles.buttonLanguage} onClick={(e) => handleLanguage()}>
                                    {language === true ? <h3 style={styles.textButtonLanguageHeader}>ENG</h3> : <h3 style={styles.textButtonLanguageHeader}>ESP</h3>}
                                </button>
                            ) : (
                                <>


                                    <Link className="nav-link active" style={styles.link}
                                        onClick={() => (window.open("https://www.linkedin.com/company/insideone?originalSubdomain=ar"))}
                                    >
                                        <svg style={styles.icons} fill="currentColor" class="bi bi-linkedin" viewBox="0 0 16 16">
                                            <path d="M0 1.146C0 .513.526 0 1.175 0h13.65C15.474 0 16 .513 16 1.146v13.708c0 .633-.526 1.146-1.175 1.146H1.175C.526 16 0 15.487 0 14.854zm4.943 12.248V6.169H2.542v7.225zm-1.2-8.212c.837 0 1.358-.554 1.358-1.248-.015-.709-.52-1.248-1.342-1.248S2.4 3.226 2.4 3.934c0 .694.521 1.248 1.327 1.248zm4.908 8.212V9.359c0-.216.016-.432.08-.586.173-.431.568-.878 1.232-.878.869 0 1.216.662 1.216 1.634v3.865h2.401V9.25c0-2.22-1.184-3.252-2.764-3.252-1.274 0-1.845.7-2.165 1.193v.025h-.016l.016-.025V6.169h-2.4c.03.678 0 7.225 0 7.225z" />
                                        </svg>
                                    </Link>
                                    <Link className="nav-link active" style={styles.link}
                                        onClick={() => (window.open("https://www.youtube.com/channel/UCX9OOCzPEGMotCzVARMYhzA"))}
                                    >
                                        <svg style={styles.icons} fill="currentColor" class="bi bi-youtube" viewBox="0 0 16 16">
                                            <path d="M8.051 1.999h.089c.822.003 4.987.033 6.11.335a2.01 2.01 0 0 1 1.415 1.42c.101.38.172.883.22 1.402l.01.104.022.26.008.104c.065.914.073 1.77.074 1.957v.075c-.001.194-.01 1.108-.082 2.06l-.008.105-.009.104c-.05.572-.124 1.14-.235 1.558a2.01 2.01 0 0 1-1.415 1.42c-1.16.312-5.569.334-6.18.335h-.142c-.309 0-1.587-.006-2.927-.052l-.17-.006-.087-.004-.171-.007-.171-.007c-1.11-.049-2.167-.128-2.654-.26a2.01 2.01 0 0 1-1.415-1.419c-.111-.417-.185-.986-.235-1.558L.09 9.82l-.008-.104A31 31 0 0 1 0 7.68v-.123c.002-.215.01-.958.064-1.778l.007-.103.003-.052.008-.104.022-.26.01-.104c.048-.519.119-1.023.22-1.402a2.01 2.01 0 0 1 1.415-1.42c.487-.13 1.544-.21 2.654-.26l.17-.007.172-.006.086-.003.171-.007A100 100 0 0 1 7.858 2zM6.4 5.209v4.818l4.157-2.408z" />
                                        </svg>
                                    </Link>


                                </>
                            )}
                        </>

                    </div>
                </div>
            </>)}
        </>
    );
};
