import React, { useState, useEffect } from 'react'
import axios from 'axios'
import { send } from 'emailjs-com';
import Solutions from "../components/Solutions";

//const hubspot = require('@hubspot/api-client')
//const hubspotClient = new hubspot.Client({ apiKey: "339e1520-71a0-42c3-8f02-6ca7bd4efefc" })

function SolutionsContainer() {

    const [darkMode, setDarkMode] = useState(false);
    const [languageSpanish, setLanguageSpanish] = useState(true);
    const [page, setPage] = useState('Arg');
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [numberPhone, setNumberPhone] = useState('');
    const [mail, setMail] = useState('');
    const [message, setMessage] = useState('');
    const [messageSent, setMessageSent] = useState(false);
    const [solutionBanner, setSolutionBanner] = useState(1);

    const [solutions, setSolutions] = useState([
        // { tipSpanish: "Agent Bot", tipEnglish: "Agent Bot", title: "Brinda respuestas en el momento", subtitleSpanish: "Solución automática con IA para el Servicio al Cliente en canales digitales.", subtitleEnglish: "Automatic solution with AI for Customer Service in digital channels.", url: "/agent-bot", urlImage: "https://i.postimg.cc/WpmGyPV6/banner-bot.png", urlImageEnglish: "https://i.postimg.cc/13Hn0Dkp/banner-bot-english.png" },
        { tipSpanish: "Agent Bot", tipEnglish: "Agent Bot", title: "Brinda respuestas en el momento", subtitleSpanish: "Solución automática con IA para el Servicio al Cliente en canales digitales.", subtitleEnglish: "Automatic solution with AI for Customer Service in digital channels.", url: "/agent-bot", urlImage: "https://i.postimg.cc/mkmwTCtk/case-inisdeone-dia.png", urlImageEnglish: "https://i.postimg.cc/mkmwTCtk/case-inisdeone-dia.png" },
        { tipSpanish: "Omnicanalidad", tipEnglish: "Omnichannel", title: "Está presente en todos los canales", subtitleSpanish: "Ofrece atención personalizada a través de los canales más elegidos.", subtitleEnglish: "Offer personalized attention through the most chosen channels.", url: "/omnichannel", urlImage: "https://i.postimg.cc/MprR26XN/banner-omnichannel.png", urlImageEnglish: "https://i.postimg.cc/MprR26XN/banner-omnichannel.png" },
        // { tipSpanish: "Contact Center", tipEnglish: "Contact Center", title: "Experiencias que tus clientes van a amar", subtitleSpanish: "Solución omnicanal para agentes con Inteligencia Artificial.", subtitleEnglish: "Omnichannel solution for agents with Artificial Intelligence.", url: "/contact-center", urlImage: "/agent-bot" },
        { tipSpanish: "Contact Center", tipEnglish: "Contact Center", title: "Experiencias que tus clientes van a amar", subtitleSpanish: "Solución omnicanal en la nube para agentes, utilizada por cientos de empresas en el mundo.", subtitleEnglish: "Omnichannel cloud solution for agents, used by hundreds of companies around the world.", url: "/contact-center", urlImage: "https://i.postimg.cc/kGRknXy4/banner-contactcenter.png", urlImageEnglish: "https://i.postimg.cc/kGRknXy4/banner-contactcenter.png" },
        { tipSpanish: "Integraciones", tipEnglish: "Integrations", title: "Integra todas las soluciones", subtitleSpanish: "Conectamos todos tus servicios para que ofrezcas un servicio completo.", subtitleEnglish: "We connect all your services so that you offer a complete service.", url: "/integrations", urlImage: "https://i.postimg.cc/bNbKWrJV/banner-development.png", urlImage: "https://i.postimg.cc/bNbKWrJV/banner-development.png", urlImageEnglish: "https://i.postimg.cc/6qnT8ZV4/banner-development-english.png" },
    ]);

    const getData = async () => {
        const res = await axios.get('https://geolocation-db.com/json/')
        if (res.data.country_code === "UK") {
            setLanguageSpanish(false)
            setPage('Spain')
        }
        if (res.data.country_code === "ES" || res.data.country_code === "SP") {
            setPage('Spain')
        }
        //country_name
        //city
    }

    useEffect(() => {
        getData()
        window.scrollTo(0, 0);
    }, [])





    // useEffect(() => {
    //     const timer = setTimeout(() => {
    //         if (solutionBanner != 4) {
    //             setSolutionBanner(solutionBanner + 1)
    //         } else {
    //             setSolutionBanner(1)
    //         }
    //     }, 5000);
    //     return () => clearTimeout(timer);
    // }, [solutionBanner]);






    const handleLanguage = () => {
        // setLanguageSpanish(!languageSpanish)
    }

    const handleDarkMode = () => {
        setDarkMode(!darkMode)
    }

    // handleResize = (e) => {
    //     this.setState({ windowWidth: window.innerWidth });
    // };
    // componentDidMount() {
    //     window.addEventListener("resize", this.handleResize);
    // }
    // componentWillUnmount() {
    //     window.addEventListener("resize", this.handleResize);
    // }

    const handleClick = (place) => {
        if (place === "contact") {
            document.getElementById("contact").scrollIntoView({ behavior: 'smooth', block: 'center' })
        } else if (place === "firstSlide") {
            document.getElementById("firstSlide").scrollIntoView({ behavior: 'smooth', block: 'center' })
        }



        else if (place === "solutions-bot") {
            setSolutionBanner(1)
            document.getElementById("solutions").scrollIntoView({ behavior: 'smooth', block: 'end' })
        } else if (place === "solutions-omnichannel") {
            setSolutionBanner(2)
            document.getElementById("solutions").scrollIntoView({ behavior: 'smooth', block: 'end' })
        }
        else if (place === "solutions-contactcenter") {
            setSolutionBanner(3)
            document.getElementById("solutions").scrollIntoView({ behavior: 'smooth', block: 'end' })
        }
        else if (place === "solutions-integrations") {
            setSolutionBanner(4)
            document.getElementById("solutions").scrollIntoView({ behavior: 'smooth', block: 'end' })
        }




        else if (place === "home") {
            document.getElementById("home").scrollIntoView({ behavior: 'smooth', block: 'center' })
        }
        // window.scrollTo({
        //     top: document.documentElement.scrollHeight,
        //     behavior: 'smooth'
        //     /* you can also use 'auto' behaviour 
        //        in place of 'smooth' */
        // });
        //  window.scrollTo(0, 5000);
    }

    const handleChange = (e, stateToChange) => {
        if (stateToChange === "firstName") {
            setFirstName(e.target.value)
        }
        if (stateToChange === "lastName") {
            setLastName(e.target.value)
        }
        if (stateToChange === "numberPhone") {
            setNumberPhone(e.target.value)
        }
        if (stateToChange === "mail") {
            setMail(e.target.value)
        }
        if (stateToChange === "message") {
            setMessage(e.target.value)
        }
    }

    const handleSubmit = (e) => {
        e.preventDefault();
        let messageToSend = {
            firstName, lastName, numberPhone, mail, message
        }
        // message: `Nombre: ${firstName} ${lastName}; Número de teléfono: ${numberPhone}; Mail: ${mail}; Mensaje: ${message}`,
        let toSend = {
            from_name: `${firstName} ${lastName}`,
            to_name: 'InsideOne',
            message: messageToSend,
            reply_to: mail,
        }

        send(
            'service_ls9niig',
            'template_b2koppp',
            toSend,
            'user_JDbq5qjcuQDjNJHhJJfpf'
        )
            .then((response) => {
                //  console.log('SUCCESS!', response.status, response.text);
            })
            .catch((err) => {
                //   console.log('FAILED...', err);
            });

        setFirstName("")
        setLastName("")
        setNumberPhone("")
        setMail("")
        setMessage("")
        setMessageSent(true)
    }

    return (
        <Solutions
            screenSize={windowWidth}
            language={languageSpanish}
            darkModeState={darkMode}
            firstName={firstName}
            lastName={lastName}
            numberPhone={numberPhone}
            mail={mail}
            message={message}
            handleLanguage={handleLanguage}
            handleDarkMode={handleDarkMode}
            handleClick={handleClick}
            handleChange={handleChange}
            handleSubmit={handleSubmit}
            messageSent={messageSent}
            page={page}
            setSolutionBanner={setSolutionBanner}
            solutionBanner={solutionBanner}
            solutions={solutions}
        />
    );
}

export default SolutionsContainer;