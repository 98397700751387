import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { useSelector } from "react-redux";
import Email from "../../components/create/Email";
import Sidebar from "../Sidebar";
import firebase from '../../config/firebaseIO'
import axios from "axios";

const db = firebase.firestore()


const sendData = (emails, subject, title, description, template) => {
    return new Promise((resolve, reject) => {
        const params = {
            emails: emails,
            subjectText: subject,
            titleText: title,
            messageText: description,
            token: "3kn786lknBhkNkjnk434jnJN",
            type: template
        }
        axios.post(`https://us-central1-insideone-newcontact.cloudfunctions.net/SEND_MAILS`, params)
            //axios.post(`http://localhost:5000/connected-app-church/us-central1/SEND_MAILS`, params)
            .then(data => {
                //      console.log('respuesta:', data)
                return data
            }).catch(e => {
                //      console.log('error:', e)
                return e
            })
    })
}


const CreateEmail = () => {

    const history = useHistory();

    const [screenSize, setScreenSize] = useState(window.innerWidth);
    const [emails, setEmails] = useState('');
    const [template, setTemplate] = useState('');
    const [subject, setSubject] = useState('');
    const [title, setTitle] = useState('');
    const [description, setDescription] = useState('');
    const [emailGroups, setEmailGroups] = useState(
        [
            { groupName: "InsideOne Prueba", mails: ["alexk@insideone.com.ar"] },
            // { groupName: "InsideOne Prueba", mails: ["alexk@insideone.com.ar", "pcurutchet@insideone.com.ar", "leonardok@insideone.com.ar"] },
            // { groupName: "Clientes Collab", mails: ["Alejandro.Touson@aon.com", "leonardo.caviglia@aon.com", "Diego.Damian.Fernandez@aon.com", "felipe.abreu@aon.com", "melanie.litterio@bbva.com", "jonathan.bielous@bbva.com", "gcomatto@insideone.com.ar", "marcela.gutierrez@diagroup.com", "paula.garcia@diagroup.com", "federico.kraft@diagroup.com", "matias.costanzo@diagroup.com", "daugugliaro@galiciaseguros.com.ar", "gnegri@galiciaseguros.com.ar", "erosalez@galiciaseguros.com.ar", "fhortiguela@galiciaseguros.com.ar", "dschmisser@galiciaseguros.com.ar","alexk@insideone.com.ar", "pcurutchet@insideone.com.ar", "leonardok@insideone.com.ar"] },
            // { groupName: "AON", mails: ["Alejandro.Touson@aon.com", "leonardo.caviglia@aon.com", "Diego.Damian.Fernandez@aon.com", "felipe.abreu@aon.com","alexk@insideone.com.ar", "pcurutchet@insideone.com.ar", "leonardok@insideone.com.ar"] },
            // { groupName: "BBVA Seguros", mails: ["melanie.litterio@bbva.com", "jonathan.bielous@bbva.com", "gcomatto@insideone.com.ar","alexk@insideone.com.ar", "pcurutchet@insideone.com.ar", "leonardok@insideone.com.ar"] },
            // { groupName: "Día", mails: ["marcela.gutierrez@diagroup.com", "paula.garcia@diagroup.com", "federico.kraft@diagroup.com", "matias.costanzo@diagroup.com","alexk@insideone.com.ar", "pcurutchet@insideone.com.ar", "leonardok@insideone.com.ar"] },
            // { groupName: "Galicia Seguros", mails: ["daugugliaro@galiciaseguros.com.ar", "gnegri@galiciaseguros.com.ar", "erosalez@galiciaseguros.com.ar", "fhortiguela@galiciaseguros.com.ar", "dschmisser@galiciaseguros.com.ar","alexk@insideone.com.ar", "pcurutchet@insideone.com.ar", "leonardok@insideone.com.ar"] },
            // { groupName: "IKE", mails: ["harancibia@ikeasistencia.com.ar", "ssoria@ikeasistencia.com.ar","alexk@insideone.com.ar", "pcurutchet@insideone.com.ar", "leonardok@insideone.com.ar"] },
            // { groupName: "Socios Claro", mails: ["carla.simao@claro.com.ar", "pablo.travella@claro.com.ar","alexk@insideone.com.ar", "pcurutchet@insideone.com.ar", "leonardok@insideone.com.ar"] },
        ]
    );
    const [templatesGroup, setTemplatesGroup] = useState(
        [
            { templateName: "Alerta de caída", template: "d-85ffa0d830124682acdbf76cd7ec6c8f" },
            { templateName: "Ventana de mantenimiento", template: "d-85ffa0d830124682acdbf76cd7ec6c8f" },
            { templateName: "Tarea programada", template: "d-85ffa0d830124682acdbf76cd7ec6c8f" },
            { templateName: "Novedades", template: "d-85ffa0d830124682acdbf76cd7ec6c8f" },
        ]
    );

    const language = useSelector(state => state.user.language)
    const loginUser = useSelector(state => state.user.loginUser)
    const [darkMode, setDarkMode] = useState(false);
    const [loader, setLoader] = useState(false);

    const handleChange = (e, state) => {
        // if (state === 'emails') {
        //     setEmails(e.target.value);
        // }
        if (state === 'emails') {
            // console.log("como viene:", e.target.value)
            setEmails(e.target.value);
        }
        if (state === 'template') {
            // console.log("como viene:", e.target.value)
            setTemplate(e.target.value);
        }
        if (state === 'subject') {
            setSubject(e.target.value);
        }
        if (state === 'title') {
            setTitle(e.target.value);
        }
        if (state === 'description') {
            setDescription(e.target.value);
        }
    };

    const handleSubmit = (e) => {
        if (e) { e.preventDefault() }
        // if (emails != "" && subject != "" && title != "" && description != "") {
        if (emails != "") {
            setLoader(true)
            db.collection('sent-emails').add({
                emails: emails,
                subject: subject,
                title: title,
                description: description,
                person: `${loginUser.firstName} ${loginUser.lastName}`,
                template: template
            }).then(() => {
                let found = emailGroups.find(element => element.groupName == emails);
                if (found) {
                    let emailsToSend = found.mails
                    let templateToSend = ""
                    if (template == "Alerta de caída") { templateToSend = "support-problem" }
                    // console.log('email>', emailsToSend)
                    // console.log('template>', templateToSend)
                    sendData(emailsToSend, subject, title, description, template)
                        .then(response => {
                            console.log("Mail enviado!")
                        }).catch(e => {
                            console.error(`Error: ${e}`);
                        });
                    history.push(`/today`);
                }
            })
        }
    };





    // sendMail() {
    //     if (this.state.titleText !== '' && this.state.messageText !== '') {
    //         if (this.props.rol !== 'developer' && this.props.rol !== 'mails') {
    //             Swal.fire({
    //                 title: 'No fue posible!',
    //                 text: 'No tienes los permisos necesarios.',
    //                 icon: 'warning',
    //                 confirmButtonColor: '#ff2068',
    //                 confirmButtonText: 'Continuar',
    //                 showCloseButton: 'true',
    //             })
    //         } else {
    //             Swal.fire({
    //                 title: '¿Estás seguro?',
    //                 showCancelButton: 'true',
    //                 showCloseButton: 'true',
    //                 confirmButtonText: 'Enviar',
    //                 cancelButtonText: 'Cancelar',
    //                 confirmButtonColor: '#ff2068',
    //                 reverseButtons: true,
    //             }).then((result) => {
    //                 if (result.value) {
    //                     sendData(this.state.titleText, this.state.messageText, this.state.subjectText)
    //                         .then(response => {

    //                         }).catch(e => {
    //                             console.error(`Error: ${e}`);
    //                         });
    //                     Swal.fire({
    //                         title: 'Enviado!',
    //                         text: 'El email fue enviado correctamente.',
    //                         icon: 'success',
    //                         confirmButtonColor: '#ff2068',
    //                         confirmButtonText: 'Continuar',
    //                         showCloseButton: 'true',
    //                     })
    //                     this.setState({ titleText: '', messageText: '', subjectText: '' })
    //                 }
    //             })
    //         }
    //     } else {
    //         Swal.fire({
    //             title: 'Faltan datos',
    //             text: 'Complete todos los datos necesarios.',
    //             icon: 'warning',
    //             confirmButtonColor: '#339AE7',
    //             confirmButtonText: 'Continuar',
    //             showCloseButton: 'true',
    //         })
    //     }
    // }





    return (
        <div>
            <Sidebar />
            <Email
                screenSize={screenSize}
                darkModeState={darkMode}
                language={language}
                handleChange={handleChange}
                handleSubmit={handleSubmit}
                emails={emails}
                subject={subject}
                title={title}
                description={description}
                loader={loader}
                emailGroups={emailGroups}
                templatesGroup={templatesGroup}
                template={template}
            />
        </div>
    );
}

export default CreateEmail;