import React from "react";
import { Link } from "react-router-dom";
import { InlineWidget } from "react-calendly";
import Navbar from "../components/Navbar";
import PopUpWelcome from "./PopUpWelcome";
import Confetti from 'react-confetti'

let year = new Date().getFullYear()

export default ({ screenSize, handleClick, handleDarkMode, darkModeState, handleLanguage, handleChange, handleSubmit, language, page, firstName, lastName, numberPhone, mail, message, messageSent, setSolutionBanner, solutionBanner, solutions, nameClientFormated, handlePopUpWelcome, popUpWelcomeState }) => {
    const styles = {
        container: {
            //    position: "fixed",
            //   bottom: 0,
            //  top: 0,
            // left: 0,
            //  right: 0,
            display: "flex",
            flexDirection: "column",
            backgroundColor: `${darkModeState ? ("hsl(230, 17%, 14%)") : ("hsl(0, 0%, 100%)")}`,
            //   padding: `${screenSize > 800 ? ("3% 2% 3% 20%") : ("30px")}`,
            //   padding: `${screenSize > 800 ? ("3% 2% 3% 280px") : ("30px")}`,
            //    height: "100%",
            overflow: `auto`,
        },
        navbar: {
            display: "flex",
            height: "60px",
            alignItems: 'center',
            justifyContent: 'space-between',
            margin: `${screenSize > 800 ? ("0px 9vw") : ("0px")}`,
            padding: '10px 0px',
            // width: '100%'
            //    flex: 1.5,
            //   marginBottom: `${screenSize > 800 ? ("0px") : ("30px")}`,
        },
        navbarWhite: {
            // display: "flex",
            // alignItems: 'center',
            // justifyContent: 'space-between',
            // height: "110px",
            // width: '100%',
            // padding: `${screenSize > 800 ? ("20px 60px") : ("20px")}`,
            // margin: "0px",

            //    flex: 1.5,
            //   marginBottom: `${screenSize > 800 ? ("0px") : ("30px")}`,

            position: "fixed",
            top: 0,
            right: 0,
            bottom: `${screenSize > 800 ? (<></>) : ("0")} `,
            left: 0,
            display: "flex",
            flexDirection: 'row',
            height: "100px",
            alignItems: 'center',
            justifyContent: 'space-between',
            padding: `${screenSize > 800 ? ("0px 60px") : ("20px")}`,
            margin: "0px",
            zIndex: 200,
            backgroundColor: 'white',
            //     borderBottom: '1px solid rgba(0, 0, 0, .2)',
        },
        logoContainer: {
            display: "flex",
            flex: '1',
            alignItems: "center",
            justifyContent: 'center',
            border: 'none',
            padding: 0,
            outline: 0,
            backgroundColor: 'transparent',
            //    backgroundColor: 'red'
            //   padding: "20px 0px"
        },
        titleNavbar: {
            //  marginBottom: `${screenSize > 800 ? ('5px') : ("10px")}`,
            margin: 0,
            fontSize: "28px",
            color: `${darkModeState ? ("hsl(0, 0%, 100%)") : ("hsl(230, 17%, 14%)")}`,
            fontFamily: "InterBold",
            lineHeight: '1.4'
        },
        buttonsNavbarLeft: {
            display: 'flex',
            flex: 2,
            flexDirection: 'row',
            alignItems: 'flex-end',
            justifyContent: 'flex-start'
        },
        buttonsNavbarRight: {
            display: 'flex',
            flex: 2,
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'flex-end'
        },
        buttonNavbarPlain: {
            backgroundColor: 'transparent',
            color: '#4dc2f1',
            borderRadius: 25,
            border: 'none',
            padding: 0,
            outline: 0,
            margin: "0px 15px 0px 0px",
            display: 'flex',
            flexDirection: 'row',
            // alignItem: 'center',
            // justifyContent: 'center'
        },
        textButtonPlain: {
            margin: 0,
            fontSize: 16,
            color: '#4dc2f1',
            margin: "0px 15px 0px 0px",
            fontFamily: "UbuntuMedium",
            //  textAlign: 'start'
        },
        line: {
            display: 'flex',
            width: "30px",
            height: "2px",
            alignSelf: 'flex-end',
            marginBottom: "3px",
            backgroundColor: '#4dc2f1'
        },
        buttonNavbarWhite: {
            backgroundColor: 'transparent',
            color: '#4dc2f1',
            borderRadius: 25,
            border: '2px solid #4dc2f1',
            padding: '3px 18px',
            height: '45px',
            width: '130px',
            outline: 0,
            margin: "0px 5px"
        },
        buttonNavbarBlue: {
            backgroundColor: '#4DC2F1',
            borderRadius: 25,
            border: 'none',
            padding: '3px 18px',
            height: '45px',
            width: '130px',
            outline: 0,
            margin: "0px 10px"
        },
        buttonLanguage: {
            backgroundColor: 'transparent',
            border: 'none',
            padding: '3px 18px',
            height: '35px',
            width: '70px',
            outline: 0
        },
        textButtonBlue: {
            margin: 0,
            fontSize: "14px",
            color: '#4dc2f1',
            fontFamily: "UbuntuMedium",
        },
        textButtonWhite: {
            margin: 0,
            fontSize: "14px",
            color: '#fff',
            fontFamily: "UbuntuMedium",
        },
        textThanks: {
            margin: 0,
            fontSize: "16px",
            color: '#fff',
            alignSelf: 'centerr'
        },
        textButtonLanguage: {
            margin: 0,
            fontSize: "14px",
            color: '#333c4e',
        },
        textButtonLanguageHeader: {
            margin: 0,
            fontSize: "14px",
            color: '#4dc2f1',
            fontFamily: "UbuntuMedium",
            //  color: '#000',
        },
        bannerHeader: {
            display: 'flex',
            flexDirection: `${screenSize > 800 ? ('row') : ('column')}`,
            width: '100vw',
            padding: `${screenSize > 800 ? ('0px 7vw 0px 9vw') : ('0px')}`,
            //      backgroundColor: 'red',
        },
        textsHeader: {
            display: 'flex',
            flex: 1,
            alignItems: 'flex-start',
            justifyContent: 'center',
            flexDirection: 'column',
            textAlign: 'start',
        },
        titleHeader: {
            fontSize: `${screenSize > 800 ? ('48px') : ('25px')}`,
            //   margin: `${screenSize > 800 ? ('0px 8vw 40px') : ('20px 15vw 20px')}`,
            margin: `${screenSize > 800 ? ('0px 1vw 40px 2vw') : ('20px 15vw 20px')}`,
            fontFamily: "UbuntuMedium",
            color: '#000',
            textAlign: `${screenSize > 800 ? (<></>) : ('center')}`,
        },
        subtitleHeader: {
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'center',
            //   margin: `${screenSize > 800 ? ('0px 8vw 40px') : ('20px 15vw 20px')}`,
            margin: `${screenSize > 800 ? ('0px 1vw 40px 2vw') : ('20px 15vw 20px')}`,
            backgroundColor: 'transparent',
            border: 'none',
            outline: 0,
        },
        subtitleHeaderText: {
            color: '#D0B5F0',
            fontSize: '18px',
            fontFamily: 'UbuntuMedium',
            fontWeight: '600',
            margin: '0px 15px 0px 0px'
            //  padding: '0px 5vw'
            // textAlign: 'center'
        },
        lineHeader: {
            display: 'flex',
            width: "100px",
            height: "2px",
            alignSelf: 'flex-end',
            marginBottom: "3px",
            backgroundColor: '#D0B5F0'
        },
        divButtonHeader: {
            display: 'flex',
            alignItem: 'center',
            justifyContent: 'center',
            height: '10vh'
        },
        buttonHeader: {
            display: 'flex',
            alignItem: 'center',
            justifyContent: 'center',
            backgroundColor: '#4DC2F1',
            borderRadius: 25,
            border: 'none',
            // padding: '3px 18px',
            height: '40px',
            width: '40px',
            color: '#fff',
            outline: 0,
        },



        bannerSolutions: {
            display: "flex",
            flexDirection: 'column',
            margin: `${screenSize > 800 ? ('0px') : ('50px 0px 0px')}`,
            padding: `${screenSize > 800 ? ('100px 0px') : ('50px 0px')}`,
            alignItems: "center",
            justifyContent: 'center',
            //  height: "70vh",
            textAlign: 'center',
            // flexWrap: `${screenSize > 800 ? ('nowrap') : ('wrap')}`,
        },
        titleBannerSolutions: {
            fontSize: `${screenSize > 800 ? ('40px') : ('25px')}`,
            //   margin: `${screenSize > 800 ? ('0px 8vw 40px') : ('20px 15vw 20px')}`,
            margin: `${screenSize > 800 ? ('-10px 0px 0px') : ('0px 0px 0px')}`,
            fontFamily: "UbuntuMedium",
            color: '#4dc2f1',
            textAlign: `${screenSize > 800 ? (<></>) : ('center')}`,
        },
        subtitleBannerSolutions: {
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'center',
            //   margin: `${screenSize > 800 ? ('0px 8vw 40px') : ('20px 15vw 20px')}`,
            margin: '50px 0px 40px',
            backgroundColor: 'transparent',
            border: 'none',
            outline: 0,
        },
        subtitleBannerSolutionsText: {
            color: '#000',
            fontSize: '18px',
            fontFamily: 'UbuntuMedium',
            fontWeight: '600',
            margin: '0px'
            //  padding: '0px 5vw'
            // textAlign: 'center'
        },
        buttonBannerSolutions: {
            display: 'flex',
            //     flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'center',
            //   margin: `${screenSize > 800 ? ('0px 8vw 40px') : ('20px 15vw 20px')}`,
            margin: '0px 0px 20px',
            backgroundColor: 'transparent',
            border: '1.5px solid #000',
            borderRadius: '50px',
            outline: 0,
            height: '45px',
            width: '180px',
        },
        buttonBannerSolutionsText: {
            // display: 'flex',
            // alignSelf: 'center',
            color: '#000',
            fontSize: '14px',
            fontFamily: 'UbuntuMedium',
            fontWeight: '600',
            margin: '0px',
            //    padding: '13px 20px',
            //    padding: '3px 18px',

            // textAlign: 'center'
        },
        columnSolutions: {
            display: "flex",
            margin: `${screenSize > 800 ? ('0px') : ('30px 0px 10px')}`,
            alignItems: "center",
            justifyContent: 'center',
            flexWrap: `${screenSize > 800 ? ('nowrap') : ('wrap')}`,
        },
        columnsolutionsText: {
            display: "flex",
            flexDirection: 'column',
            alignItems: "flex-start",
            justifyContent: 'center',
            margin: 0,
            //   width: `${screenSize > 800 ? ("50vw") : ("80vw")}`,
            width: `${screenSize > 800 ? ("50vw") : ("100%")}`,
            padding: `${screenSize > 800 ? ("0px 10vw") : ("12vw 12vw 0px")}`,
        },





        columnsolutionsOptions: {
            display: "flex",
            flexDirection: 'row',
            alignItems: "flex-start",
            margin: `${screenSize > 800 ? ('50px 0px 0px') : ('20px 0px 0px')}`,
        },

        columnsolutionsOptionsTextActive: {
            color: '#4dc2f1',
            fontSize: '20px',
            fontFamily: 'UbuntuBold',
            margin: '0px 20px 0px 0px',
            outline: 0,
            backgroundColor: 'transparent',
            border: 'none',
        },
        columnsolutionsOptionsText: {
            color: 'grey',
            fontSize: '20px',
            fontFamily: 'UbuntuBold',
            margin: '0px 20px 0px 0px',
            outline: 0,
            backgroundColor: 'transparent',
            border: 'none',
        },



        columnsolutionsTipText: {
            fontSize: `${screenSize > 800 ? ('14px') : ('12px')}`,
            //   margin: `${screenSize > 800 ? ('0px 8vw 40px') : ('20px 15vw 20px')}`,
            margin: `${screenSize > 800 ? ('0px 0px 30px') : ('0px 0px 30px')}`,
            fontFamily: "UbuntuRegular",
            color: '#fff',
            backgroundColor: "#2D2A32",
            borderRadius: '25px',
            padding: '5px 18px',
            textAlign: `${screenSize > 800 ? (<></>) : ('center')}`,
        },
        columnsolutionsTitleText: {
            fontSize: `${screenSize > 800 ? ('40px') : ('25px')}`,
            //   margin: `${screenSize > 800 ? ('0px 8vw 40px') : ('20px 15vw 20px')}`,
            margin: `${screenSize > 800 ? ('-15px 0px 0px') : ('0px 0px 0px')}`,
            fontFamily: "UbuntuMedium",
            color: '#4dc2f1',
            textAlign: `${screenSize > 800 ? (<></>) : ('center')}`,
        },
        columnsolutionsSubtitleText: {
            color: '#000',
            fontSize: '18px',
            fontFamily: 'UbuntuRegular',
            //   fontWeight: '400',
            margin: '20px 0px 30px',
            padding: `${screenSize > 800 ? ('0px 5vw 0px 0px') : ('0px')}`,
            lineHeight: '30px'
            // textAlign: 'center'
        },













        columnContainer: {
            display: "flex",
            margin: '50px 0px',
            alignItems: "center",
            justifyContent: 'center',
            flexWrap: `${screenSize > 800 ? ('nowrap') : ('wrap')}`,
        },
        columnContainerReverse: {
            display: "flex",
            margin: '50px 0px',
            alignItems: "center",
            justifyContent: 'center',
            flexWrap: `${screenSize > 800 ? ('nowrap') : ('wrap-reverse')}`,
        },
        columnContainerNoBottom: {
            display: "flex",
            margin: '50px 0px 0px',
            alignItems: "center",
            justifyContent: 'center',
            flexWrap: `${screenSize > 800 ? ('nowrap') : ('wrap')}`,
        },
        columnContainerNoTop: {
            display: "flex",
            margin: '0px 0px 50px',
            alignItems: "center",
            justifyContent: 'center',
            flexWrap: `${screenSize > 800 ? ('nowrap') : ('wrap')}`,
        },
        columnContainerNoTopNoBottom: {
            display: "flex",
            margin: `${screenSize > 800 ? ('0px') : ('30px 0px 0px')}`,
            alignItems: "center",
            justifyContent: 'center',
            flexWrap: `${screenSize > 800 ? ('nowrap') : ('wrap')}`,
        },
        columnText: {
            display: "flex",
            flexDirection: 'column',
            alignItems: "flex-start",
            justifyContent: 'center',
            margin: 0,
            //   width: `${screenSize > 800 ? ("50vw") : ("80vw")}`,
            width: `${screenSize > 800 ? ("50vw") : ("100%")}`,
            padding: `${screenSize > 800 ? (0) : ("10px")}`,
        },
        columnTextGrey: {
            display: "flex",
            flexDirection: 'column',
            alignItems: "flex-start",
            justifyContent: 'center',
            margin: 0,
            width: `${screenSize > 800 ? ("50vw") : ("100%")}`,
            padding: `${screenSize > 800 ? (0) : ("10px")}`,
            backgroundColor: "#F5F7FA",
            height: `${screenSize > 800 ? ("480px") : (<></>)} `,
        },
        columnTextPadding: {
            padding: `${screenSize > 800 ? ("0px 80px") : ("20px")}`,
        },
        columnTextContext: {
            fontFamily: 'sans-serif',
            fontWeight: '600',
            fontSize: '16px',
            color: '#a4abae',
            lineHeight: '1.45',
            margin: "0px 0px 15px 0px",

        },
        columnTextTitle: {
            fontFamily: 'sans-serif',
            fontWeight: '600',
            fontSize: '27px',
            color: '#333c4e',
            lineHeight: '1.3',
            margin: "0px 0px 30px 0px",
        },
        columnTextBody: {
            fontFamily: 'sans-serif',
            fontWeight: '400',
            fontSize: '18px',
            color: '#4f5766',
            lineHeight: '1.7',
            margin: "20px 0px 0px",
        },
        titleCaseContainer: {
            display: "flex",
            margin: '50px 50px 0px',
            alignItems: "center",
            justifyContent: 'center',
        },
        titleCase: {
            fontFamily: 'sans-serif',
            fontWeight: '600',
            fontSize: '18px',
            textAlign: 'center',
            color: '#333c4e',
            lineHeight: '1.7',
            margin: 0,
        },
        columnCaseContainer: {
            display: "flex",
            margin: '50px 0px',
            alignItems: "center",
            justifyContent: 'center',
            flexWrap: `${screenSize > 800 ? ('wrap') : ('wrap')}`,
        },
        columnCaseContainerCarrefour: {
            display: "flex",
            margin: '50px 0px 150px',
            alignItems: "center",
            justifyContent: 'center',
            flexWrap: `${screenSize > 800 ? ('wrap') : ('wrap')}`,
        },
        columnCase: {
            display: "flex",
            alignItems: "center",
            justifyContent: 'center',
            width: `${screenSize > 800 ? ("30vw") : ("80vw")}`,
            height: `${screenSize > 800 ? ("345px") : ("230px")}`,
            margin: 0,
            padding: `${screenSize > 800 ? (0) : ("20px")}`,
            backgroundColor: "#005093"
        },
        imageCase: {
            height: `${screenSize > 800 ? ("45px") : ("35px")}`,
        },
        imageCase2: {
            height: `${screenSize > 800 ? ("75px") : ("55px")}`,
        },
        imageCase3: {
            height: `${screenSize > 800 ? ("45px") : ("30px")}`,
        },
        columnCaseText: {
            display: "flex",
            alignItems: "center",
            justifyContent: 'center',
            width: `${screenSize > 800 ? ("30vw") : ("80vw")}`,
            height: `${screenSize > 800 ? ("345px") : (<></>)}`,
            margin: 0,
            backgroundColor: '#F5F7FA'
        },
        columnCaseTextRecordati: {
            display: "flex",
            alignItems: "center",
            justifyContent: 'center',
            width: `${screenSize > 800 ? ("30vw") : ("80vw")}`,
            height: `${screenSize > 800 ? ("445px") : (<></>)}`,
            margin: 0,
            backgroundColor: '#F5F7FA'
        },
        columnCasePadding: {
            padding: `${screenSize > 800 ? ("0px 80px") : ("20px")}`,
            display: "flex",
            flexDirection: 'column',
            alignItems: "center",
            justifyContent: 'center',
        },
        columnCasePaddingRecordati: {
            padding: `${screenSize > 800 ? ("0px 60px") : ("20px")}`,
            display: "flex",
            flexDirection: 'column',
            alignItems: "center",
            justifyContent: 'center',
        },
        columnTextCase: {
            fontFamily: 'sans-serif',
            fontWeight: '400',
            fontSize: '18px',
            textAlign: 'center',
            color: '#4f5766',
            lineHeight: '1.7',
            margin: "30px 0px",
        },
        columnTextCaseNoTop: {
            fontFamily: 'sans-serif',
            fontWeight: '400',
            fontSize: '18px',
            textAlign: 'center',
            color: '#4f5766',
            lineHeight: '1.7',
            margin: `${screenSize > 800 ? ("0px 0px 30px") : ("20px 10px 20px")}`,
        },
        columnTextCaseRecordati: {
            fontFamily: 'sans-serif',
            fontWeight: '400',
            fontSize: '17px',
            textAlign: 'start',
            color: '#4f5766',
            lineHeight: '1.7',
            margin: `${screenSize > 800 ? ("10px 0px") : ("20px 10px 20px")}`,
        },
        columnBoldCase: {
            fontFamily: 'sans-serif',
            fontWeight: '600',
            fontSize: '16px',
            textAlign: 'center',
            color: '#333c4e',
            lineHeight: '1.7',
            margin: 0,
            // padding: 0,
            padding: `${screenSize > 800 ? (0) : ("0px 0px 10px")}`,
        },
        titleClientContainer: {
            display: "flex",
            margin: '50px 50px 0px',
            alignItems: "center",
            justifyContent: 'center',
        },
        titleClient: {
            fontFamily: 'sans-serif',
            fontWeight: '400',
            fontSize: '26px',
            textAlign: 'center',
            color: '#333c4e',
            margin: 0,
            lineHeight: '1.7',
        },
        columnClientContainer: {
            display: "flex",
            margin: `${screenSize > 800 ? ('50px') : ("30px 30px 50px")}`,
            alignItems: "center",
            justifyContent: 'center',
            flexWrap: 'wrap'
        },
        imageClient: {
            height: 70,
            margin: 25
        },
        formContainer: {
            backgroundColor: "#333c4e",
            alignItems: "center",
            justifyContent: 'center',
            padding: `${screenSize > 800 ? ("100px") : ("100px 30px")}`,
            margin: '50px 0px'
        },
        titleForm: {
            fontFamily: 'sans-serif',
            fontWeight: '400',
            fontSize: '28px',
            textAlign: 'center',
            color: '#fff',
            margin: "0px 50px 70px",
            lineHeight: '1.7',
        },
        formCenter: {
            display: 'flex',
            alignItems: "center",
            justifyContent: 'center',
        },
        formInputsContainer: {
            display: "flex",
            // flex: 12,
            width: '440px',
            flexDirection: 'column',
            alignItems: "flex-start",
            justifyContent: 'center',
        },
        labelForm: {
            margin: 0,
            color: '#fff'
        },
        inputForm: {
            borderRadius: '25px',
            height: '40px',
            margin: "8px 0px 16px",
            fontFamily: 'sans-serif',
            fontWeight: '400',
            padding: '0px 16px'
        },
        inputFormMessage: {
            display: "flex",
            alignItems: "flex-start",
            justifyContent: 'flex-start',
            borderRadius: '15px',
            height: '160px',
            margin: "8px 0px 16px",
            fontFamily: 'sans-serif',
            fontWeight: '400',
            padding: '10px 16px',
        },
        buttonForm: {
            display: "flex",
            alignItems: "center",
            justifyContent: 'center',
            backgroundColor: '#4DC2F1',
            borderRadius: 25,
            border: 'none',
            padding: '13px 23px',
            margin: "22px 0px",
            height: '50px',
            outline: 0
        },
        columnAddressContainer: {
            display: "flex",
            // margin: '50px 120px 100px',
            margin: `${screenSize > 800 ? ('50px 120px 100px') : ("50px 30px")}`,
            alignItems: "flex-start",
            justifyContent: 'space-between',
            flexWrap: 'wrap'
        },
        singleAddress: {
            display: "flex",
            flexDirection: 'column',
            alignItems: "flex-start",
            justifyContent: 'flex-start',
            width: "250px",
            //height: 70,
            margin: "0px 0px 25px"
        },
        titleAddress: {
            fontFamily: 'sans-serif',
            fontWeight: '600',
            fontSize: '18px',
            textAlign: 'center',
            color: '#333c4e',
            lineHeight: '1.7',
            textAlign: 'left',
            margin: "0px 0px 15px",
        },
        addressText: {
            fontFamily: 'sans-serif',
            fontWeight: '400',
            fontSize: '18px',
            textAlign: 'center',
            color: '#4f5766',
            lineHeight: '1.7',
            textAlign: 'left',
            margin: "0px 0px 3px",
        },
        phoneText: {
            fontFamily: 'sans-serif',
            fontWeight: '400',
            fontSize: '18px',
            textAlign: 'center',
            color: '#4dc2f1',
            lineHeight: '1.7',
            margin: 0,
            textAlign: 'left',
        },
        footerContainer: {
            display: "flex",
            margin: "10px 0px 30px",
            alignItems: "flex-start",
            justifyContent: 'flex-start',
        },
        textFooter: {
            fontFamily: 'sans-serif',
            fontWeight: '400',
            fontSize: '14px',
            textAlign: `${screenSize > 800 ? ("center") : ("left")} `,
            color: '#4f5766',
            margin: `${screenSize > 800 ? ("0px 120px") : ("0px 30px")} `,
            lineHeight: '1.7',
        },
        svgStyle: {
            height: 16,
            width: 16,
            display: 'flex',
            alignSelf: 'center'
        },
        // toogleNavbar: {
        //     display: "flex",
        //     flex: 4,
        //     alignItems: "center",
        //     justifyContent: `${ screenSize > 800 ? ("flex-end") : ("center") } `,
        //     flexDirection: `${ screenSize > 800 ? ("row") : ("column") } `,
        // },
        // textToogle: {
        //     fontSize: "14px",
        //     fontFamily: "InterBold",
        //     alignSelf: "center",
        //     textDecoration: "none",
        //     color: 'inherit',
        //     border: 'none',
        //     outline: 'none',
        //     padding: '12px',
        //     backgroundColor: `transparent`
        // }


        bannerPartners: {
            display: "flex",
            flexDirection: `${screenSize > 800 ? ("row") : ("column")}`,
            //     flexWrap: `${screenSize > 800 ? ('nowrap') : ('wrap')}`,
            margin: `${screenSize > 800 ? ("0px") : ("50px 0px 0px")}`,
            alignItems: "center",
            justifyContent: 'center',
            backgroundColor: '#2D2A32',
            height: `${screenSize > 800 ? ("250px") : (<></>)}`,
            padding: `${screenSize > 800 ? ("0px 10vw") : ("50px 0px 40px")}`,
            width: "100vw"
        },
        columnTextPartners: {
            display: "flex",
            flex: 1,
            justifyContent: 'center',
            padding: `${screenSize > 800 ? ("0px") : ("0px")}`,
            width: `${screenSize > 800 ? ("100px") : ("80vw")}`,
            margin: `${screenSize > 800 ? ("0px 3vw 0px 0px") : ("0px 20px 20px")}`,

        },
        textPartners: {
            fontFamily: 'UbuntuMedium',
            fontSize: `${screenSize > 800 ? ("30px") : ("22px")}`,
            color: '#fff',
            lineHeight: `${screenSize > 800 ? ("1.3") : ("1.5")}`,
            textAlign: `${screenSize > 800 ? ("end") : ("center")}`,
            margin: 0
        },
        linePartners: {
            display: 'flex',
            width: "2px",
            height: "130px",
            alignSelf: 'center',
            backgroundColor: '#4dc2f1',
            margin: `${screenSize > 800 ? ("0px 3vw") : ("0px")}`,
        },
        imagePartnersDiv: {
            display: "flex",
            flex: 1,
            alignItems: 'center',
            justifyContent: 'center',
            margin: `${screenSize > 800 ? ("0px") : ("10px 0px")}`,
        },
        imagePartners: {
            width: `${screenSize > 800 ? ("40%") : ("20%")}`,
            objectFit: "cover",
            margin: `${screenSize > 800 ? (<></>) : ("15px")}`,
        },
        imagePartners2: {
            width: `${screenSize > 800 ? ("70%") : ("30%")}`,
            objectFit: "cover",
            margin: `${screenSize > 800 ? (<></>) : ("15px")}`,
        },






        bannerMeet: {
            display: "flex",
            flexDirection: `${screenSize > 800 ? ("column") : ("column")}`,
            margin: `${screenSize > 800 ? ("0px") : ("0px")}`,
            alignItems: "center",
            justifyContent: 'center',
            backgroundColor: '#F7F7F5',
            //     height: `${screenSize > 800 ? ("") : (<></>)}`,
            padding: `${screenSize > 800 ? ("100px 0px") : ("50px 0px")}`,
            width: "100vw"
        },
        imageMeetDiv: {
            display: "flex",
            flex: 1,
            alignItems: 'center',
            justifyContent: 'center',
            margin: "0px 0px 20px"
        },
        imageMeet: {
            width: '100px',
            objectFit: "cover",
            //  margin: `${screenSize > 800 ? ("0px 3vw") : ("0px")}`,
        },
        columnTextMeet: {
            display: "flex",
            flex: 1,
            flexDirection: `${screenSize > 800 ? ("column") : ("column")}`,
            justifyContent: 'center',
            padding: `${screenSize > 800 ? ("0px") : ("0px")}`,
            width: `${screenSize > 800 ? ("35vw") : ("70vw")}`,
            margin: `${screenSize > 800 ? ("0px") : ("0px 0px")}`,

        },
        textMeetName: {
            fontFamily: 'UbuntuMedium',
            fontSize: `${screenSize > 800 ? ("20px") : ("16px")}`,
            color: '#4dc2f1',
            lineHeight: '1.3',
            textAlign: 'center',
            margin: `${screenSize > 800 ? ("30px 0px 20px") : ("0px 0px 10px")}`,
        },
        textMeetTitle: {
            fontFamily: 'UbuntuMedium',
            fontSize: `${screenSize > 800 ? ("35px") : ("18px")}`,
            color: '#000',
            lineHeight: '1.3',
            textAlign: 'center',
            margin: "10px 0px 20px"
        },
        textMeetSubtitle: {
            fontFamily: 'UbuntuRegular',
            fontSize: `${screenSize > 800 ? ("18px") : ("16px")}`,
            color: '#000',
            lineHeight: '1.3',
            textAlign: 'center',
            margin: "2px"
        },






        buttonMeetGroup: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            margin: `${screenSize > 800 ? ('50px 0px 0px') : ('0px')}`,
            width: `${screenSize > 800 ? ("600px") : (<></>)}`,
            height: '60px',
        },
        imageButtonDiv: {
            display: "flex",
            alignSelf: 'flex-end',
            alignItems: 'center',
            //   justifyContent: 'flex-end',
            //   margin: "0px 0px 20px"
        },
        imageButton: {
            height: '45px',
            objectFit: "cover",
            //  margin: `${screenSize > 800 ? ("0px 3vw") : ("0px")}`,
        },
        imageButtonDivMobile: {
            display: "flex",
            alignSelf: 'center',
            alignItems: 'center',
            margin: '25px 0px'
        },
        imageButtonMobile: {
            height: '60px',
            objectFit: "cover",
            //  margin: `${screenSize > 800 ? ("0px 3vw") : ("0px")}`,
        },
        buttonMeet: {
            display: 'flex',
            //       flex: 3,
            alignSelf: 'center',
            alignItems: 'center',
            justifyContent: 'center',
            margin: `${screenSize > 800 ? ('0px 20px') : ('0px')}`,

            backgroundColor: 'transparent',
            border: '1.5px solid #000',
            borderRadius: '50px',
            outline: 0,
            //     padding: '20px 40px'
            height: '45px',
            width: '180px',
        },
        buttonMeetText: {
            color: '#000',
            fontSize: '14px',
            fontFamily: 'UbuntuMedium',
            fontWeight: '600',
            margin: '0px',
        },












        columnsolutionsTitleText: {
            fontSize: `${screenSize > 800 ? ('40px') : ('25px')}`,
            //   margin: `${screenSize > 800 ? ('0px 8vw 40px') : ('20px 15vw 20px')}`,
            margin: `${screenSize > 800 ? ('-15px 0px 0px') : ('0px 0px 0px')}`,
            fontFamily: "UbuntuMedium",
            color: '#4dc2f1',
            textAlign: `${screenSize > 800 ? (<></>) : ('center')}`,
        },
        columnsolutionsSubtitleText: {
            color: '#000',
            fontSize: '18px',
            fontFamily: 'UbuntuRegular',
            //   fontWeight: '400',
            margin: '20px 0px 30px',
            padding: `${screenSize > 800 ? ('0px 5vw 0px 0px') : ('0px')}`,
            lineHeight: '30px'
            // textAlign: 'center'
        },




        bannerAvaya: {
            display: "flex",
            flexDirection: `${screenSize > 800 ? ("row") : ("column")}`,
            margin: `${screenSize > 800 ? ("0px") : ("50px 0px 0px")}`,
            alignItems: "center",
            justifyContent: 'center',
            backgroundColor: '#DA291C'
        },
        columnTextAvaya: {
            display: "flex",
            justifyContent: 'center',
            margin: `${screenSize > 800 ? ("0px 40px") : ("60px 20px 0px")}`,
            padding: `${screenSize > 800 ? ("70px 0px") : ("0px")}`,
        },
        textAvaya: {
            fontFamily: 'sans-serif',
            fontWeight: 400,
            fontSize: '22px',
            color: '#fff',
            lineHeight: '1.5',
            textAlign: 'center',
            width: '100%',
            margin: 0
        }
    };
    return (
        <>
            {popUpWelcomeState == true && nameClientFormated != "" && nameClientFormated != undefined ? (
                <>
                    <Confetti numberOfPieces={200} gravity={0.2} recycle={false} width={window.screen.width} height={window.screen.height} />
                    <PopUpWelcome
                        style={{ zIndex: 998 }}
                        screenSize={screenSize}
                        darkModeState={darkModeState}
                        language={language}
                        nameClientFormated={nameClientFormated}
                        handlePopUpWelcome={handlePopUpWelcome}
                    />
                </>
            ) : (<></>)}
            <div style={styles.container} id="scroller">
                <Navbar style={{ zIndex: 999 }}
                    screenSize={screenSize}
                    language={language}
                    handleClick={handleClick}
                    handleLanguage={handleLanguage}
                    origin="home"
                />
                <div>
                    <div style={{
                        display: "flex",
                        flexDirection: 'column',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                        width: '100vw',
                        height: "100vh",
                        margin: 0,
                        padding: 0,
                        //   backgroundImage: `url(${"https://i.postimg.cc/gkVm0h7V/hero.jpg"})`,
                        backgroundColor: "#fff",
                        // backgroundRepeat: "no-repeat",
                        // backgroundSize: "cover",
                    }}
                        id="home"
                    >
                        <div style={{ backgroundColor: 'transparent', height: "100px", width: '100vw' }}></div>
                        <div style={styles.bannerHeader}>
                            <div style={styles.textsHeader}>
                                {screenSize > 800 ? (
                                    <h3 style={styles.titleHeader}>{language === true ? (<>Conexiones virtuales, experiencias  <span style={{ fontFamily: "Autography", fontSize: '72px', margin: "0px 30px 0px 15px", fontWeight: '400', color: "#4dc2f1" }}> reales</span></>) : (<>Digital connections, <span style={{ fontFamily: "Autography", fontSize: '72px', margin: "0px 5px 0px 15px", fontWeight: '400', color: "#4dc2f1" }}>real</span> experiences</>)}</h3>
                                ) : (
                                    <h3 style={styles.titleHeader}>{language === true ? (<>Conexiones virtuales, experiencias  <span style={{ fontFamily: "Autography", fontSize: '42px', margin: "0px 0px 0px 15px", fontWeight: '400', color: "#4dc2f1" }}> reales</span></>) : (<>Digital connections, <span style={{ fontFamily: "Autography", fontSize: '42px', margin: "0px 5px 0px 15px", fontWeight: '400', color: "#4dc2f1" }}>real</span> experiences</>)}</h3>
                                )}
                                {screenSize > 800 ? (
                                    <button style={styles.subtitleHeader} onClick={(e) => handleClick("contact")}>
                                        {language === true ?
                                            <h3 style={styles.subtitleHeaderText}>HABLAR CON UN EXPERTO</h3> :
                                            <h3 style={styles.subtitleHeaderText}>TALK WITH AN EXPERT</h3>}
                                        <div style={styles.lineHeader} />
                                    </button>
                                ) : (<></>)}
                            </div>
                            <div style={{
                                display: "flex",
                                flex: 1,
                                alignItems: 'center',
                                justifyContent: 'center',
                                margin: 0,
                                padding: 0,
                                height: "80vh"
                            }}>
                                <img
                                    src={language === true ? "https://i.postimg.cc/XvS572ck/banner-general-insideone.png" : "https://i.postimg.cc/WpWkCmzS/banner-general-insideone.png"}
                                    // src="https://i.postimg.cc/sf5GM1Rs/insideone-banner-general.png"
                                    //   height={screenSize > 800 ? ('621px') : (<></>)}
                                    height={screenSize > 800 ? ('90%') : (<></>)}
                                    width={screenSize > 800 ? (<></>) : ("70%")}
                                    style={{ objectFit: "cover" }}
                                    alt="Hero of InsideOne"
                                    loading="lazy"
                                />
                            </div>
                        </div>
                        <div style={styles.divButtonHeader}>
                            {screenSize > 800 ? (

                                <></>
                                // <button style={styles.buttonHeader} onClick={(e) => handleClick("firstSlide")}>
                                //     <svg style={styles.svgStyle} fill="currentColor" class="bi bi-chevron-down" viewBox="0 0 16 16">
                                //         <path fill-rule="evenodd" d="M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z" />
                                //     </svg>
                                // </button>
                            ) : (
                                <button style={styles.buttonNavbarWhite} onClick={(e) => handleClick("contact")}>
                                    {language === true ?
                                        <h3 style={styles.textButtonBlue}>Hablemos</h3> :
                                        <h3 style={styles.textButtonBlue}>Let´s talk</h3>}
                                </button>
                            )}
                        </div>
                    </div>
                </div>
                {screenSize > 800 ? (
                    <div style={{
                        position: 'absolute',
                        //     top: "700px",
                        top: "70vh",
                        //  left: '300px',
                        left: '20%',
                        display: "flex",
                        //    flex: 1,
                        // alignItems: 'center',
                        // justifyContent: 'center',
                        margin: 0,
                        padding: 0,
                        // objectPosition: "bottom 10px right 20px"
                        height: "100%"
                    }}>
                        <img
                            src="https://i.postimg.cc/4dHSCXyg/draw-hero.png"
                            //   height={screenSize > 800 ? ('621px') : (<></>)}
                            //    height={screenSize > 800 ? ('350px') : (<></>)}
                            height={screenSize > 800 ? ('40%') : (<></>)}
                            width={screenSize > 800 ? (<></>) : ("70%")}
                            style={{ objectFit: "cover" }}
                            alt="Hero of InsideOne"
                            loading="lazy"
                        />
                    </div>
                ) : (
                    <div style={{
                        position: 'absolute',
                        top: "100vh",
                        left: '40%',
                        display: "flex",
                        //    flex: 1,
                        // alignItems: 'center',
                        // justifyContent: 'center',
                        margin: 0,
                        padding: 0,
                        // objectPosition: "bottom 10px right 20px"
                        //   height: "80vh"
                    }}>
                        <img
                            src="https://i.postimg.cc/1t7Y2fsM/draw-solutions.png"
                            //   height={screenSize > 800 ? ('621px') : (<></>)}
                            height={screenSize > 800 ? ('70px') : (<></>)}
                            width={screenSize > 800 ? (<></>) : ("70%")}
                            style={{ objectFit: "cover" }}
                            alt="Hero of InsideOne"
                            loading="lazy"
                        />
                    </div>
                )}
                <div style={styles.bannerSolutions} id="firstSlide">
                    <div style={styles.subtitleBannerSolutions}>
                        {language === true ?
                            <h3 style={styles.subtitleBannerSolutionsText}>One Cloud</h3> :
                            <h3 style={styles.subtitleBannerSolutionsText}>One Cloud</h3>}
                    </div>
                    {screenSize > 800 ? (
                        <>
                            <h3 style={styles.titleBannerSolutions}>{language === true ? (<>Todas las <span style={{ fontFamily: "Autography", fontSize: '52px', margin: "0px 10px 0px 15px", fontWeight: '400', color: "#000", textDecoration: "underline 2px" }}> soluciones</span></>) : (<>All <span style={{ fontFamily: "Autography", fontSize: '52px', margin: "0px 10px 0px 15px", fontWeight: '400', color: "#000", textDecoration: "underline 2px" }}>solutions</span></>)}</h3>
                            <h3 style={styles.titleBannerSolutions}>{language === true ? (<>en un mismo lugar.</>) : (<>in one place.</>)}</h3>
                        </>
                    ) : (
                        <>
                            <h3 style={styles.titleBannerSolutions}>{language === true ? (<>Todas las  <span style={{ fontFamily: "Autography", fontSize: '42px', margin: "0px 0px 0px 10px", fontWeight: '400', color: "#000", textDecoration: "underline 2px" }}> soluciones</span></>) : (<>All <span style={{ fontFamily: "Autography", fontSize: '42px', margin: "0px 0px 0px 10px", fontWeight: '400', color: "#000", textDecoration: "underline 2px" }}>solutions</span></>)}</h3>
                            <h3 style={styles.titleBannerSolutions}>{language === true ? (<>en un mismo lugar.</>) : (<>in one place.</>)}</h3>
                        </>
                    )}
                    <img
                        src="https://i.postimg.cc/zv87gyHj/Componente-4-1-2x.png"
                        //   height={screenSize > 800 ? ('621px') : (<></>)}
                        height={screenSize > 800 ? ('350px') : (<></>)}
                        width={screenSize > 800 ? (<></>) : ("80%")}
                        style={{ objectFit: "cover", margin: '70px 0px 100px' }}
                        alt="Hero of InsideOne"
                        loading="lazy"
                    />
                    <button style={styles.buttonBannerSolutions} onClick={(e) => handleClick("contact")}>
                        {language === true ?
                            <h3 style={styles.buttonBannerSolutionsText}>VER SOLUCIÓN</h3> :
                            <h3 style={styles.buttonBannerSolutionsText}>LEARN MORE</h3>}
                    </button>

                    {/* <div style={styles.columnText} >
                        {language === true ?
                            <div style={styles.columnTextPadding} >
                                <h1 style={styles.columnTextContext} >JML</h1>
                                <h1 style={styles.columnTextTitle} > Mejora los procesos de asignación </h1>
                                <h1 style={styles.columnTextBody} >El alta de un nuevo integrante al equipo, los cambios de rol y las bajas son actividades que implican varios procesos de gestión de activos tanto físicos como digitales dentro de una compañía.</h1>
                                <h1 style={styles.columnTextBody} >Optimice tiempo y costos con nuestra solución JML, automatizando la asignación y decomiso de activos a su fuerza de trabajo.</h1>
                            </div> :
                            <div style={styles.columnTextPadding} >
                                <h1 style={styles.columnTextContext} >JOIN MOVE LEAVE</h1>
                                <h1 style={styles.columnTextTitle} > Improve resource assignment processes. </h1>
                                <h1 style={styles.columnTextBody} >The incorporation of a new member to the team, role changes and terminations are activities that involve various processes for the management of both physical and digital assets within a company. </h1>
                                <h1 style={styles.columnTextBody} >Optimize time and costs with our JML solution, which automates asset allocation and forfeiture to your workforce.</h1>
                            </div>}
                    </div>
                    <div style={{
                        display: "flex",
                        backgroundImage: `url(${"https://i.postimg.cc/JnMxvrZs/join-move-leave.png"})`,
                        backgroundRepeat: "no-repeat",
                        backgroundSize: "cover",
                        margin: 0,
                        padding: 0,
                        height: `${screenSize > 800 ? ("637px") : ("250px")} `,
                        width: `${screenSize > 800 ? ("50vw") : ("100%")} `,
                    }} /> */}
                </div>



                {/* <InlineWidget url="https://calendly.com/insideone/30min?hide_landing_page_details=1&primary_color=4dc2f1"
                    styles={{
                        height: '1000px'
                    }}
                    pageSettings={{
                        backgroundColor: 'ffffff',
                        hideEventTypeDetails: false,
                        hideLandingPageDetails: false,
                        //primaryColor: '4dc2f1',
                        //      textColor: '4d5055'
                    }}
                // prefill={{
                //     date: new Date(Date.now() + 86400000)
                // }}
                /> */}


                {/* <div style={styles.columnSolutions}>
                    <div style={{
                        display: "flex",
                        backgroundImage: `url(${"https://i.postimg.cc/zXbd8RFb/banner-contact-center.png"})`,
                        backgroundRepeat: "no-repeat",
                        backgroundSize: "cover",
                        backgroundPosition: 'center',
                        margin: 0,
                        padding: 0,
                        height: `${screenSize > 800 ? ("90vh") : ("250px")} `,
                        width: `${screenSize > 800 ? ("50vw") : ("100%")} `,
                        objectFit: 'cover'
                    }} />
                    <div style={styles.columnsolutionsText}>
                        <div style={styles.columnsolutionsTipText}>{language === true ? (<>Agent Bot</>) : (<>Agent Bot</>)}</div>
                        {screenSize > 800 ? (
                            <>
                                <h3 style={styles.columnsolutionsTitleText}>{language === true ? (<>Brinda <span style={{ fontFamily: "Autography", fontSize: '62px', margin: "0px 0px 0px 5px", fontWeight: '400', color: "#000" }}> respuestas</span> en</>) : (<>Give <span style={{ fontFamily: "Autography", fontSize: '62px', margin: "0px 0px 0px 5px", fontWeight: '400', color: "#000" }}>answers</span></>)}</h3>
                                <h3 style={styles.columnsolutionsTitleText}>{language === true ? (<>el momento</>) : (<>at the moment</>)}</h3>

                            </>
                        ) : (
                            <>
                                <h3 style={styles.columnsolutionsTitleText}>{language === true ? (<>Brinda  <span style={{ fontFamily: "Autography", fontSize: '42px', margin: "0px 0px 0px 10px", fontWeight: '400', color: "#000" }}> respuestas</span></>) : (<>Give <span style={{ fontFamily: "Autography", fontSize: '42px', margin: "0px 0px 0px 10px", fontWeight: '400', color: "#000" }}>answers</span></>)}</h3>
                                <h3 style={styles.columnsolutionsTitleText}>{language === true ? (<>en el momento</>) : (<>at the moment</>)}</h3>

                            </>
                        )}
                        <h3 style={styles.columnsolutionsSubtitleText}>{language === true ? (<>Solución automática con IA para el Servicio al Cliente en canales digitales</>) : (<>Automatic solution with AI for Customer Service in digital channels</>)}</h3>
                        <button style={styles.buttonBannerSolutions} onClick={(e) => handleClick("contact")}>
                            {language === true ?
                                <h3 style={styles.buttonBannerSolutionsText}>VER SOLUCIÓN</h3> :
                                <h3 style={styles.buttonBannerSolutionsText}>LEARN MORE</h3>}
                        </button>
                        <div style={styles.columnsolutionsOptions}>
                            {solutionBanner == 1 ? <button style={styles.columnsolutionsOptionsTextActive}>01</button> : <button style={styles.columnsolutionsOptionsText} onClick={() => { setSolutionBanner(1) }}>01</button>}
                            {solutionBanner == 2 ? <button style={styles.columnsolutionsOptionsTextActive}>02</button> : <button style={styles.columnsolutionsOptionsText} onClick={() => { setSolutionBanner(2) }}>02</button>}
                            {solutionBanner == 3 ? <button style={styles.columnsolutionsOptionsTextActive}>03</button> : <button style={styles.columnsolutionsOptionsText} onClick={() => { setSolutionBanner(3) }}>03</button>}
                            {solutionBanner == 4 ? <button style={styles.columnsolutionsOptionsTextActive}>04</button> : <button style={styles.columnsolutionsOptionsText} onClick={() => { setSolutionBanner(4) }}>04</button>}
                        </div>
                    </div>
                </div> */}


                <div style={styles.columnSolutions} id="solutions">
                    <div style={{
                        display: "flex",
                        backgroundImage: `url(${language === true ? solutions[solutionBanner - 1].urlImage : solutions[solutionBanner - 1].urlImageEnglish})`,
                        backgroundRepeat: "no-repeat",
                        backgroundSize: "cover",
                        backgroundPosition: 'center',
                        margin: 0,
                        padding: 0,
                        height: `${screenSize > 800 ? ("90vh") : ("350px")} `,
                        width: `${screenSize > 800 ? ("50vw") : ("100%")} `,
                        objectFit: 'cover'
                    }} />
                    <div style={styles.columnsolutionsText}>
                        <div style={styles.columnsolutionsTipText}>{language === true ? solutions[solutionBanner - 1].tipSpanish : solutions[solutionBanner - 1].tipEnglish}</div>


                        {solutionBanner == 1 ?
                            <>
                                {screenSize > 800 ? (
                                    <>
                                        <h3 style={styles.columnsolutionsTitleText}>{language === true ? (<>Brinda <span style={{ fontFamily: "Autography", fontSize: '62px', margin: "0px 0px 0px 5px", fontWeight: '400', color: "#000" }}> respuestas</span> en</>) : (<>Give <span style={{ fontFamily: "Autography", fontSize: '62px', margin: "0px 0px 0px 5px", fontWeight: '400', color: "#000" }}>answers</span></>)}</h3>
                                        <h3 style={styles.columnsolutionsTitleText}>{language === true ? (<>el momento</>) : (<>at the moment</>)}</h3>

                                    </>
                                ) : (
                                    <>
                                        <h3 style={styles.columnsolutionsTitleText}>{language === true ? (<>Brinda  <span style={{ fontFamily: "Autography", fontSize: '42px', margin: "0px 0px 0px 10px", fontWeight: '400', color: "#000" }}> respuestas</span></>) : (<>Give <span style={{ fontFamily: "Autography", fontSize: '42px', margin: "0px 0px 0px 10px", fontWeight: '400', color: "#000" }}>answers</span></>)}</h3>
                                        <h3 style={styles.columnsolutionsTitleText}>{language === true ? (<>en el momento</>) : (<>at the moment</>)}</h3>

                                    </>
                                )}
                            </>
                            : <></>}
                        {solutionBanner == 2 ?
                            <>
                                {screenSize > 800 ? (
                                    <>
                                        <h3 style={styles.columnsolutionsTitleText}>{language === true ? (<>Está <span style={{ fontFamily: "Autography", fontSize: '62px', margin: "0px 0px 0px 5px", fontWeight: '400', color: "#000" }}> presente</span> en</>) : (<>Be <span style={{ fontFamily: "Autography", fontSize: '62px', margin: "0px 0px 0px 5px", fontWeight: '400', color: "#000" }}>present</span></>)}</h3>
                                        <h3 style={styles.columnsolutionsTitleText}>{language === true ? (<>todos los canales</>) : (<>at all channels</>)}</h3>

                                    </>
                                ) : (
                                    <>
                                        <h3 style={styles.columnsolutionsTitleText}>{language === true ? (<>Está  <span style={{ fontFamily: "Autography", fontSize: '42px', margin: "0px 10px 0px 10px", fontWeight: '400', color: "#000" }}> presente</span> en</>) : (<>Be <span style={{ fontFamily: "Autography", fontSize: '42px', margin: "0px 0px 0px 10px", fontWeight: '400', color: "#000" }}>present</span></>)}</h3>
                                        <h3 style={styles.columnsolutionsTitleText}>{language === true ? (<>todos los canales</>) : (<>at all channels</>)}</h3>

                                    </>
                                )}
                            </>
                            : <></>}
                        {solutionBanner == 3 ?
                            <>
                                {screenSize > 800 ? (
                                    <>
                                        <h3 style={styles.columnsolutionsTitleText}>{language === true ? (<><span style={{ fontFamily: "Autography", fontSize: '62px', margin: "0px 0px 0px 5px", fontWeight: '400', color: "#000" }}>Experiencias</span> que</>) : (<><span style={{ fontFamily: "Autography", fontSize: '62px', margin: "0px 0px 0px 5px", fontWeight: '400', color: "#000" }}>Experiences</span> your</>)}</h3>
                                        <h3 style={styles.columnsolutionsTitleText}>{language === true ? (<>van amar</>) : (<>customers will love</>)}</h3>

                                    </>
                                ) : (
                                    <>
                                        <h3 style={styles.columnsolutionsTitleText}>{language === true ? (<><span style={{ fontFamily: "Autography", fontSize: '42px', margin: "0px 0px 10px 0px", fontWeight: '400', color: "#000" }}>Experiencias</span> que</>) : (<><span style={{ fontFamily: "Autography", fontSize: '42px', margin: "0px 0px 10px 0px", fontWeight: '400', color: "#000" }}>Experiences</span> your</>)}</h3>
                                        <h3 style={styles.columnsolutionsTitleText}>{language === true ? (<>van amar</>) : (<>customers will love</>)}</h3>

                                    </>
                                )}
                            </>
                            : <></>}
                        {solutionBanner == 4 ?
                            <>
                                {screenSize > 800 ? (
                                    <>
                                        <h3 style={styles.columnsolutionsTitleText}>{language === true ? (<>Integra <span style={{ fontFamily: "Autography", fontSize: '62px', margin: "0px 0px 0px 20px", fontWeight: '400', color: "#000" }}> todas</span></>) : (<>Integrate <span style={{ fontFamily: "Autography", fontSize: '62px', margin: "0px 0px 0px 5px", fontWeight: '400', color: "#000" }}>all</span></>)}</h3>
                                        <h3 style={styles.columnsolutionsTitleText}>{language === true ? (<>las soluciones</>) : (<>solutions</>)}</h3>

                                    </>
                                ) : (
                                    <>
                                        <h3 style={styles.columnsolutionsTitleText}>{language === true ? (<>Integra  <span style={{ fontFamily: "Autography", fontSize: '42px', margin: "0px 0px 0px 15px", fontWeight: '400', color: "#000" }}> todas</span></>) : (<>Integrate <span style={{ fontFamily: "Autography", fontSize: '42px', margin: "0px 0px 0px 10px", fontWeight: '400', color: "#000" }}>all</span></>)}</h3>
                                        <h3 style={styles.columnsolutionsTitleText}>{language === true ? (<>las soluciones</>) : (<>solutions</>)}</h3>

                                    </>
                                )}
                            </>
                            : <></>}
                        {/* {screenSize > 800 ? (
                            <>
                                <h3 style={styles.columnsolutionsTitleText}>{language === true ? (<>Brinda <span style={{ fontFamily: "Autography", fontSize: '62px', margin: "0px 0px 0px 5px", fontWeight: '400', color: "#000" }}> respuestas</span> en</>) : (<>Give <span style={{ fontFamily: "Autography", fontSize: '62px', margin: "0px 0px 0px 5px", fontWeight: '400', color: "#000" }}>answers</span></>)}</h3>
                                <h3 style={styles.columnsolutionsTitleText}>{language === true ? (<>el momento</>) : (<>at the moment</>)}</h3>

                            </>
                        ) : (
                            <>
                                <h3 style={styles.columnsolutionsTitleText}>{language === true ? (<>Brinda  <span style={{ fontFamily: "Autography", fontSize: '42px', margin: "0px 0px 0px 10px", fontWeight: '400', color: "#000" }}> respuestas</span></>) : (<>Give <span style={{ fontFamily: "Autography", fontSize: '42px', margin: "0px 0px 0px 10px", fontWeight: '400', color: "#000" }}>answers</span></>)}</h3>
                                <h3 style={styles.columnsolutionsTitleText}>{language === true ? (<>en el momento</>) : (<>at the moment</>)}</h3>

                            </>
                        )} */}

                        <h3 style={styles.columnsolutionsSubtitleText}>{language === true ? solutions[solutionBanner - 1].subtitleSpanish : solutions[solutionBanner - 1].subtitleEnglish}</h3>
                        {/* <Link style={styles.buttonBannerSolutions} to={solutions[solutionBanner - 1].url}>
                            {language === true ?
                                <h3 style={styles.buttonBannerSolutionsText}>VER SOLUCIÓN</h3> :
                                <h3 style={styles.buttonBannerSolutionsText}>LEARN MORE</h3>}
                        </Link> */}



                        {solutionBanner == 2 || solutionBanner == 3 ?
                            <Link style={styles.buttonBannerSolutions} to="/solutions/contact-center">
                                {language === true ?
                                    <h3 style={styles.buttonBannerSolutionsText}>VER SOLUCIÓN</h3> :
                                    <h3 style={styles.buttonBannerSolutionsText}>LEARN MORE</h3>}
                            </Link>
                            :

                            <Link style={styles.buttonBannerSolutions} to="/solutions/artificial-intelligence">
                                {language === true ?
                                    <h3 style={styles.buttonBannerSolutionsText}>VER SOLUCIÓN</h3> :
                                    <h3 style={styles.buttonBannerSolutionsText}>LEARN MORE</h3>}
                            </Link>
                            // <button style={styles.buttonBannerSolutions} onClick={(e) => handleClick("contact")}>
                            //     {language === true ?
                            //         <h3 style={styles.buttonBannerSolutionsText}>VER SOLUCIÓN</h3> :
                            //         <h3 style={styles.buttonBannerSolutionsText}>LEARN MORE</h3>}
                            // </button>
                        }






                        <div style={styles.columnsolutionsOptions}>
                            {solutionBanner == 1 ? <button style={styles.columnsolutionsOptionsTextActive}>01</button> : <button style={styles.columnsolutionsOptionsText} onClick={() => { setSolutionBanner(1) }}>01</button>}
                            {solutionBanner == 2 ? <button style={styles.columnsolutionsOptionsTextActive}>02</button> : <button style={styles.columnsolutionsOptionsText} onClick={() => { setSolutionBanner(2) }}>02</button>}
                            {solutionBanner == 3 ? <button style={styles.columnsolutionsOptionsTextActive}>03</button> : <button style={styles.columnsolutionsOptionsText} onClick={() => { setSolutionBanner(3) }}>03</button>}
                            {solutionBanner == 4 ? <button style={styles.columnsolutionsOptionsTextActive}>04</button> : <button style={styles.columnsolutionsOptionsText} onClick={() => { setSolutionBanner(4) }}>04</button>}
                        </div>
                    </div>
                </div>















                {/* 
                <div style={styles.columnContainerNoTopNoBottom}>
                    <div style={{
                        display: "flex",
                        backgroundImage: `url(${"https://i.postimg.cc/526TkQ8M/contact-center.jpg"})`,
                        backgroundRepeat: "no-repeat",
                        backgroundSize: "cover",
                        margin: 0,
                        padding: 0,
                        height: `${screenSize > 800 ? ("480px") : ("250px")} `,
                        width: `${screenSize > 800 ? ("50vw") : ("100%")} `,
                    }} />
                    <div style={styles.columnText}>
                        {language === true ?
                            <div style={styles.columnTextPadding} >
                                <h1 style={styles.columnTextContext} >CONTACT CENTER</h1>
                                <h1 style={styles.columnTextTitle} > Tecnología para Atención al Cliente, Ventas y Cobranzas potenciado por IA </h1>
                                <h1 style={styles.columnTextBody} > Plataforma #1 de Contact Center Omnicanal en la nube, utilizada por cientos de empresas en el mundo. </h1>
                                <h1 style={styles.columnTextBody} >   Mejorá la satisfacción de tus clientes y entregá una experiencia única con Chatbots, Voicebots, Automatización e Inteligencia Artificial. </h1>
                            </div> :
                            <div style={styles.columnTextPadding} >
                                <h1 style={styles.columnTextContext} >CONTACT CENTER</h1>
                                <h1 style={styles.columnTextTitle} > Technology for Customer Service, Sales and Collections powered by AI </h1>
                                <h1 style={styles.columnTextBody} > No.1 Omnichannel Contact Center platform delivered via the cloud, used by hundreds of companies around the world. </h1>
                                <h1 style={styles.columnTextBody} > Improve your customer satisfaction and deliver a unique experience with Chatbots, Voicebots, Automation and Artificial Intelligence. </h1>
                            </div>}
                    </div>
                </div> */}









                <div style={styles.bannerPartners}>
                    <div style={styles.columnTextPartners}>
                        {language === true ? <h2 style={styles.textPartners}>Construyendo relaciones <span style={{ fontFamily: "UbuntuRegular" }}>Partners</span></h2> : <h2 style={styles.textPartners}>Building relationships <span style={{ fontFamily: "UbuntuRegular" }}>Partners</span></h2>}
                    </div>
                    {screenSize > 800 ? (<div style={styles.linePartners} />) : (<></>)}
                    <div style={styles.imagePartnersDiv}>
                        <img
                            src="https://i.postimg.cc/NfQqqwxH/salesforce-logo.png"
                            // height={screenSize > 800 ? (<></>) : ("10%")}
                            // width={screenSize > 800 ? ("10%") : ("70%")}
                            style={styles.imagePartners}
                            alt="Logo"
                            loading="lazy"
                        />
                    </div>
                    <div style={styles.imagePartnersDiv}>
                        <img
                            src="https://i.postimg.cc/mghnBZ1t/aws-logo.png"
                            // height={screenSize > 800 ? (<></>) : ("10%")}
                            // width={screenSize > 800 ? ("10%") : ("70%")}
                            style={styles.imagePartners}
                            alt="Logo"
                            loading="lazy"
                        />
                    </div>

                    <div style={styles.imagePartnersDiv}>
                        <img
                            src="https://i.postimg.cc/yNKCp2km/logo-avaya-white.png"
                            // height={screenSize > 800 ? (<></>) : ("10%")}
                            // width={screenSize > 800 ? ("10%") : ("70%")}
                            style={styles.imagePartners2}
                            alt="Logo"
                            loading="lazy"
                        />
                    </div>
                    <div style={styles.imagePartnersDiv}>
                        <img
                            src="https://i.postimg.cc/Nf1c8g0s/logo-collab-white.png"
                            // height={screenSize > 800 ? (<></>) : ("10%")}
                            // width={screenSize > 800 ? ("10%") : ("70%")}
                            style={styles.imagePartners2}
                            alt="Logo"
                            loading="lazy"
                        />
                    </div>

                    {/* <div style={styles.imagePartnersDiv}>
                        <img
                            src="https://i.postimg.cc/J0X5DYMn/logo-cirion-white.png"
                            // height={screenSize > 800 ? (<></>) : ("10%")}
                            // width={screenSize > 800 ? ("10%") : ("70%")}
                            style={styles.imagePartners}
                            alt="Logo"
                            loading="lazy"
                        />
                    </div>
                    <div style={styles.imagePartnersDiv}>
                        <img
                            src="https://i.postimg.cc/FFkd4JTT/logo-claro-white.png"
                            // height={screenSize > 800 ? (<></>) : ("10%")}
                            // width={screenSize > 800 ? ("10%") : ("70%")}
                            style={styles.imagePartners}
                            alt="Logo"
                            loading="lazy"
                        />
                    </div> */}
                </div>






                {/* <div style={styles.bannerAvaya}>
                    <div style={styles.columnTextAvaya}>
                        {language === true ? <h1 style={styles.textAvaya}>Alianza estratégica para ofrecer soluciones World Class.</h1> : <h1 style={styles.textAvaya}>Strategic alliance to offer World Class solutions.</h1>}
                    </div>
                    <div style={{
                        display: "flex",
                        backgroundImage: `url(${"https://i.postimg.cc/SRhmNRYW/Avaya-Logo-White.png"})`,
                        backgroundRepeat: "no-repeat",
                        backgroundSize: "cover",
                        margin: `${screenSize > 800 ? ("0px 40px") : ("50px 0px 60px")} `,
                        padding: 0,
                        height: `${screenSize > 800 ? ("44px") : ("44px")} `,
                        width: `${screenSize > 800 ? ("155px") : ("155px")} `,
                    }} />
                </div> */}








                <div style={styles.columnContainerReverse}>
                    <div style={{
                        display: "flex",
                        alignItems: 'center',
                        justifyContent: `${screenSize > 800 ? ("flex-end") : ("center")} `,
                        margin: 0,
                        padding: `${screenSize > 800 ? (0) : ("20px 0px 0px")} `,
                        width: `${screenSize > 800 ? ("50vw") : ("100%")} `,
                    }}>
                        <img
                            src="https://i.postimg.cc/SNtxH8YS/chatbot-insideone.png"
                            //    height="637px"
                            height={screenSize > 800 ? ("637px") : ("350px")}
                            alt="Image of chatbot"
                        />
                    </div>
                    <div style={styles.columnText} >
                        {language === true ?
                            <div style={styles.columnTextPadding} >
                                <h1 style={styles.columnTextContext} >WHATSAPP</h1>
                                <h1 style={styles.columnTextTitle} >Chateá con tus clientes por WhatsApp </h1>
                                <h1 style={styles.columnTextBody} > Incorporá un Chatbot en tu cuenta WhatsApp Business, automatizá tus conversaciones y ofrecé atención las 24 horas.
                                    Dale a tus clientes la posibilidad de autogestionarse integrando WhatsApp con tu CRM, ERP o cualquier sistema de información.</h1>
                                <h1 style={styles.columnTextBody} >Optimizá tu flujo de atención con una plataforma integrada y múltiples agentes u operadores. Desplegá una experiencia única que permite cambiar de canal de atención sin perder el contexto de la conversación. </h1>
                            </div> :
                            <div style={styles.columnTextPadding} >
                                <h1 style={styles.columnTextContext}>WHATSAPP</h1>
                                <h1 style={styles.columnTextTitle} > Chat with your clients on WhatsApp</h1>
                                <h1 style={styles.columnTextBody} > Incorporate a Chatbot into your WhatsApp Business account, automate your conversations and offer 24-hour service.
                                    Give your customers the possibility of self-management by integrating WhatsApp with your CRM, ERP or any information system.  </h1>
                                <h1 style={styles.columnTextBody} > Optimize your customer care process with an integrated platform and multiple agents or operators. Deploy a unique experience that allows you to change the service channel without losing track of the conversation. </h1>
                            </div>}
                    </div>
                </div>

                {/* <div style={styles.columnContainerNoBottom}>
                    <div style={styles.columnText} >
                        {language === true ?
                            <div style={styles.columnTextPadding} >
                                <h1 style={styles.columnTextContext} >OMNICANALIDAD</h1>
                                <h1 style={styles.columnTextTitle} > Interactuá con tus clientes por su canal favorito </h1>
                                <h1 style={styles.columnTextBody} > Maximiza la experiencia y satisfacción de tus clientes integrando al centro de atención los canales de Voz, WhatsApp, E-mail, Facebook, Twitter, Instagram, WebChat, SMS y más.</h1>
                            </div> :
                            <div style={styles.columnTextPadding} >
                                <h1 style={styles.columnTextContext} >MULTI-CHANNEL</h1>
                                <h1 style={styles.columnTextTitle} > Interact with your customers through their favorite channel </h1>
                                <h1 style={styles.columnTextBody} > Maximize the experience and satisfaction of your customers by integrating Voice, WhatsApp, E-mail, Facebook, Twitter, Instagram, WebChat, SMS channels and more into the service center.</h1>
                            </div>}
                    </div>
                    <div style={{
                        display: "flex",
                        backgroundImage: `url(${"https://i.postimg.cc/85gRTKPV/omnichannel.png"})`,
                        backgroundRepeat: "no-repeat",
                        backgroundSize: "cover",
                        margin: 0,
                        padding: 0,
                        height: `${screenSize > 800 ? ("480px") : ("250px")} `,
                        width: `${screenSize > 800 ? ("50vw") : ("100%")} `,
                    }} />
                </div>

                <div style={styles.columnContainerNoTop}>
                    <div style={{
                        display: "flex",
                        backgroundImage: `url(${"https://i.postimg.cc/m2WgVvp4/quality.jpg"})`,
                        backgroundRepeat: "no-repeat",
                        backgroundSize: "cover",
                        margin: 0,
                        padding: 0,
                        height: `${screenSize > 800 ? ("480px") : ("250px")} `,
                        width: `${screenSize > 800 ? ("50vw") : ("100%")} `,
                    }} />
                    <div style={styles.columnTextGrey} >
                        {language === true ?
                            <div style={styles.columnTextPadding} >
                                <h1 style={styles.columnTextContext} >AUTOMATIZACIÓN</h1>
                                <h1 style={styles.columnTextTitle} > Bots: Brindá autogestión y personalizá cada interacción </h1>
                                <h1 style={styles.columnTextBody} > Implementá fácilmente procesos de RPA, interactuá con Web Services o sistemas de terceros y configurá Chatbots o Voicebots para entregar una experiencia única, reduciendo tiempos de atención gracias a la automatización de flujos. </h1>
                            </div> :
                            <div style={styles.columnTextPadding} >
                                <h1 style={styles.columnTextContext} >AUTOMATION</h1>
                                <h1 style={styles.columnTextTitle} > Bots: Provide self-management and personalize every interaction </h1>
                                <h1 style={styles.columnTextBody} > Easily implement RPA processes, interact with Web Services or third-party systems and configure Chatbots or Voicebots to deliver a unique experience, reducing service times thanks to flow automation. </h1>
                            </div>}
                    </div>
                </div> */}

                {/* <div style={styles.titleCaseContainer}>
                    {language === true ?
                        <h1 style={styles.titleCase}>CASOS DE ÉXITO</h1> :
                        <h1 style={styles.titleCase}>SUCCESS STORY</h1>}
                </div> */}



                {/* {page !== "Arg" ? (
                    <div style={styles.columnCaseContainer}>
                        <div style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: 'center',
                            width: `${screenSize > 800 ? ("30vw") : ("80vw")} `,
                            height: `${screenSize > 800 ? ("445px") : ("230px")} `,
                            margin: 0,
                            padding: `${screenSize > 800 ? (0) : ("20px")} `,
                            backgroundColor: "#C91733"
                        }}>
                            <img src="https://i.postimg.cc/hG8scn3m/casen-recordati.png" style={styles.imageCase3} alt="Logo of Casen Recordati" />
                        </div>
                        <div style={styles.columnCaseTextRecordati} >
                            {language === true ?
                                <div style={styles.columnCasePaddingRecordati} >
                                    <h1 style={styles.columnTextCaseRecordati}>Desde finales de 2019 Casen Recordati ha liberado a su área de administración y finanzas de una tediosa tarea manual: enviar listas, copias y duplicados de facturas a sus clientes.</h1>
                                    <h1 style={styles.columnTextCaseRecordati}>Actualmente resuelven 300 de estos pedidos en promedio al mes empleando un modelo de autogestión omnicanal con nuestra tecnología, permitiendo a sus clientes obtener los documentos al instante.</h1>
                                </div> :
                                <div style={styles.columnCasePaddingRecordati} >
                                    <h1 style={styles.columnTextCaseRecordati}>Since the end of 2019, Casen Recordati has reduced its administration and finance area of ​​tedious manual tasks: sending lists, copies and duplicates of invoices to its clients.</h1>
                                    <h1 style={styles.columnTextCaseRecordati}>They currently solve 300 of these orders on average per month using an omnichannel self-management model with our technology, allowing their clients to obtain documents instantly.</h1>
                                </div>}

                        </div>
                    </div>
                ) : (
                    <div style={styles.columnCaseContainer}>
                        <div style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: 'center',
                            width: `${screenSize > 800 ? ("30vw") : ("80vw")} `,
                            height: `${screenSize > 800 ? ("345px") : ("230px")} `,
                            margin: 0,
                            padding: `${screenSize > 800 ? (0) : ("20px")} `,
                            backgroundColor: "#D52B1E"
                        }}>
                            <img src="https://i.postimg.cc/CLRqCCR8/logo-dia.png" style={styles.imageCase2} alt="Logo of Dia" />
                        </div>
                        <div style={styles.columnCaseText} >
                            {language === true ?
                                <div style={styles.columnCasePadding} >
                                    <h1 style={styles.columnTextCaseNoTop} > "Hemos disminuído el tiempo de atención 6 veces y maximizamos la satisfacción de nuestros clientes en un 40%"</h1>
                                    <h1 style={styles.columnBoldCase} > Marcela Gutierrez</h1>
                                    <h1 style={styles.columnBoldCase} > Líder de SAC</h1>
                                </div> :
                                <div style={styles.columnCasePadding} >
                                    <h1 style={styles.columnTextCaseNoTop} > "We have reduced the service time 6 fold and we improved the satisfaction of our clients by 40%"</h1>
                                    <h1 style={styles.columnBoldCase} > Marcela Gutierrez</h1>
                                    <h1 style={styles.columnBoldCase} > SAC Leader</h1>
                                </div>}

                        </div>
                    </div>
                )} */}

                <div style={styles.titleClientContainer}>
                    {page !== "Arg" ? (
                        <>
                            {language === true ?
                                <h1 style={styles.titleClient}>Ellos eligen nuestra tecnología para mejorar la  <span style={{ fontWeight: '600' }}>Experiencia de sus Clientes</span></h1> :
                                <h1 style={styles.titleClient}>They choose our technology to improve  <span style={{ fontWeight: '600' }}>Customer Experience</span></h1>}
                        </>
                    ) : (
                        <>
                            {language === true ?
                                <h1 style={styles.titleClient}>Ellos eligen nuestra tecnología de <span style={{ fontWeight: '600' }}>Contact Center</span> para mejorar la  <span style={{ fontWeight: '600' }}>Experiencia de sus Clientes</span></h1> :
                                <h1 style={styles.titleClient}>They choose our <span style={{ fontWeight: '600' }}>Contact Center technology</span> to improve  <span style={{ fontWeight: '600' }}>Customer Experience</span></h1>}
                        </>
                    )}
                </div>
                <div style={styles.columnClientContainer}>
                    <img src="https://i.postimg.cc/8ChJPW4r/bbva.png" style={styles.imageClient} alt="Logo of BBVA" />
                    <img src="https://i.postimg.cc/t4FChbxK/galicia-seguros.png" style={styles.imageClient} alt="Logo of Galicia Seguros" />
                    <img src="https://i.postimg.cc/HxkYD7hm/macro.png" style={styles.imageClient} alt="Logo of Banco Macro" />
                    <img src="https://i.postimg.cc/zv98g2XD/mercado-libre.png" style={styles.imageClient} alt="Logo of Mercado Libre" />
                    <img src="https://i.postimg.cc/vHVQsy34/prudential.png" style={styles.imageClient} alt="Logo of Prudential" />
                </div>

                <div style={styles.columnContainerReverse}>
                    <div style={styles.columnText} >
                        {language === true ?
                            <div style={styles.columnTextPadding} >
                                <h1 style={styles.columnTextContext} >COMUNICACIONES UNIFICADAS</h1>
                                <h1 style={styles.columnTextTitle} > Telefonía IP, Video y Colaboración en la Nube </h1>
                                <h1 style={styles.columnTextBody} > Potenciá la productividad de tu empresa a través de Llamadas, Video Conferencias, Mensajería y Colaboración, desde cualquier dispositivo en forma remota o en la oficina.</h1>
                            </div> :
                            <div style={styles.columnTextPadding} >
                                <h1 style={styles.columnTextContext} >UNIFIED COMMUNICATIONS</h1>
                                <h1 style={styles.columnTextTitle} > IP Telephony, Video and Cloud Collaboration </h1>
                                <h1 style={styles.columnTextBody} > Boost the productivity of your company through Calls, Video Conferences, Messaging and Collaboration, from any device remotely or in the office.</h1>
                            </div>}
                    </div>
                    <div style={{
                        display: "flex",
                        backgroundImage: `url(${"https://i.postimg.cc/hjV0xF3j/unified-communications.png"})`,
                        backgroundRepeat: "no-repeat",
                        backgroundSize: "cover",
                        margin: 0,
                        padding: 0,
                        height: `${screenSize > 800 ? ("467px") : ("250px")} `,
                        width: `${screenSize > 800 ? ("50vw") : ("100%")} `,
                    }} />
                </div>


                <div style={styles.columnContainer}>
                    <div style={{
                        display: "flex",
                        margin: 0,
                        padding: 0,
                        backgroundImage: `url(${"https://i.postimg.cc/BZDz5k12/movilidad.jpg"})`,
                        backgroundRepeat: "no-repeat",
                        backgroundSize: "cover",
                        height: `${screenSize > 800 ? ("480px") : ("250px")} `,
                        width: `${screenSize > 800 ? ("50vw") : ("100%")} `,
                    }} />
                    <div style={styles.columnText} >

                        {language === true ?
                            <div style={styles.columnTextPadding} >
                                <h1 style={styles.columnTextContext} >CENTRAL TELEFÓNICA IP</h1>
                                <h1 style={styles.columnTextTitle} > Llevá tu interno en el celular </h1>
                                <h1 style={styles.columnTextBody} > Simplifica las comunicaciones de tu empresa y accede a múltiples funcionalidades e integraciones.</h1>
                                <h1 style={styles.columnTextBody} > Una experiencia unificada con llamadas, video y mensajería desde tu computadora, teléfono de escritorio o dispositivo móvil.</h1>
                            </div> :
                            <div style={styles.columnTextPadding} >
                                <h1 style={styles.columnTextContext} >IP TELEPHONE SYSTEMS</h1>
                                <h1 style={styles.columnTextTitle} > Your office in the pocket </h1>
                                <h1 style={styles.columnTextBody} > Simplify your company communications and access multiple functionalities and integrations.</h1>
                                <h1 style={styles.columnTextBody} > A unified experience with calls, video and messaging from your computer, desk phone or mobile device.</h1>
                            </div>}
                    </div>
                </div>





                <div style={styles.titleCaseContainer}>
                    {language === true ?
                        <h1 style={styles.titleCase}>CASOS DE ÉXITO</h1> :
                        <h1 style={styles.titleCase}>SUCCESS STORY</h1>}
                </div>
                <div style={styles.columnCaseContainerCarrefour}>
                    <div style={styles.columnCase}>
                        <img src="https://i.postimg.cc/bJKj7jRp/logo-carrefour.png" style={styles.imageCase} alt="Logo of Carrefour" />
                    </div>
                    <div style={styles.columnCaseText} >
                        {language === true ?
                            <div style={styles.columnTextPadding} >
                                <h1 style={styles.columnTextCase} > +600 Sitios Conectados</h1>
                                <h1 style={styles.columnTextCase} > +5000 Usuarios</h1>
                                <h1 style={styles.columnTextCase} > Core Cloud y Supervivencias</h1>
                                <h1 style={styles.columnTextCase} > Alta Disponibilidad</h1>
                            </div> :
                            <div style={styles.columnTextPadding} >
                                <h1 style={styles.columnTextCase} > +600 Sites Connected</h1>
                                <h1 style={styles.columnTextCase} > +5000 Users</h1>
                                <h1 style={styles.columnTextCase} > Core Cloud y On-Prem backups</h1>
                                <h1 style={styles.columnTextCase} > High Availability</h1>
                            </div>}
                    </div>
                </div>

                {/* <div style={styles.titleClientContainer}>
                    {page !== "Arg" ? (
                        <>
                            {language === true ?
                                <h1 style={styles.titleClient}>Orgullosos de proveer tecnología para sus servicios</h1> :
                                <h1 style={styles.titleClient}>We are proud to provide technology for them</h1>}
                        </>
                    ) : (
                        <>
                            {language === true ?
                                <h1 style={styles.titleClient}>Orgullosos de proveer tecnología para sus <span style={{ fontWeight: '600' }}>Comunicaciones Unificadas</span></h1> :
                                <h1 style={styles.titleClient}>We are proud to provide technology for <span style={{ fontWeight: '600' }}>Unified Communications</span></h1>}
                        </>
                    )}
                </div>
                <div style={styles.columnClientContainer}>
                    <img src="https://i.postimg.cc/PqrJhcC0/aon.png" style={styles.imageClient} alt="Logo of Aon" />
                    <img src="https://i.postimg.cc/kX35DDVQ/volkswagen.png" style={styles.imageClient} alt="Logo of Volkswagen" />
                    <img src="https://i.postimg.cc/q7RJW3YT/airliquid.png" style={styles.imageClient} alt="Logo of Air Liquide" />
                    <img src="https://i.postimg.cc/vmNYymF6/metlife.png" style={styles.imageClient} alt="Logo of Metlife" />
                    <img src="https://i.postimg.cc/tCjXpffD/bancopiano.png" style={styles.imageClient} alt="Logo of Banco Piano" />
                </div> */}













                <div style={styles.bannerMeet} id="contact">
                    <div style={styles.imageMeetDiv}>
                        <img
                            src="https://i.postimg.cc/fywvyNs0/profile-image.png"
                            // height={screenSize > 800 ? (<></>) : ("10%")}
                            // width={screenSize > 800 ? ("10%") : ("70%")}
                            style={styles.imageMeet}
                            alt="Logo"
                            loading="lazy"
                        />
                    </div>
                    <div style={styles.columnTextMeet}>
                        {language === true ? <h2 style={styles.textMeetName}>Hola, soy Thomas 🖐</h2> : <h2 style={styles.textMeetName}>Hi, I'm Thomas 🖐</h2>}
                        {language === true ? <h2 style={styles.textMeetTitle}>Ayudo a organizaciones a mejorar <span style={{ textDecoration: "underline 2px" }}>el contacto</span> con sus clientes.</h2> : <h2 style={styles.textMeetTitle}>I help organizations to improve <span style={{ textDecoration: "underline 2px" }}>contact</span> with their customers.</h2>}
                        {language === true ? <h2 style={styles.textMeetSubtitle}>Hablemos y busquemos la mejor solución para tu negocio.</h2> : <h2 style={styles.textMeetSubtitle}>Let's talk and find the best solution for your business.</h2>}
                        {language === true ? <h2 style={styles.textMeetSubtitle}>Agenda una reunión gratuita de 30 minutos.</h2> : <h2 style={styles.textMeetSubtitle}>Schedule a free 30 minute meeting.</h2>}
                    </div>


                    {screenSize > 800 ? (
                        <></>
                    ) : (
                        <div style={styles.imageButtonDivMobile}>
                            <img
                                src="https://i.postimg.cc/gcCvDfCH/arrow-vertical.png"
                                style={styles.imageButtonMobile}
                                alt="Logo"
                                loading="lazy"
                            />
                        </div>
                    )}


                    <div style={styles.buttonMeetGroup}>
                        {screenSize > 800 ? (
                            <div style={styles.imageButtonDiv}>
                                <img
                                    src="https://i.postimg.cc/v82yW7qC/arrow-button.png"
                                    style={styles.imageButton}
                                    alt="Logo"
                                    loading="lazy"
                                />
                            </div>
                        ) : (<></>)}
                        <Link style={styles.buttonMeet}
                            // to="/meet"
                            onClick={() => (window.open("https://insideone.typeform.com/to/OPap5DNM"))}
                        >
                            {language === true ? <h3 style={styles.buttonMeetText}>AGENDAR REUNIÓN</h3> : <h3 style={styles.buttonMeetText}>SCHEDULE MEETING</h3>}
                        </Link>
                        {screenSize > 800 ? (<div style={{ display: "flex", width: '94px' }}></div>) : (<></>)}

                    </div>
                </div>









                {/* <div style={styles.formContainer} id="contact">
                    {language === true ?
                        <h1 style={styles.titleForm}>Hablemos de tu próximo proyecto</h1> :
                        <h1 style={styles.titleForm}>Let's talk about your next project</h1>}
                    <div style={styles.formCenter} >
                        <form style={styles.formInputsContainer} onSubmit={(e) => handleSubmit(e)}>
                            {language === true ?
                                <label style={styles.labelForm} for="firstNameInput">  Nombre  </label> :
                                <label style={styles.labelForm} for="firstNameInput">  First Name  </label>}
                            <input style={styles.inputForm} onChange={(e) => handleChange(e, "firstName")} value={firstName} name="firstName" id="firstNameInput" type="text" className="form-control" required />
                            {language === true ?
                                <label style={styles.labelForm} for="lastNameInput">  Apellido  </label> :
                                <label style={styles.labelForm} for="lastNameInput">  Last Name  </label>}
                            <input style={styles.inputForm} onChange={(e) => handleChange(e, "lastName")} value={lastName} name="lastName" id="lastNameInput" type="text" className="form-control" required />
                            {language === true ?
                                <label style={styles.labelForm} for="numberInput">  Número de Teléfono   </label> :
                                <label style={styles.labelForm} for="numberInput">  Phone Number   </label>}
                            <input style={styles.inputForm} onChange={(e) => handleChange(e, "numberPhone")} value={numberPhone} name="numberPhone" id="numberInput" type="text" className="form-control" required />
                            {language === true ?
                                <label style={styles.labelForm} for="mailInput">  Correo  </label> :
                                <label style={styles.labelForm} for="mailInput">  Email  </label>}
                            <input style={styles.inputForm} onChange={(e) => handleChange(e, "mail")} value={mail} name="mail" id="mailInput" type="text" className="form-control" required />
                            {language === true ?
                                <label style={styles.labelForm} for="messageInput">  Mensaje  </label> :
                                <label style={styles.labelForm} for="messageInput">  Message  </label>}
                            <textarea style={styles.inputFormMessage} onChange={(e) => handleChange(e, "message")} value={message} name="message" id="messageInput" type="textarea" className="form-control" />
                            <button type="submit" style={styles.buttonForm}>
                                {language === true ?
                                    <h3 style={styles.textButtonWhite}>ENVIAR</h3> :
                                    <h3 style={styles.textButtonWhite}>SEND</h3>}
                            </button>
                            {messageSent === true ?
                                <>
                                    {language === true ?
                                        <h3 style={styles.textThanks}>Gracias por tu mensaje. Te contactaremos en breve.</h3> :
                                        <h3 style={styles.textThanks}>Thank you for your message. We will contact you.</h3>}
                                </> :
                                <></>}
                        </form>
                    </div>
                </div> */}

                <div style={styles.columnAddressContainer}>
                    <div style={styles.singleAddress}>
                        <h1 style={styles.titleAddress}>ARGENTINA</h1>
                        <h1 style={styles.addressText}>Diogenes Taborda 127</h1>
                        <h1 style={styles.addressText}>Buenos Aires</h1>
                        {/* <a href="tel:0800 345 4466" style={styles.phoneText}> 0800 345 4466</a> */}
                    </div>
                    <div style={styles.singleAddress}>
                        <h1 style={styles.titleAddress}>CHILE</h1>
                        <h1 style={styles.addressText}>Avenida Ricardo Lyon 222</h1>
                        <h1 style={styles.addressText}>Santiago de Chile</h1>
                        {/* <a href="tel:+34915774549" style={styles.phoneText}>+34 91 577 4549</a> */}
                    </div>
                    <div style={styles.singleAddress}>
                        <h1 style={styles.titleAddress}>MEXICO</h1>
                        <h1 style={styles.addressText}>Río Amazonas 44</h1>
                        <h1 style={styles.addressText}>Cuauhtémoc, CDMX</h1>
                    </div>
                    <div style={styles.singleAddress}>
                        {language === true ?
                            <h1 style={styles.titleAddress}>ESTADOS UNIDOS</h1> :
                            <h1 style={styles.titleAddress}>UNITED STATES</h1>}
                        <h1 style={styles.addressText}>3401 SW 160th Ave</h1>
                        <h1 style={styles.addressText}>Miami</h1>
                    </div>
                </div>
                <div style={styles.footerContainer}>
                    <h1 style={styles.textFooter}>{`Copyright © ${year} InsideOne SL. All Rights Reserved.`}</h1>
                </div>
            </div>
        </>
    );
};
