import React from "react";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import Loader from "react-loader-spinner";

export default ({ screenSize, darkModeState, language, loader, handleChange, handleSubmit, firstName, lastName, mail, birthday, anniversary }) => {
    const styles = {
        backColor: {
            position: "fixed",
            left: 0,
            top: 0,
            right: 0,
            bottom: 0,
            height: "100%",
            backgroundColor: `${darkModeState ? ("hsl(230, 17%, 14%)") : ("hsl(0, 0%, 100%)")}`
        },
        container: {
            position: "fixed",
            bottom: 0,
            top: 0,
            left: 0,
            right: 0,
            display: "flex",
            flexDirection: "column",
            //   padding: `${screenSize > 800 ? ("3% 2% 3% 20%") : ("30px")}`,
            padding: `${screenSize > 800 ? ("3% 2% 3% 280px") : ("30px")}`,
            height: "100%",
            overflow: `auto`,
        },
        containerLoader: {
            display: "flex",
            alignItem: 'center',
            justifyContent: 'center',
            height: "80%",
        },
        navbar: {
            display: "flex",
            flex: 1.5,
            margin: `${screenSize > 800 ? ("0px 20px") : ("0px")}`,
            marginBottom: `${screenSize > 800 ? ("0px") : ("30px")}`,
        },
        textsNavbar: {
            display: "flex",
            flex: 8,
            flexDirection: "column",
        },
        titleNavbar: {
            marginBottom: `${screenSize > 800 ? ('5px') : ("10px")}`,
            fontSize: "28px",
            color: `${darkModeState ? ("hsl(0, 0%, 100%)") : ("hsl(230, 17%, 14%)")}`,
            fontFamily: "InterBold",
            lineHeight: '1.4'
        },
        subtitleNavbar: {
            margin: 0,
            fontSize: "14px",
            color: `${darkModeState ? ("hsl(228, 34%, 66%)") : ("hsl(228, 12%, 44%)")}`,
            fontFamily: "InterBold",
        },
        textUser: {
            fontSize: "14px",
            fontFamily: "InterBold",
            alignSelf: "center",
            textDecoration: "none",
            color: 'inherit',
            border: 'none',
            outline: 'none',
            padding: 0,
            backgroundColor: `transparent`
        },
        campaigns: {
            display: "flex",
            flex: 11,
            flexDirection: "column",
            alignItems: 'center',
            margin: `${screenSize > 800 ? ("0px 20px") : ("0px")}`,
        },
        form: {
            width: '100%',
            // margin: '20px 0px'
        },

        label: {
            margin: '0px 0px 10px',
            fontSize: "18px",
            fontFamily: "InterBold",
            color: `${darkModeState ? ("hsl(228, 34%, 66%)") : ("hsl(228, 12%, 44%)")} `,
        },
        input: {
            fontFamily: "InterRegular",
            fontSize: "16px",
            color: `${darkModeState ? ("hsl(228, 34%, 66%)") : ("hsl(228, 12%, 44%)")}`,
            margin: '0px 0px 20px',
            textDecoration: "none",
            border: 'none',
            outline: 'none',
            padding: '25px 20px'
        },
        buttonNew: {
            display: "flex",
            alignItems: "flex-start",
            justifyContent: 'flex-start',
            alignSelf: 'flex-start',
            textDecoration: "none",
            outline: 0,
            color: "#fff",
            backgroundColor: "#4dc2f1",
            border: 'none',
            borderRadius: 5,
            margin: 0,
            padding: '12px 32px',
        },
        loader: {
            display: 'flex',
            alignSelf: 'center'
        }
    };

    return (
        <>
            <div style={styles.backColor} />
            <div style={styles.container}>
                <div style={styles.navbar}>
                    <div style={styles.textsNavbar}>
                        <h3 style={styles.titleNavbar}>New person 😃</h3>
                        <h3 style={styles.subtitleNavbar}>Create a new person.</h3>
                    </div>
                </div>
                <div style={styles.campaigns}>
                    {loader ? (
                        <div style={styles.containerLoader}>
                            <Loader
                                style={styles.loader}
                                type="TailSpin"
                                color="#4dc2f1"
                                height={100}
                                width={100}
                                timeout={10000}
                            />
                        </div>
                    ) : (
                        <form onSubmit={(e) => handleSubmit(e)} style={styles.form}>
                            <div className="form-group">
                                <label style={styles.label}>First name</label>
                                <input onChange={(e) => handleChange(e, 'firstName')} name="nameOfRol" type="text" className={darkModeState ? ("form-control buttonDark") : ("form-control buttonLight")} style={styles.input} value={firstName} placeholder="Juan" required />
                                <label style={styles.label}>Last name</label>
                                <input onChange={(e) => handleChange(e, 'lastName')} name="lastName" type="text" className={darkModeState ? ("form-control buttonDark") : ("form-control buttonLight")} style={styles.input} value={lastName} placeholder="Perez" required />
                                <label style={styles.label}>Email</label>
                                <input onChange={(e) => handleChange(e, 'mail')} name="mail" type="text" className={darkModeState ? ("form-control buttonDark") : ("form-control buttonLight")} style={styles.input} value={mail} placeholder="jperez@insideone.com.ar" required />
                                <label style={styles.label}>Birthday DD/MM</label>
                                <input onChange={(e) => handleChange(e, 'birthday')} name="birthday" type="text" className={darkModeState ? ("form-control buttonDark") : ("form-control buttonLight")} style={styles.input} value={birthday} placeholder="DD/MM" required />
                                <label style={styles.label}>Anniversary DD/MM/AAAA</label>
                                <input onChange={(e) => handleChange(e, 'anniversary')} name="anniversary" type="text" className={darkModeState ? ("form-control buttonDark") : ("form-control buttonLight")} style={styles.input} value={anniversary} placeholder="DD/MM/AAAA" required />
                                <button type="submit" className="btn" style={styles.buttonNew}>Create person</button>
                            </div>

                        </form>
                    )}
                </div>
            </div>
        </>
    );
};
