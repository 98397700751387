import React from "react";
import { Link } from "react-router-dom";


export default ({ screenSize, messages, message, setMessage, darkmodeState, loginUser, handleSend, active, messageEl, introMode, handleIntroMode }) => {
    const styles = {
        container: {
            position: "fixed",
            bottom: 0,
            top: 0,
            left: 0,
            right: 0,
            display: "flex",
            flexDirection: "column",
            padding: `${screenSize > 800 ? ("3% 10%") : ("30px 0px")}`,
            height: "100%",
        },
        navbar: {
            display: "flex",
            flex: 2,
            justifyContent: "center",
            alignItems: "center",
            margin: `${screenSize > 800 ? ("0px 20px") : ("0px 30px")}`,
            marginBottom: `${screenSize > 800 ? ("0px") : ("30px")}`,
        },
        logosGroup: {
            display: 'flex',
            flexDirection: 'row',
            width: '100%',
            alignItems: 'center',
            justifyContent: 'center'
        },
        icon1: {
            display: "flex",
            height: '55px',
            borderRight: '1px solid lightgrey',
            padding: '10px 50px 10px 0px'
        },
        icon2: {
            display: "flex",
            height: '55px',
            padding: '5px 0px 5px 50px'
        },
        chat: {
            display: "flex",
            flex: 10,
            flexDirection: "column",
            justifyContent: "flex-start",
            alignSelf: 'center',
            margin: '40px 0px',
            width: '100%'
        },
        intro: {
            display: "flex",
            flex: 10,
            flexDirection: "column",
            alignItems: 'center',
            justifyContent: 'center',
            margin: '0px 0px 40px',
        },
        titleIntro: {
            fontSize: '30px',
            fontFamily: 'InterBold',
            margin: '20px 0px',
            textAlign: 'center',
        },
        textIntro: {
            fontSize: '20px',
            fontFamily: 'InterRegular',
            margin: '20px 100px',
            textAlign: 'center',
            lineHeight: '1.8',
            color: '#637580'
        },
        spanIntro: {
            fontFamily: 'InterBold',
        },
        buttonIntro: {
            backgroundColor: '#4dc2f1',
            color: '#fff',
            borderRadius: '25px',
            padding: '13px 50px',
            textAlign: 'center',
            fontFamily: 'InterBold',
            margin: '50px 0px',
            border: "0px",
            outline: 0
        },
        buttonSvg: {
            backgroundColor: '#fff',
            color: '#fff',
            border: 0,
            outline: 0,
            padding: 0
        },
        chatContainer: {
            display: "flex",
            height: "75vh",
            borderRadius: "5px",
            // backgroundColor: 'grey',
            // boxShadow: "0 3px 16px 0 rgba(90,90,90,0.2)",
            boxShadow: `${screenSize > 800 ? ("0 3px 16px 0 rgba(150,150,150,0.2)") : (<></>)}`,
            // border: '1px solid rgb(230,230,230)',
            margin: `${screenSize > 800 ? ("0px 20px") : ("0px 0px 40px")}`,
        },
        chatMessages: {
            flex: 12,
            display: "flex",
            borderRadius: "10px 10px 0px 0px",
            flexDirection: "column",
        },
        chatMessagesAll: {
            display: "flex",
            flex: 11,
            flexDirection: "column",
            borderRadius: "10 10px 0px 0px",
            paddingBottom: "10px",
            overflow: "auto",
        },
        chatReceived: {
            margin: "10px 0px 0px",
            padding: "15px 20px",
            maxWidth: "60%",
            display: "flex",
            alignSelf: "flex-start",
            backgroundColor: "#fff",
            color: `${darkmodeState ? ("hsl(228, 34%, 66%)") : ("hsl(228, 12%, 44%)")}`,
            borderRadius: "0 10px 10px 0",
            boxShadow: "0 3px 16px 0 rgba(90,90,90,0.2)",
            fontFamily: 'InterRegular'
        },
        chatSent: {
            margin: "0px",
            padding: "15px 20px",
            maxWidth: "60%",
            margin: "10px 0px 0px",
            display: "flex",
            alignSelf: "flex-end",
            backgroundColor: "#4dc2f1",
            color: "#fff",
            borderRadius: "10px 0 0 10px",
            boxShadow: "0 3px 16px 0 rgba(90,90,90,0.2)",
            fontFamily: 'InterRegular'
        },
        textBoxChat: {
            flex: 1,
            display: "flex",
            borderRadius: "0px 10px 10px 10px",
            flexDirection: "row",
            borderTop: "solid 0.2px rgb(240,240,240)",
            alignItems: "center",
        },
        inputChat: {
            display: "flex",
            flex: 10,
            justifyContent: "flex-start",
            alignItems: "center",
            height: "100%",
            border: "0px",
            padding: '0px 20px',
            color: `${darkmodeState ? ("hsl(228, 34%, 66%)") : ("hsl(228, 12%, 44%)")}`,
            fontFamily: 'InterRegular',
            fontSize: "16px",
        },
        button: {
            display: "flex",
            flex: 2,
            alignItems: "center",
            justifyContent: "center",
            borderRadius: "5px",
            backgroundColor: "#4dc2f1",
            height: "100%",
            border: "0px",
            color: '#fff',
            outline: 0,
            fontSize: "16px",
            fontFamily: 'InterRegular',
        },
        buttonFloat: {
            position: "absolute",
            bottom: 15,
            right: -100,
            display: "flex",
            justifyContent: "flex-end",
            height: '280px'
        },
        svgChat: {
            width: 30,
            height: 30,
            color: '#94A3AD'
        }
    };

    return (
        <div style={styles.container}>
            <div style={styles.navbar}>
                {introMode === false ? (
                    <button style={styles.buttonSvg} onClick={handleIntroMode}>
                        <svg style={styles.svgChat} fill="currentColor" class="bi bi-arrow-left-short" viewBox="0 0 16 16">
                            <path fill-rule="evenodd" d="M12 8a.5.5 0 0 1-.5.5H5.707l2.147 2.146a.5.5 0 0 1-.708.708l-3-3a.5.5 0 0 1 0-.708l3-3a.5.5 0 1 1 .708.708L5.707 7.5H11.5a.5.5 0 0 1 .5.5z" />
                        </svg>
                    </button>
                ) : (<></>)}
                <div style={styles.logosGroup}>
                    <img src="https://i.postimg.cc/m26pS9xt/logo-bbva.png" style={styles.icon1} alt="Logo of BBVA" />
                    <img src="https://i.postimg.cc/bN6SJ6fx/logo-insideone.png" style={styles.icon2} alt="Logo of Inside One" />
                </div>
                {introMode === false ? (<div style={{ width: 30, height: 30 }} />) : (<></>)}
            </div>
            {introMode === true ? (
                <div style={styles.intro}
                //ref={messageEl}
                >
                    <h1 style={styles.titleIntro}>Hola, Bienvenido a la solución de Lenguaje Natural!</h1>
                    <h1 style={styles.textIntro}>En esta demostración conocerás como funciona el  <span style={styles.spanIntro}>Bot de Lenguaje Natural</span> para <span style={styles.spanIntro}>BBVA Seguros</span>. Este Bot puede ser usado en <span style={styles.spanIntro}>múltiples canales de atención</span> como, por ejemplo, en <span style={styles.spanIntro}>WhatsApp</span>.</h1>
                    <button style={styles.buttonIntro} onClick={handleIntroMode}>Comenzar</button>
                    {screenSize > 800 ? (
                        <img
                            style={styles.buttonFloat}
                            src="https://i.postimg.cc/SRjc48Cv/Eric-Apuntando-Alpha-baja.gif"
                            alt="Bot of Inside One"
                        />
                    ) : (<></>)}
                </div>

            ) : (
                <div style={styles.chat}>
                    <div style={styles.chatContainer}>
                        <div style={styles.chatMessages}>
                            <div style={styles.chatMessagesAll} ref={messageEl}>
                                <div style={styles.chatReceived}>
                                    {loginUser.firstName ? (`Hola! Bienvenido de nuevo. ¿Qué puedo hacer por ti?`) : ('Bienvenido de nuevo! ¿Qué puedo hacer por ti?')}
                                </div>
                                {messages.length ? (
                                    messages.map((message) => {
                                        if (message.owner === "admin") {
                                            return (
                                                <div style={styles.chatReceived}>
                                                    {message.message}
                                                </div>
                                            );
                                        } else {
                                            return (
                                                <div style={styles.chatSent}>
                                                    {message.message}
                                                </div>
                                            );
                                        }
                                    })
                                ) : (
                                    <></>
                                )}
                            </div>
                            <form style={styles.textBoxChat}>
                                <input
                                    name="textChat"
                                    type="text"
                                    className="form-control"
                                    placeholder="Escribe aquí..."
                                    style={styles.inputChat}
                                    value={message}
                                    onChange={e => setMessage(e.target.value)}
                                //onChange={handlerChange}
                                />
                                <button type="submit" style={styles.button} onClick={handleSend} disabled={active ? '' : 'disabled'}>Enviar</button>
                            </form>
                        </div>
                    </div>
                </div>
            )}

        </div>

    );
};
