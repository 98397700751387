import firebase from 'firebase';

var firebaseConfig = {
    apiKey: "AIzaSyCCWjNJpHa2llQAsIiKwQKmfgmS7cqxyhs",
    authDomain: "insideone-app.firebaseapp.com",
    projectId: "insideone-app",
    storageBucket: "insideone-app.appspot.com",
    messagingSenderId: "697808558886",
    appId: "1:697808558886:web:1a5331755f69bbad0c7fe7"
};
// Initialize Firebase
firebase.initializeApp(firebaseConfig);
//firebase.analytics();

export default firebase;