import React from "react";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import Loader from "react-loader-spinner";

export default ({ screenSize, darkModeState, language, loader, handleChange, handleSubmit, emails, subject, title, description, emailGroups, templatesGroup, template }) => {
    const styles = {
        backColor: {
            position: "fixed",
            left: 0,
            top: 0,
            right: 0,
            bottom: 0,
            height: "100%",
            backgroundColor: `${darkModeState ? ("hsl(230, 17%, 14%)") : ("hsl(0, 0%, 100%)")}`
        },
        container: {
            position: "fixed",
            bottom: 0,
            top: 0,
            left: 0,
            right: 0,
            display: "flex",
            flexDirection: "column",
            //   padding: `${screenSize > 800 ? ("3% 2% 3% 20%") : ("30px")}`,
            padding: `${screenSize > 800 ? ("3% 2% 3% 280px") : ("30px")}`,
            height: "100%",
            overflow: `auto`,
        },
        containerLoader: {
            display: "flex",
            alignItem: 'center',
            justifyContent: 'center',
            height: "80%",
        },
        navbar: {
            display: "flex",
            flex: 1.5,
            margin: `${screenSize > 800 ? ("0px 20px") : ("0px")}`,
            marginBottom: `${screenSize > 800 ? ("0px") : ("30px")}`,
        },
        textsNavbar: {
            display: "flex",
            flex: 8,
            flexDirection: "column",
        },
        titleNavbar: {
            marginBottom: `${screenSize > 800 ? ('5px') : ("10px")}`,
            fontSize: "28px",
            color: `${darkModeState ? ("hsl(0, 0%, 100%)") : ("hsl(230, 17%, 14%)")}`,
            fontFamily: "InterBold",
            lineHeight: '1.4'
        },
        subtitleNavbar: {
            margin: 0,
            fontSize: "14px",
            color: `${darkModeState ? ("hsl(228, 34%, 66%)") : ("hsl(228, 12%, 44%)")}`,
            fontFamily: "InterBold",
        },
        textUser: {
            fontSize: "14px",
            fontFamily: "InterBold",
            alignSelf: "center",
            textDecoration: "none",
            color: 'inherit',
            border: 'none',
            outline: 'none',
            padding: 0,
            backgroundColor: `transparent`
        },
        campaigns: {
            display: "flex",
            flex: 11,
            flexDirection: "column",
            alignItems: 'center',
            margin: `${screenSize > 800 ? ("0px 20px") : ("0px")}`,
        },
        form: {
            width: '100%',
            // margin: '20px 0px'
        },

        label: {
            margin: '0px 0px 10px',
            fontSize: "18px",
            fontFamily: "InterBold",
            color: `${darkModeState ? ("hsl(228, 34%, 66%)") : ("hsl(228, 12%, 44%)")} `,
        },
        input: {
            width: '100%',
            fontFamily: "InterRegular",
            fontSize: "16px",
            color: `${darkModeState ? ("hsl(228, 34%, 66%)") : ("hsl(228, 12%, 44%)")}`,
            margin: '0px 0px 20px',
            textDecoration: "none",
            border: 'none',
            outline: 'none',
            padding: '25px 20px'
        },
        buttonNew: {
            display: "flex",
            alignItems: "flex-start",
            justifyContent: 'flex-start',
            alignSelf: 'flex-start',
            textDecoration: "none",
            outline: 0,
            color: "#fff",
            backgroundColor: "#4dc2f1",
            border: 'none',
            borderRadius: 5,
            margin: 0,
            padding: '12px 32px',
        },
        loader: {
            display: 'flex',
            alignSelf: 'center'
        }
    };

    return (
        <>
            <div style={styles.backColor} />
            <div style={styles.container}>
                <div style={styles.navbar}>
                    <div style={styles.textsNavbar}>
                        <h3 style={styles.titleNavbar}>New email 😃</h3>
                        <h3 style={styles.subtitleNavbar}>Create a new email.</h3>
                    </div>
                </div>
                <div style={styles.campaigns}>
                    {loader ? (
                        <div style={styles.containerLoader}>
                            <Loader
                                style={styles.loader}
                                type="TailSpin"
                                color="#4dc2f1"
                                height={100}
                                width={100}
                                timeout={10000}
                            />
                        </div>
                    ) : (
                        // <form onSubmit={(e) => handleSubmit(e)} style={styles.form}>
                        //     <div className="form-group">
                        //         <label style={styles.label}>Emails</label>
                        //         <input onChange={(e) => handleChange(e, 'emails')} name="emails" type="text" className={darkModeState ? ("form-control buttonDark") : ("form-control buttonLight")} style={styles.input} value={emails} placeholder="hola@cliente.com" required />
                        //         <label style={styles.label}>Subject</label>
                        //         <input onChange={(e) => handleChange(e, 'subject')} name="subject" type="text" className={darkModeState ? ("form-control buttonDark") : ("form-control buttonLight")} style={styles.input} value={subject} placeholder="Mail de ejemplo" required />
                        //         <label style={styles.label}>Title</label>
                        //         <input onChange={(e) => handleChange(e, 'title')} name="title" type="text" className={darkModeState ? ("form-control buttonDark") : ("form-control buttonLight")} style={styles.input} value={title} placeholder="Título de prueba" required />
                        //         <label style={styles.label}>Description</label>
                        //         <input onChange={(e) => handleChange(e, 'description')} name="description" type="text" className={darkModeState ? ("form-control buttonDark") : ("form-control buttonLight")} style={styles.input} value={description} placeholder="Cuerpo de Email" required />
                        //         <button type="submit" className="btn" style={styles.buttonNew}>Send email</button>
                        //     </div>
                        // </form>



                        <form onSubmit={(e) => handleSubmit(e)} style={styles.form}>
                            <div className="form-group">
                                {/* <label style={styles.label}>Emails</label>
                                <input onChange={(e) => handleChange(e, 'emails')} name="emails" type="text" className={darkModeState ? ("form-control buttonDark") : ("form-control buttonLight")} style={styles.input} value={emails} placeholder="hola@cliente.com" required /> */}


                                <label style={styles.label}>Template</label>
                                <select onChange={(e) => handleChange(e, 'template')} name="template" className={darkModeState ? ("form-select buttonDark") : ("form-select buttonLight")} style={styles.input} value={template} required>
                                    <option selected>Select the group</option>
                                    {templatesGroup ? (templatesGroup.map((singleTemplate) => {
                                        return (<option key={singleTemplate.templateName} value={singleTemplate.templateName} selected={template == singleTemplate.templateName}>{singleTemplate.templateName}</option>)
                                    })) : (null)}
                                </select>


                                <label style={styles.label}>Email group</label>
                                <select onChange={(e) => handleChange(e, 'emails')} name="emails" className={darkModeState ? ("form-select buttonDark") : ("form-select buttonLight")} style={styles.input} value={emails} required>
                                    <option selected>Select the group</option>
                                    {emailGroups ? (emailGroups.map((singleGroup) => {
                                        return (<option key={singleGroup.groupName} value={singleGroup.groupName} selected={emails == singleGroup.groupName}>{singleGroup.groupName}</option>)
                                    })) : (null)}
                                </select>


                                {/* 
                                <label style={styles.label}>Subject</label>
                                <input onChange={(e) => handleChange(e, 'subject')} name="subject" type="text" className={darkModeState ? ("form-control buttonDark") : ("form-control buttonLight")} style={styles.input} value={subject} placeholder="Mail de ejemplo" required />
                                <label style={styles.label}>Title</label>
                                <input onChange={(e) => handleChange(e, 'title')} name="title" type="text" className={darkModeState ? ("form-control buttonDark") : ("form-control buttonLight")} style={styles.input} value={title} placeholder="Título de prueba" required />
                                <label style={styles.label}>Description</label>
                                <input onChange={(e) => handleChange(e, 'description')} name="description" type="text" className={darkModeState ? ("form-control buttonDark") : ("form-control buttonLight")} style={styles.input} value={description} placeholder="Cuerpo de Email" required />
                             
                              */}
                                <button type="submit" className="btn" style={styles.buttonNew}>Send email</button>



                            </div>
                        </form>




                    )}
                </div>
            </div>
        </>
    );
};
