import React from "react";
import { Link } from "react-router-dom";
import PopUpDelete from "./PopUpDelete";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import Loader from "react-loader-spinner";

export default ({ screenSize, handleDelete, handleDarkMode, darkModeState, people, setUserToDelete, handlePopUpClose, popUpClose, loader }) => {
    const styles = {
        backColor: {
            position: "fixed",
            left: 0,
            top: 0,
            right: 0,
            bottom: 0,
            height: "100%",
            backgroundColor: `${darkModeState ? ("hsl(230, 17%, 14%)") : ("hsl(0, 0%, 100%)")}`
        },
        topBackColor: {
            position: "fixed",
            left: 0,
            top: 0,
            right: 0,
            bottom: 0,
            height: "29%",
            backgroundColor: `${darkModeState ? ("hsl(232, 19%, 15%)") : ("hsl(225, 100%, 98%)")}`,
            borderRadius: "0px 0px 25px 25px"
        },
        container: {
            position: "fixed",
            bottom: 0,
            top: 0,
            left: 0,
            right: 0,
            display: "flex",
            flexDirection: "column",
            //   padding: `${screenSize > 800 ? ("3% 2% 3% 20%") : ("30px")}`,
            padding: `${screenSize > 800 ? ("3% 2% 3% 280px") : ("30px")}`,
            height: "100%",
            overflow: `auto`,
        },
        navbar: {
            display: "flex",
            flex: 1.5,
            margin: `${screenSize > 800 ? ("0px 20px") : ("0px")}`,
            marginBottom: `${screenSize > 800 ? ("0px") : ("30px")}`,
        },
        textsNavbar: {
            display: "flex",
            flex: 8,
            flexDirection: "column",
        },
        titleNavbar: {
            marginBottom: `${screenSize > 800 ? ('5px') : ("10px")}`,
            fontSize: "28px",
            color: `${darkModeState ? ("hsl(0, 0%, 100%)") : ("hsl(230, 17%, 14%)")}`,
            fontFamily: "InterBold",
            lineHeight: '1.4'
        },
        subtitleNavbar: {
            margin: 0,
            fontSize: "14px",
            color: `${darkModeState ? ("hsl(228, 34%, 66%)") : ("hsl(228, 12%, 44%)")}`,
            fontFamily: "InterBold",
        },
        toogleNavbar: {
            display: "flex",
            flex: 4,
            alignItems: "center",
            justifyContent: `${screenSize > 800 ? ("flex-end") : ("center")}`,
            flexDirection: `${screenSize > 800 ? ("row") : ("column")}`,
        },
        textToogle: {
            fontSize: "14px",
            fontFamily: "InterBold",
            alignSelf: "center",
            textDecoration: "none",
            color: 'inherit',
            border: 'none',
            outline: 'none',
            padding: '12px',
            backgroundColor: `transparent`
        },
        indicators: {
            display: "flex",
            flex: 11,
            flexDirection: "column",
        },
        contentSinglePerson: {
            display: "flex",
            flexDirection: "row",
            justifyContent: 'center',
            width: `${screenSize > 800 ? ("97%") : ("80vw")}`,
            borderRadius: '5px',
            padding: "10px",
            textDecoration: "none",
            border: 'none',
            outline: 'none',
            margin: `${screenSize > 800 ? ('1.4% 1.4% 0px 1.4%') : ("10px")}`,
        },
        personTitles3: {
            display: "flex",
            flex: 3,
            alignItems: 'center',
            color: `${darkModeState ? ("hsl(0, 0%, 100%)") : ("hsl(230, 17%, 14%)")}`,
            margin: 0,
            fontSize: "16px",
            fontFamily: "InterBold",
            padding: '10px'
        },
        personTitles2: {
            display: "flex",
            flex: 2,
            alignItems: 'center',
            color: `${darkModeState ? ("hsl(0, 0%, 100%)") : ("hsl(230, 17%, 14%)")}`,
            margin: 0,
            fontSize: "16px",
            fontFamily: "InterBold",
            padding: '10px'
        },
        personName: {
            display: "flex",
            flex: 3,
            alignItems: 'center',
            color: `${darkModeState ? ("hsl(0, 0%, 100%)") : ("hsl(230, 17%, 14%)")}`,
            margin: 0,
            fontSize: "16px",
            fontFamily: "InterBold",
            padding: '10px'
        },
        personMail: {
            display: "flex",
            flex: 3,
            alignItems: 'center',
            color: `${darkModeState ? ("hsl(0, 0%, 100%)") : ("hsl(230, 17%, 14%)")}`,
            margin: 0,
            fontSize: "16px",
            fontFamily: "InterRegular",
            padding: '10px'
        },
        personInfo: {
            display: "flex",
            flex: 2,
            alignItems: 'center',
            color: `${darkModeState ? ("hsl(0, 0%, 100%)") : ("hsl(230, 17%, 14%)")}`,
            margin: 0,
            fontSize: "16px",
            fontFamily: "InterRegular",
            padding: '10px'
        },
        buttonNew: {
            display: "flex",
            alignItems: "flex-start",
            justifyContent: 'flex-start',
            alignSelf: 'flex-start',
            textDecoration: "none",
            outline: 0,
            color: "#fff",
            backgroundColor: "#4dc2f1",
            border: 'none',
            borderRadius: 5,
            margin: `${screenSize > 800 ? ('1.4% 1.4% 0px 1.4%') : ("10px")}`,
            padding: '12px 32px',
        },
        buttonDelete: {
            display: "flex",
            alignSelf: "center",
            color: `${darkModeState ? ("hsl(0, 0%, 100%)") : ('hsl(228, 12%, 44%)')}`,
            border: 'none',
            backgroundColor: `transparent`,
            textDecoration: "none",
            outline: 'none',
            padding: '6px',
        },
        containerLoader: {
            display: "flex",
            alignItems: 'center',
            justifyContent: 'center',
            height: '100%'
        },
        loader: {
            display: 'flex',
            alignSelf: 'center'
        },
    };

    let year = new Date().getFullYear()
    let month = new Date().getMonth() + 1
    if (`${month}`.length === 1) { month = "0" + month }
    let date = new Date().getDate()
    if (`${date}`.length === 1) { date = "0" + date }
    let dateTotal = (date + " / " + month + " / " + year)

    return (
        <>
            <div style={styles.backColor} />
            <div style={styles.container}>
                <div style={styles.navbar}>
                    <div style={styles.textsNavbar}>
                        <h3 style={styles.titleNavbar}>People of InsideOne 😃</h3>
                        <h3 style={styles.subtitleNavbar}>{dateTotal}</h3>
                    </div>
                </div>
                {loader ? (
                    <div style={styles.containerLoader}>
                        <Loader
                            style={styles.loader}
                            type="TailSpin"
                            color="#4dc2f1"
                            height={100}
                            width={100}
                            timeout={10000} //10 seconds
                        />
                    </div>
                ) : (<>
                    <div style={styles.indicators}>
                        {popUpClose ? (
                            <PopUpDelete
                                screenSize={screenSize}
                                darkModeState={darkModeState}
                                handlePopUpClose={handlePopUpClose}
                                handleClose={handleDelete}
                            />
                        ) : (<></>)}
                        <Link className={darkModeState ? ("buttonDark") : ("buttonLight")} style={styles.buttonNew} to="/new-person">New person</Link>
                        <div className={darkModeState ? ("buttonDark") : ("buttonLight")} style={styles.contentSinglePerson}>
                            <div style={styles.personTitles3}>Name</div>
                            <div style={styles.personTitles3}>Email</div>
                            <div style={styles.personTitles2}>Birthday</div>
                            <div style={styles.personTitles2}>Anniversary</div>
                            <div style={styles.buttonDelete}>
                                <svg width="25" height="25" fill="transparent" class="bi bi-x" viewBox="0 0 16 16"><path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z" /></svg>
                            </div>
                        </div>
                        {people ? (
                            people.map((singlePerson) => {
                                return (
                                    <div className={darkModeState ? ("buttonDark") : ("buttonLight")} style={styles.contentSinglePerson}>
                                        <div style={styles.personName}>{`${singlePerson.firstName} ${singlePerson.lastName}`}</div>
                                        <div style={styles.personMail}>{singlePerson.mail}</div>
                                        <div style={styles.personInfo}>{singlePerson.birthday}</div>
                                        <div style={styles.personInfo}>{singlePerson.anniversary}</div>
                                        <button style={styles.buttonDelete} onClick={() => {
                                            setUserToDelete(singlePerson.id)
                                            handlePopUpClose()
                                        }
                                        }>
                                            <svg width="25" height="25" fill="currentColor" class="bi bi-x" viewBox="0 0 16 16"><path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z" /></svg>
                                        </button>
                                    </div>
                                )
                            })) : (null)}
                    </div>
                </>)}
            </div>
        </>
    );
};
