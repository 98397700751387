import React, { useState, useEffect } from "react";
import People from "../components/People";
import Sidebar from "./Sidebar";
import firebase from '../config/firebaseIO'
import { useSelector } from "react-redux";

const db = firebase.firestore()

const PeopleClient = () => {

    const [chartState, setChartState] = useState('');
    const [darkMode, setDarkMode] = useState(false);
    const [screenSize, setScreenSize] = useState(window.innerWidth);
    const [people, setPeople] = useState([]);
    const [userToDelete, setUserToDelete] = useState('');
    const [popUpClose, setPopUpClose] = useState(false);
    const [loader, setLoader] = useState(false);

    const loginUser = useSelector(state => state.user.loginUser)

    const handleDarkMode = () => { setDarkMode(!darkMode) };

    const handleResize = (e) => { setScreenSize(window.innerWidth) };

    const handlePopUpClose = (e) => { setPopUpClose(!popUpClose) };

    useEffect(() => {
        window.scrollTo(0, 0);
        window.addEventListener("resize", handleResize);

        // db.collection("users").limit(1)
        //     //.orderBy("firstName")
        //     .get()
        //     .then((persons) => {
        //         persons.forEach((singlePerson) => {
        //             peopleToState.push({
        //                 id: singlePerson.id,
        //                 firstName: singlePerson.data().firstName,
        //                 lastName: singlePerson.data().lastName,
        //                 mail: singlePerson.data().mail,
        //                 rol: singlePerson.data().rol,
        //                 anniversary: singlePerson.data().anniversary,
        //                 birthday: singlePerson.data().birthday,
        //             })
        //         });
        //     }).then(() => { setPeople(peopleToState) })


        db.collection("users")
            //.limit(1)
            .orderBy("firstName")
            .onSnapshot((persons) => {
                let peopleToState = []
                persons.forEach((singlePerson) => {
                    peopleToState.push({
                        id: singlePerson.id,
                        firstName: singlePerson.data().firstName,
                        lastName: singlePerson.data().lastName,
                        mail: singlePerson.data().mail,
                        rol: singlePerson.data().rol,
                        anniversary: singlePerson.data().anniversary,
                        birthday: singlePerson.data().birthday,
                    })
                })
                setPeople(peopleToState)
            })






    }, []);

    const handleDelete = () => {
        setLoader(true)
        setPopUpClose(false)

        if (userToDelete != "") {
            db.collection('users').doc(userToDelete).delete()
                .then(() => {
                    setLoader(false)
                })
        }


    };



    return (
        <>
            <Sidebar />
            <People
                handleDarkMode={handleDarkMode}
                chartState={chartState}
                darkModeState={darkMode}
                screenSize={screenSize}
                people={people}
                loginUser={loginUser}
                handleDelete={handleDelete}
                setUserToDelete={setUserToDelete}
                handlePopUpClose={handlePopUpClose}
                popUpClose={popUpClose}
                loader={loader}
            />
        </>
    );
}

export default PeopleClient;