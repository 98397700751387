import React from "react";

export default ({ screenSize }) => {
    const styles = {
        container: {
            position: "fixed",
            bottom: 0,
            top: 0,
            left: 0,
            right: 0,
            display: "flex",
            flexDirection: "column",
            padding: `${screenSize > 800 ? ("3% 2% 3% 280px") : ("30px 0px")}`,
            height: "100%",
        },
        chatContainer: {
            display: "flex",
            height: "90vh",
            borderRadius: "5px",
            boxShadow: `${screenSize > 800 ? ("0 3px 16px 0 rgba(150,150,150,0.2)") : (<></>)}`,
            margin: `${screenSize > 800 ? ("0px 20px") : ("0px 0px 40px")}`,
        }
    };

    return (
        <div style={styles.container}>
            <iframe
                style={styles.chatContainer}
                allow="microphone;" src="https://console.dialogflow.com/api-client/demo/embedded/ec6fb8a3-caa4-4247-ba3b-5bed117fe785"></iframe>
        </div>
    );
};
