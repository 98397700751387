import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { useSelector } from "react-redux";
import People from "../../components/create/People";
import Sidebar from "../Sidebar";
import firebase from '../../config/firebaseIO'

const db = firebase.firestore()

const CreatePeople = () => {

    const history = useHistory();

    const [screenSize, setScreenSize] = useState(window.innerWidth);
    const [firstName, serFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [mail, setMail] = useState('');
    const [birthday, setBirthday] = useState('');
    const [anniversary, setAnniversary] = useState('');

    const language = useSelector(state => state.user.language)
    const [darkMode, setDarkMode] = useState(false);
    const [loader, setLoader] = useState(false);

    const handleChange = (e, state) => {
        if (state === 'firstName') {
            serFirstName(e.target.value);
        }
        if (state === 'lastName') {
            setLastName(e.target.value);
        }
        if (state === 'mail') {
            setMail(e.target.value);
        }
        if (state === 'birthday') {
            let birthdayDate = e.target.value
            if (birthdayDate.length > 5) {
                //nothing
            } else {
                if (birthdayDate.length == 2) {
                    setBirthday(birthdayDate + "/")
                } else {
                    setBirthday(birthdayDate)
                }
            }
        }
        if (state === 'anniversary') {
            let anniversaryDate = e.target.value
            if (anniversaryDate.length > 10) {
                //nothing
            } else {
                if (anniversaryDate.length == 2 || anniversaryDate.length == 5) {
                    setAnniversary(anniversaryDate + "/")
                } else {
                    setAnniversary(anniversaryDate)
                }
            }
        }
    };

    const handleSubmit = (e) => {
        if (e) { e.preventDefault() }
        if (firstName != "" && lastName != "" && mail != "" && birthday != "" && anniversary != "") {
            setLoader(true)
            db.collection('users').add({
                firstName: firstName,
                lastName: lastName,
                mail: mail,
                birthday: birthday,
                anniversary: anniversary,
                rol: 'user'
            }).then(() => {
                history.push(`/people`);
            })
        }
    };

    return (
        <div>
            <Sidebar />
            <People
                screenSize={screenSize}
                darkModeState={darkMode}
                language={language}
                handleChange={handleChange}
                handleSubmit={handleSubmit}
                firstName={firstName}
                lastName={lastName}
                mail={mail}
                birthday={birthday}
                anniversary={anniversary}
                loader={loader}
            />
        </div>
    );
}

export default CreatePeople;